import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import { connect } from "formik";
import gql from "fraql";
import each from "lodash/each";
import find from "lodash/find";
import get from "lodash/get";
import isNil from "lodash/isNil";
import omit from "lodash/omit";
import { NO_ACCESS_TITLE } from "../../constants/labelConstants";
import isBlank from "../../utils/isBlank";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

const GET_TEAMS_FOR_COMPETITION = gql`
  query FormFieldTeam_GetTeams($CompetitionId: String!) {
    Team(where: { CompetitionId: { _eq: $CompetitionId } }, order_by: { name: asc }) {
      id
      name
      Competition {
        maxTeamMembers
      }
      TeamPeople {
        PersonId
      }
      TeamPeople_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const isFull = (team) => {

  const maxTeamMembers = get(team, "Competition.maxTeamMembers", 999999);
  const currentCount = get(team, "TeamPeople_aggregate.aggregate.count", 0);

  return currentCount >= maxTeamMembers;
}

function FormFieldTeam(props) {
  const {
    meta,
    name,
    disabled,
    hideOnEmpty,
    hideOnLoading,
    formik: { values, setFieldValue },
    query,
    queryOptions,
    personId
  } = props;

  const { loading: teamsLoading, error: teamsError, data: teamsData } = useQuery(query, queryOptions);

  const fieldValue = get(values, name, undefined);

  const teamOptions = useMemo(() => {

    
 
    const teams = get(teamsData, "Team", []).map(team => ({
      value: team.id,
      label: `${team.name}${isFull(team) ? " [THIS TEAM IS FULL]" : ""}`,
      disabled: isFull(team),
    }));

    if (!isBlank(fieldValue) && !find(teams, { value: fieldValue })) {
      teams.unshift({
        value: fieldValue,
        label: <StatusText status={STATUS_TEXT_STATUS_OPTIONS.WARNING}>{NO_ACCESS_TITLE}</StatusText>,
        title: NO_ACCESS_TITLE,
      });
    }

    return teams;
  }, [teamsData, fieldValue]);

  useEffect(()=>{

    const personIsInTeam = (teamId, teams) => {

      if (isNil(teams) || teams.length === 0 || isNil(personId) || isNil(teamId)){
        return false;
      }

      let found = false;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < teams.Team.length; i++){
        const team = teams.Team[i];
        
        if (team.id === teamId){
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < team.TeamPeople.length; j++){
            const teamPerson = team.TeamPeople[j];

            if (teamPerson.PersonId === personId){
              found = true;
            }
          }
        }
      }
      
      return found;
    }

    if (isNil(fieldValue)){
      return;
    }

    const teams = get(teamsData, "Team", []).map(team => ({
      value: team.id,
      label: `${team.name}${isFull(team) ? " [THIS TEAM IS FULL]" : ""}`,
      disabled: isFull(team),
    }));

    each (teams, function(team){
      if (!disabled && team.value === fieldValue && !personIsInTeam(team.value, teamsData) && team.disabled){
        setFieldValue(name, undefined);
      }
    });
  },[teamsData, fieldValue, personId, setFieldValue, disabled, name]);

  if (teamsLoading && !teamsData) {
    if (hideOnLoading) {
      return null;
    }

    return (
      <FormFieldFormItem {...props} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (teamsError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayForInput={false}>
        <Alert message="Teams failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  if (hideOnEmpty && teamOptions.length === 0) {
    return null;
  }

  return (
    <FormFieldSelect
      {...omit(props, ["formik"])}
      disabled={disabled || teamsLoading}
      loading={teamsLoading}
      meta={{ ...meta, options: teamOptions }}
    />
  );
}

FormFieldTeam.propTypes = {
  name: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    placeholder: PropTypes.string,
    allowClear: PropTypes.bool,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
    displayMode: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  hideOnEmpty: PropTypes.bool,
  hideOnLoading: PropTypes.bool,
  formik: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  readOnly: PropTypes.bool,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
};

FormFieldTeam.defaultProps = {
  readOnly: false,
  query: GET_TEAMS_FOR_COMPETITION,
  queryOptions: {},
  hideOnEmpty: false,
  hideOnLoading: false,
};

export default connect(FormFieldTeam);
