import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import gql from "fraql";
import { get } from "lodash";
import { useRegContext } from "../../utils/useRegContext";
import FormFieldCheckboxGroup from "./FormFieldCheckboxGroup";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";
import getActiveArchivedOptions from "./getActiveArchivedOptions";

const GET_SPORTS = gql`
  query FormFieldSport_GetSports {
    Sport(order_by: { name: asc }) {
      id
      name
      archived
      Tenant {
        key
      }
    }
  }
`;

export const DISPLAY_MODES = {
  checkboxList: "checkboxList",
  select: "select",
};

function FormFieldSport(props) {
  const { meta, query, queryOptions, dataPath } = props;

  // regContext used here to determine if fields are to be locked during a Registration Submission.
  const regContext = useRegContext();
  const lockedSports = get(regContext, "regContextData.lockedFields.locked_sports", []);

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const displayMode = get(meta, "displayMode", DISPLAY_MODES.select);

  const { loading, error, data } = useQuery(query, queryOptions);

  const options = useMemo(() => {
    const activeOptions = getActiveArchivedOptions({ allRecords: get(data, dataPath, []) });
    // Lock any option that is linked with a payment item.
    const result = activeOptions.map(option => {
      if (lockedSports.includes(option.value)) {
        return { ...option, disabled: true };
      }
      return option;
    });

    return result;
  }, [data, dataPath, lockedSports]);

  if (loading && !data) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (error) {
    return (
      <FormFieldFormItem {...props} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Sports failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  switch (displayMode) {
    case DISPLAY_MODES.checkboxList: {
      return <FormFieldCheckboxGroup {...props} meta={{ ...meta, options }} />;
    }
    case DISPLAY_MODES.select: {
      return <FormFieldSelect {...props} loading={loading} meta={{ ...meta, options }} />;
    }
    default: {
      console.error("Unsupported display mode", displayMode);
      return null;
    }
  }
}

FormFieldSport.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    label: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.node,
    displayDefaultLabel: PropTypes.bool,
    displayMode: PropTypes.string,
  }).isRequired,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  dataPath: PropTypes.string,
};

FormFieldSport.defaultProps = {
  query: GET_SPORTS,
  queryOptions: {},
  readOnly: false,
  dataPath: "Sport",
};

export default FormFieldSport;
