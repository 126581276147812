import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Tooltip } from "antd";
import gql from "fraql";
import { useHistory } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import each from "lodash/each";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { PEOPLE } from "../../../../constants/routeConstants";
import { useGetUserHasPermission, PERMISSION } from "../../../../utils/useGetUserHasPermission";
import ColumnValueGender from "../../../ColumnValueGender";
import ColumnValueTeamPosition from "../../../ColumnValueTeamPosition";
import ColumnValueText from "../../../ColumnValueText";
import EmptyValueText from "../../../EmptyValueText";
import SpinPageContent from "../../../SpinPageContent";
import TableWithTruncation from "../../../TableWithTruncation/TableWithTruncation";
import PersonName, { getFullName } from "../../PersonName";
import TeamConditions from "./TeamConditions";
import TeamQualificationScore from "./TeamQualificationScore/TeamQualificationScore";

const GET_TEAM = gql`
  query ViewTeamDrawerJoinedTab_GetTeam($teamId: String!, $regId: String!) {
    Team_by_pk(id: $teamId) {
      id

      Competition {
        id
        teamPositions
      }

      TeamPeople {
        id
        position
        PersonId
        Person {
          id
          gender
          firstName
          lastName
          RegSubmissions(where: { RegId: { _eq: $regId } }) {
            id
            Role {
              id
              name
            }
          }
        }
      }

      PersonUserTeams {
        id
        firstName
        lastName
        gender
        RegSubmissions(where: { RegId: { _eq: $regId } }) {
          id
          Role {
            id
            name
          }
        }
      }
    }
  }
`;

const JOINED_COLUMNS_BASE = [
  {
    key: "name",
    title: "Name",
    render: (text, record) => {
      
      if (isNil(get(record, "Person"))) {
        return <EmptyValueText text="[Not visible]" />;
      }

      return (
        <Tooltip
          placement="topLeft"
          trigger="hover"
          title={getFullName(record.Person.firstName, record.Person.lastName)}
        >
          <ColumnValueText>
            <PersonName firstName={record.Person.firstName} lastName={record.Person.lastName} />
          </ColumnValueText>
        </Tooltip>
      );
    },
    minWidth: 190,
  },
  {
    title: "Gender",
    key: "gender",
    render: (text, record) => {
      if (isNil(get(record, "Person"))) {
        return <EmptyValueText text="[Not visible]" />;
      }

      return <ColumnValueGender gender={record.Person.gender} />;
    },
    width: 170,
  },
  {
    key: "role",
    title: "Role",
    render: (text, record) => {
      if (isNil(get(record, "Person"))) {
        return <EmptyValueText text="[Not visible]" />;
      }

      const roleName = get(record, "Person.RegSubmissions.0.Role.name", "");

      return (
        <Tooltip placement="topLeft" trigger="hover" title={roleName}>
          <ColumnValueText>{roleName}</ColumnValueText>
        </Tooltip>
      );
    },
    width: 190,
  },
];

function ViewTeamDrawerJoinedTab({ teamId, regId, readOnly }) {
  const history = useHistory();
  const hasWriteAccess = useGetUserHasPermission(PERMISSION.WRITE_ACCESS);
  const [dataSource, setDataSource] = useState(null);

  const checkForMissingPeople = (data) => {

    if (isNil(data)){
      return null;
    }

    const result = cloneDeep(data);

    each (result.Team_by_pk.TeamPeople, function(teamPerson){
      if (isNil(teamPerson.Person)){
        if (!isNil(data.Team_by_pk.PersonUserTeams)){
          // eslint-disable-next-line no-param-reassign
          teamPerson.Person = data.Team_by_pk.PersonUserTeams.find(x => x.id === teamPerson.id);
        }
      }
    });

    return result;
  }

  const { loading: teamLoading, error: teamError, data: teamData } = useQuery(GET_TEAM, {
    variables: { teamId, regId },
    onCompleted: (data) => {
      setDataSource(checkForMissingPeople(data));
    },
    skip: isNil(teamId) || isNil(regId),
  });

  const joinedDataSource = useMemo(() => get(dataSource, "Team_by_pk.TeamPeople", []), [dataSource]);
  const teamPositions = useMemo(() => get(teamData, "Team_by_pk.Competition.teamPositions", []), [teamData]);

  const onRow = useCallback(
    record => ({
      onClick: () => {
        if (!readOnly){
          history.push(`${PEOPLE}/${record.Person.id}/registrations`);
        }
      },
    }),
    [history, readOnly],
  );

  const joinedColumns = useMemo(() => {
    const columns = [...JOINED_COLUMNS_BASE];

    if (!isEmpty(teamPositions)) {
      columns.push({
        title: "Position",
        key: "position",
        render: (text, record) => (
          <ColumnValueTeamPosition position={record.position} availableTeamPositions={teamPositions} />
        ),
        width: 190,
      });
    }

    return columns;
  }, [teamPositions]);

  if (teamLoading && !teamData) {
    return <SpinPageContent />;
  }

  if (teamError) {
    return (
      <div style={{ padding: 24 }}>
        <Alert
          message="Team failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <div>
      <TeamConditions teamId={teamId} regId={regId} />

      <TableWithTruncation
        columns={joinedColumns}
        includeIndexColumn
        dataSource={joinedDataSource}
        loading={teamLoading}
        onRow={onRow}
        rowKey="id"
        rowClassName="cursor-pointer"
        size="small"
        pagination={false}
        bordered={false}
        scroll={{ y: 54 * 10 }}
        locale={{
          emptyText: "No team members yet",
        }}
      />

      <TeamQualificationScore teamId={teamId} showFormBottomComponent={hasWriteAccess} readOnly={!hasWriteAccess} />
    </div>
  );
}

ViewTeamDrawerJoinedTab.propTypes = {
  teamId: PropTypes.string,
  regId: PropTypes.string,
  readOnly: PropTypes.bool,
};

ViewTeamDrawerJoinedTab.defaultProps = {
  teamId: undefined,
  regId: undefined,
  readOnly: false
};

export default ViewTeamDrawerJoinedTab;
