import isBlank from "../utils/isBlank";
import { PERMISSION } from "../utils/useGetUserHasPermission";
import * as ROUTES from "./routeConstants";

const GLOBAL_NAV_MENU_ELEVATED = [
  {
    title: "Events",
    icon: "calendar",
    path: ROUTES.COMPETITION_MANAGEMENT,
    permission: PERMISSION.ACCESS_COMPETITION_MANAGEMENT,
  },
  {
    title: "Registrations",
    icon: "picture",
    path: ROUTES.REGISTRATIONS,
    usePath: false,
    permission: { or: [PERMISSION.ACCESS_FORMS, PERMISSION.ACCESS_REGISTRATIONS] },
    submenu: [
      {
        title: "Registrations",
        path: ROUTES.REGISTRATIONS,
        permission: PERMISSION.ACCESS_REGISTRATIONS,
      },
      {
        title: "Forms",
        path: ROUTES.FORMS,
        permission: PERMISSION.ACCESS_FORMS,
      },
    ],
  },
  {
    title: "People",
    icon: "user",
    path: "/people",
    disabled: false,
    permission: PERMISSION.ACCESS_PEOPLE,
  },
  {
    title: "Reports",
    icon: "file-text",
    path: "/reports",
    usePath: false,
    permission: { or: [PERMISSION.ACCESS_SUMMARY_REPORT] },
    submenu: [
      {
        title: "Summary Report",
        path: ROUTES.SUMMARY_REPORT,
        permission: PERMISSION.ACCESS_SUMMARY_REPORT,
      },
      {
        title: "Product Summary Report",
        key: "Product Summary Report",
        path: ROUTES.PRODUCT_SUMMARY_REPORT,
        permission: PERMISSION.ACCESS_SUMMARY_REPORT,
      },
      {
        title: "Product Detail Report",
        key: "Product Detail Report",
        path: ROUTES.PRODUCT_DETAIL_REPORT,
        permission: PERMISSION.ACCESS_SUMMARY_REPORT,
      },
      {
        title: "Product Refund Report",
        key: "Product Refund Report",
        path: ROUTES.PRODUCT_REFUND_REPORT,
        permission: PERMISSION.ACCESS_SUMMARY_REPORT,
      },
      {
        title: "Credit Card Transaction Report",
        key: "Credit Card Transaction Report",
        path: ROUTES.CREDIT_CARD_TRANSACTION_SUMMARY_REPORT,
        permission: PERMISSION.ACCESS_SUMMARY_REPORT,
      },
      {
        title: "Reg Transaction Report",
        key: "Reg Transaction Report",
        path: ROUTES.REG_TRANSACTION_REPORT,
        permission: PERMISSION.ACCESS_SUMMARY_REPORT,
      },
      {
        title: "Discount Code Usage Report",
        path: ROUTES.DISCOUNT_CODE_USAGE_REPORT,
        permission: PERMISSION.ACCESS_DISCOUNT_REPORTS,
      },
      {
        title: "Discount Code Transaction Report",
        path: ROUTES.DISCOUNT_CODE_TRANSACTION_REPORT,
        permission: PERMISSION.ACCESS_DISCOUNT_REPORTS,
      },
    ],
  },
  {
    title: "Admin",
    icon: "setting",
    path: "/admin",
    permission: PERMISSION.TENANT_ADMIN_MENUS,
    usePath: false,
    submenu: [
      {
        title: "Roles",
        path: ROUTES.ROLES,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Group Types",
        path: ROUTES.GROUP_TYPES,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Groups",
        path: ROUTES.GROUPS,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Result Statuses",
        path: ROUTES.RESULT_STATUSES,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Sports",
        path: ROUTES.SPORTS,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Formats",
        path: ROUTES.FORMATS,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Score Types",
        path: ROUTES.SCORE_TYPES,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Custom Fields",
        path: ROUTES.FIELDS,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Countries",
        path: ROUTES.COUNTRIES,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Countries",
        path: ROUTES.COUNTRIES,
      },
      {
        title: "Discount Codes",
        path: ROUTES.DISCOUNT_CODES,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "Permission Management",
        path: ROUTES.USER_GROUP_ROLE_PERMISSIONS,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
      },
      {
        title: "System Settings",
        icon: "setting",
        path: "/settings",
        usePath: false,
        permission: PERMISSION.TENANT_ADMIN_MENUS,
        submenu: [
          {
            title: "Theme",
            path: ROUTES.SYSTEM_THEME,
            permission: PERMISSION.TENANT_ADMIN_MENUS,
          },
          {
            title: "Communications / Templates",
            path: ROUTES.SYSTEM_COMMUNICATIONS,
            permission: PERMISSION.TENANT_ADMIN_MENUS,
          },
        ],
      },
    ],
  },
  {
    title: "Fusesport Admin Panel",
    icon: "dribbble",
    path: "/tenant",
    usePath: false,
    permission: PERMISSION.FUSESPORT_ADMIN,
    submenu: [
      {
        title: "Tenant Management",
        path: ROUTES.TENANT,
        permission: PERMISSION.FUSESPORT_ADMIN,
      },
    ],
  },
];

function appendTermsAndPrivacyItems(previousNavItems, { termsPageLink, privacyPageLink }) {
  const result = [...previousNavItems];
  if (!isBlank(termsPageLink)) {
    result.push({
      title: "Terms",
      path: termsPageLink,
      requiresAuthentication: false,
    });
  }
  if (!isBlank(privacyPageLink)) {
    result.push({
      title: "Privacy",
      path: privacyPageLink,
      requiresAuthentication: false,
    });
  }
  return result;
}

export function getGlobalNavMenuNormal({ termsPageLink, privacyPageLink }) {
  return appendTermsAndPrivacyItems([], { termsPageLink, privacyPageLink });
}

export function getGlobalNavMenuElevated({ termsPageLink, privacyPageLink }) {
  return appendTermsAndPrivacyItems(GLOBAL_NAV_MENU_ELEVATED, { termsPageLink, privacyPageLink });
}
