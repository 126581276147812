import React from "react";
import PropTypes from "prop-types";
import { Typography, Row, Col, Icon } from "antd";
import classnames from "classnames";
import { parseDateTime } from "../../../utils/dateTimeUtils";
import { isRegistrationClosed, isRegistrationNotOpenYet } from "../../../utils/registrationUtil";
import DateTimeValueWithTimezone from "../../DateTimeValueWithTimezone";
import MyRegistrationItemAction from "./MyRegistrationItemAction";
import { REGISTRATION_LIST_MODE } from "./MyRegistrationsConstants";
import "./MyRegistrationItem.scss";

// Basically, a clone of MyRegistrationItem, except a few changes to handle Registrations that are Public and New.

function MyPublicRegistrationItem({ Reg, listMode }) {
  const regIsClosed = isRegistrationClosed(Reg.openAt, Reg.closeAt);
  const regIsNotOpenYet = isRegistrationNotOpenYet(Reg.openAt, Reg.closeAt);

  const containerClassName = classnames("my-registration-item__container", {
    "my-registration-item__container--disabled": regIsClosed,
  });

  if (regIsNotOpenYet) {
    return null;
  }

  return (
    <Row type="flex" justify="space-between" className={containerClassName}>
      <Col span={14}>
        <Typography.Title level={4}>{Reg.name}</Typography.Title>
        <Row type="flex">
          <Col>
            <Icon type="calendar" />
          </Col>
          <Col>
            <Row type="flex">
              <div className="my-registration-item__label">Open:</div>
              <DateTimeValueWithTimezone value={parseDateTime(Reg.openAt)} referenceTimezoneName={Reg.timezoneName} />
            </Row>
            <Row type="flex">
              <div className="my-registration-item__label">Close:</div>
              <DateTimeValueWithTimezone value={parseDateTime(Reg.closeAt)} referenceTimezoneName={Reg.timezoneName} />
            </Row>
          </Col>
        </Row>
        <div className="my-registration-item__button-container">
          <MyRegistrationItemAction regIsClosed={regIsClosed} regId={Reg.id} listMode={listMode} />
        </div>
      </Col>
    </Row>
  );
}

MyPublicRegistrationItem.propTypes = {
  Reg: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    openAt: PropTypes.string.isRequired,
    closeAt: PropTypes.string.isRequired,
    timezoneName: PropTypes.string.isRequired,
  }).isRequired,
  listMode: PropTypes.oneOf(Object.values(REGISTRATION_LIST_MODE)).isRequired,
};

export default MyPublicRegistrationItem;
