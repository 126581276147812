import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { Input, message, Spin } from "antd";
import { APPLY_DISCOUNT_CODE } from "../../../../../schemas/paymentGql";

const { Search } = Input;

const DiscountCodeInput = ({ session }) => {
  const [applyDiscountCode] = useMutation(APPLY_DISCOUNT_CODE, {
    refetchQueries: ["Reg_GetSessionCharges"],
    onCompleted: result => {
      console.log(result);
      message.success("Discount code accepted!");
    },
    onError: err => {
      console.log(err);
      message.error("Invalid code entered!");
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitDiscountCode = async code => {
    setIsSubmitting(true);

    const variables = {
      session,
      code,
    };
    await applyDiscountCode({ variables });

    setIsSubmitting(false);
  };

  return (
    <>
      {session && (
        <>
          <Spin spinning={isSubmitting}>
            <Search
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
              placeholder="Discount code"
              enterButton="Apply >"
              maxLength={20}
              size="small"
              onSearch={value => handleSubmitDiscountCode(value)}
            />
          </Spin>
        </>
      )}
    </>
  );
};

DiscountCodeInput.propTypes = {
  session: PropTypes.string,
};

DiscountCodeInput.defaultProps = {
  session: null,
};

export default DiscountCodeInput;
