import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Drawer, message } from "antd";
import gql from "fraql";
import { get } from "lodash";
import * as yup from "yup";
import has from "lodash/has";
import { serverErrorStatus } from "../utils/graphqlErrors";
import { useRegContext } from "../utils/useRegContext";
import { schemaNullable } from "../utils/yupUtils";
import ActionForm from "./ActionForm";
import { DISPLAY_MODES as FORM_FIELD_GROUP_DISPLAY_MODES } from "./FormFields/FormFieldGroup";

const INSERT_TEAM = gql`
  mutation DrawerCreateTeam_InsertTeam(
    $name: String!
    $CompetitionId: ID!
    $RegId: ID
    $GroupId: ID
    $TeamManagerPersonId: ID
  ) {
    insertTeam(
      name: $name
      CompetitionId: $CompetitionId
      RegId: $RegId
      GroupId: $GroupId
      TeamManagerPersonId: $TeamManagerPersonId
    ) {
      returning {
        id
      }
    }
  }
`;

const TEAM_FIELDS = {
  name: {
    type: "text",
    label: "Team name",
    required: true,
  },
};

const GROUP_FIELDS = {
  GroupId: {
    type: "group",
    label: "Group",
    placeholder: "Select a group",
    required: false,
    displayMode: FORM_FIELD_GROUP_DISPLAY_MODES.treeSelect,
  },
};

const initialValues = {
  name: "",
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Please enter a team name.")
    // eslint-disable-next-line no-template-curly-in-string
    .max(255, "Name must be ${max} characters or fewer."),
  GroupId: schemaNullable(yup.string()),
});

function DrawerCreateTeam({
  isVisible,
  handleClose,
  handleSuccess,
  competitionId,
  regId,
  mutationOptions,
  showGroupField,
}) {
  const [insertTeam] = useMutation(INSERT_TEAM, mutationOptions);

  const regContext = useRegContext();
  const TeamManagerPersonId = get(regContext, "regContextData.PersonId", null);

  const fields = useMemo(() => {
    let result;

    if (showGroupField) {
      result = { ...TEAM_FIELDS, ...GROUP_FIELDS };
    } else {
      result = TEAM_FIELDS;
    }

    return result;
  }, [showGroupField]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { name, GroupId } = values;

      try {
        const result = await insertTeam({
          variables: { name, CompetitionId: competitionId, RegId: regId, GroupId, TeamManagerPersonId },
        });

        message.success("New team created.");

        handleSuccess(result.data.insertTeam.returning.id);

        actions.resetForm();
      } catch (error) {
        console.error(error);

        const errorStatus = serverErrorStatus(error);

        actions.setSubmitting(false);
        actions.setStatus(errorStatus);

        if (!has(errorStatus, "validationError")) {
          // Unexpected error
          Sentry.captureException(error);
        }
      }
    },
    [insertTeam, handleSuccess, competitionId, regId, TeamManagerPersonId],
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Drawer title="Create Team" width={520} onClose={handleClose} visible={isVisible}>
      <ActionForm
        fields={fields}
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleSubmit={handleSubmit}
        submitText="Create"
        formLayout="vertical"
      />
    </Drawer>
  );
}

DrawerCreateTeam.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func,
  competitionId: PropTypes.string.isRequired,
  regId: PropTypes.string,
  mutationOptions: PropTypes.object,
  showGroupField: PropTypes.bool,
};

DrawerCreateTeam.defaultProps = {
  mutationOptions: {},
  handleSuccess: null,
  regId: null,
  showGroupField: false,
};

export default DrawerCreateTeam;
