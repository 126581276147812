import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import { Transfer } from "formik-antd";
import { get, omit } from "lodash";
import FormFieldFormItem from "./FormFieldFormItem";

/**
 * A Competition list picker using the AntD Transfer box instead of a Select picker.
 * Typically called by FormFieldCompetition when Form builder calls for DISPLAY_MODES: transfer.
 */
function FormFieldTransfer(props) {
  const { name, meta, disabled, formik } = props;
  const { setFieldTouched, setFieldValue } = formik;

  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const options = get(meta, "options", []);

  const handleChange = useCallback(
    newTargetKeys => {
      setFieldTouched(name, true);
      setFieldValue(name, newTargetKeys);
    },
    [setFieldTouched, setFieldValue, name],
  );

  return (
    <FormFieldFormItem
      {...omit(props, ["formik"])}
      displayDefaultLabel={displayDefaultLabel}
      className="form-field-select"
    >
      <Transfer
        name={name}
        dataSource={options}
        showSearch
        listStyle={{
          width: 600,
          height: 500,
        }}
        disabled={disabled}
        operations={["Select", "Deselect"]}
        onChange={handleChange}
        render={item => `${item.title} (${item.description})`}
      />
    </FormFieldFormItem>
  );
}

FormFieldTransfer.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
};

export default connect(FormFieldTransfer);
