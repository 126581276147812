export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "h:mm a";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_TIME_INPUT_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
export const DATE_TIME_COMPARABLE_FORMAT = `${DATE_FORMAT} H:mm`;
export const LESS_OR_EQUAL_DATE_TIME_MSG = "Minimum value must be less than or equal to maximum value.";
export const GREATER_OR_EQUAL_DATE_TIME_MSG = "Maximum value must be greater than or equal to minimum value.";
export const DATE_FULL_TIME = `${DATE_FORMAT} HH:mm:ss`;
export const DATE_FULL_TIME_MILLISECONDS = `${DATE_FULL_TIME}.SSS`;
export const DATE_HOMEPAGE = `YYYY MMM DD`;

export const VIEW_TIME_FORMAT = "HH:mm";
export const VIEW_DATE_TIME_FORMAT = `${DATE_FORMAT} ${VIEW_TIME_FORMAT}`;
export const VIEW_FULL_TIME_FORMAT = "h:mm:ss a";

export const UTC_TIME_ZONE = "Etc/UTC";
