import { chain } from "lodash";
import toNumber from "lodash/toNumber";

export function parseAgeGroup(ageGroup) {
  const lowerLimit = ageGroup.lower_limit;
  const upperLimit = ageGroup.upper_limit;

  if (upperLimit){
    return `${lowerLimit}-${upperLimit}`;
  }
  
  return `${lowerLimit}+`;
}

export function parseAgeGroups(ageGroups) {
  return ageGroups.map(parseAgeGroup).join(", ");
}

export const formatAgeGroups = value => {
  const valueSplitIntoArrayOfArrays = chain(value)
    .replace(/\s/g, "")
    .replace("+", "-999999")
    .split(",")
    .map(group => group.split("-"))
    .value();

  return valueSplitIntoArrayOfArrays.map(ageGroupAsArrayOfStrings => {
    return ageGroupAsArrayOfStrings.map(eachValue => toNumber(eachValue));
  });
};
