import gql from "fraql";

export const GET_SESSION_CHARGES = gql`
  query Reg_GetSessionCharges($RegSubmissionId: ID!) {
    getSessionCharges(RegSubmissionId: $RegSubmissionId) {
      charges
    }
  }
`;

export const APPLY_DISCOUNT_CODE = gql`
  mutation Reg_ApplyDiscountCode($session: String!, $code: String!) {
    applyDiscountCode(session: $session, code: $code) {
      result
    }
  }
`;
