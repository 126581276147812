import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row } from "antd";
import { Col } from "antd/es/grid";
import { isEmpty } from "lodash";
import uuidv4 from "uuid/v4";
import DiscountCodeInput from "./DiscountCodeInput";

const SessionSummary = ({ charges, isDisplayHeading }) => {
  const [session, setSession] = useState({});
  const [hasFeePayable, setHasFeePayable] = useState(false);

  useEffect(() => {
    if (charges) {
      setSession(charges);
    }
  }, [charges]);

  useEffect(() => {
    if (charges) {
      if (!isEmpty(charges)) {
        if (charges.Items && charges.Items.length > 0) {
          setHasFeePayable(true);
        } else {
          setHasFeePayable(false);
        }
      }
    }
  }, [charges]);

  const rowId = () => uuidv4();

  return (
    <>
      {hasFeePayable && (
        <div style={{ paddingTop: "5px", paddingBottom: "20px", color: "rgba(0,0,0,0.65)" }}>
          {isDisplayHeading && (
            <Row style={{ paddingBottom: "20px" }}>
              <Col span={24} style={{ textAlign: "center" }}>
                <span className="heading">Payment Required</span>
              </Col>
            </Row>
          )}
          {!isEmpty(session) &&
            session.Items &&
            session.Items.map(item => (
              <Row key={rowId()}>
                <Col span={16}>
                  <div>
                    <strong>
                      {item.Quantity} x {item.Headline}
                    </strong>
                  </div>
                  {item.DetailLineItems && item.DetailLineItems.map(line => <div key={line}>{line}</div>)}
                  {item.DiscountCaption && (
                    <div>
                      <em>{item.DiscountCaption}</em>
                    </div>
                  )}
                </Col>
                <Col span={8} className="rightAlign">
                  {item.AmountStr}
                </Col>
              </Row>
            ))}
          {hasFeePayable && (
            <>
              <Row>
                <Col span={24}>
                  <hr className="light" style={{ marginBottom: "10px", marginTop: "10px" }} />
                </Col>
              </Row>
              <Row>
                <Col span={16}>Subtotal</Col>
                <Col span={8} className="rightAlign">
                  {session.SubTotalStr}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DiscountCodeInput session={session.Session} />
                </Col>
              </Row>
              <Row>
                <Col span={16}>Processing Fee</Col>
                <Col span={8} className="rightAlign">
                  {session.FeeStr}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <hr className="light" />
                </Col>
              </Row>
              <Row style={{ paddingTop: "20px" }}>
                <Col span={16}>
                  <strong>Total Due</strong>
                </Col>
                <Col span={8} className="rightAlign">
                  <strong>{session.TotalStr}</strong>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </>
  );
};

SessionSummary.propTypes = {
  charges: PropTypes.object,
  isDisplayHeading: PropTypes.bool,
};

SessionSummary.defaultProps = {
  charges: {},
  isDisplayHeading: false,
};

export default SessionSummary;
