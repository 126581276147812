import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Button, Typography, Spin } from "antd";
import gql from "fraql";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import padStart from "lodash/padStart";
import isBlank from "../../../../utils/isBlank";
import SectionHeaderContainer from "../../../SectionHeaderContainer";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../../../StatusText";
import TeamQualificationScoreNotProvidedAlert from "../TeamQualificationScoreNotProvidedAlert";
import ViewTeamDrawer from "../ViewTeamDrawer/ViewTeamDrawer";
import "./PersonRegistrationCompetitionDetails.scss";

const GET_COMPETITION_INFORMATION = gql`
  query PersonRegistrationCompetitionDetails_GetCompetitionInformation($CompetitionId: String!) {
    Competition_by_pk(id: $CompetitionId) {
      id
      name
      teamCompetition
      teamPositions
      qualificationScoreType
      qualificationScoreRequired
      teamQualificationScoreDetermination

      SportMeet {
        id
        name
      }
    }
  }
`;

const GET_AGE_GROUP = gql`
  query RegStepValue_GetAgeGroup($AgeGroupId: String!) {
    AgeGroup_by_pk(id: $AgeGroupId) {
      id
      lower_limit
      upper_limit
    }
  }
`;

function getCompetitionName(competition) {
  const sportMeetName = get(competition, "SportMeet.name");
  const competitionName = get(competition, "name");

  if (!sportMeetName) {
    return competitionName;
  }

  return `${sportMeetName} - ${competitionName}`;
}

function PersonRegistrationCompetitionDetails({ competitionId, ageGroupId, team, index, regId, readOnly }) {
  const { loading: competitionsLoading, error: competitionsError, data: competitionsData } = useQuery(
    GET_COMPETITION_INFORMATION,
    {
      variables: { CompetitionId: competitionId },
    },
  );

  const { data: ageGroupData } = useQuery(
    GET_AGE_GROUP,
    {
      variables: { AgeGroupId: ageGroupId },
      skip: !ageGroupId
    },
  );

  const ageGroup = useMemo(() => {
    if (!ageGroupData){
      return null;
    }

    const temp = get(ageGroupData, "AgeGroup_by_pk", null);

    if (!temp){
      return null;
    }
    return `${temp.lower_limit} ${(temp.upper_limit ? `- ${temp.upper_limit}` : "+")}`;
  },[ageGroupData]);

  const competition = useMemo(() => {
    return get(competitionsData, "Competition_by_pk", null);
  }, [competitionsData]);

  const teamPositions = get(competition, "teamPositions", []);
  const teamPerson = get(team, "PersonInTeam.0", null);

  const [viewTeamDrawerState, setViewTeamDrawerState] = useState({ isVisible: false, teamId: null });

  const handleClickViewTeam = useCallback(
    teamId => {
      setViewTeamDrawerState({ isVisible: true, teamId });
    },
    [setViewTeamDrawerState],
  );

  const handleCloseDrawer = useCallback(() => {
    setViewTeamDrawerState({ isVisible: false, teamId: null });
  }, [setViewTeamDrawerState]);

  if (competitionsLoading && !competitionsData) {
    return (
      <div className="person-registration-competitions-team-item__content">
        <div className="person-registration-competitions-team-item__loading-container">
          <Spin size="small" />
        </div>
      </div>
    );
  }

  if (competitionsError) {
    return (
      <div className="person-registration-competitions-team-item__content">
        <Alert
          type="error"
          message="Sorry, there was an issue loading the data for this competition."
          className="form-field-alert"
        />
      </div>
    );
  }

  function renderAgeGroup() {
    if (isNil(ageGroup)){
      return (<></>);
    }

    return (
      <div className="person-registration-competitions-team-item__content">
        <div className="person-registration-competitions-team-item__row">
          <span className="person-registration-competitions-team-item__field-label">Age Group:</span>
          <span className="person-registration-competitions-team-item__field-value">{ageGroup}</span>
        </div>
      </div>
    );
  }

  function renderContent() {
    if (isNil(team)) {
      return (
        <></>
      );
    }

    let teamPosition;
    if (competition.teamCompetition) {
      teamPosition = get(teamPerson, "position");

      if (isBlank(teamPosition)) {
        teamPosition = "N/A";
      } else if (!includes(teamPositions, teamPosition)) {
        teamPosition = (
          <StatusText status={STATUS_TEXT_STATUS_OPTIONS.ERROR}>{teamPerson.position} [Invalid]</StatusText>
        );
      }
    }

    return (
      <div className="person-registration-competitions-team-item__content">
        <div className="person-registration-competitions-team-item__row">
          <span className="person-registration-competitions-team-item__field-label">Team:</span>
          <span className="person-registration-competitions-team-item__field-value">{team.name}</span>
          <div className="person-registration-competitions-team-item__row-actions">
            <Button onClick={() => handleClickViewTeam(team.id)}>View Team</Button>
          </div>
        </div>
        {!isEmpty(competition.teamPositions) && (
          <div className="person-registration-competitions-team-item__row">
            <span className="person-registration-competitions-team-item__field-label">Position:</span>
            <span className="person-registration-competitions-team-item__field-value">{teamPosition}</span>
          </div>
        )}
        <TeamQualificationScoreNotProvidedAlert
          style={{ marginTop: 15 }}
          team={{ ...team, Competition: competition }}
        />
      </div>
    );
  }

  return (
    <div className="person-registration-competitions-team-item">
      <SectionHeaderContainer>
        <Typography.Title level={4}>
          <span className="person-registration-competitions-team-item__number">{padStart(index + 1, 2, "0")}.</span>
          <span>{getCompetitionName(competition)}</span>
        </Typography.Title>
      </SectionHeaderContainer>

      {renderAgeGroup()}
      {renderContent()}

      <ViewTeamDrawer
        visible={viewTeamDrawerState.isVisible}
        teamId={viewTeamDrawerState.teamId}
        regId={regId}
        handleClose={handleCloseDrawer}
        readOnly={readOnly}
      />
    </div>
  );
}

PersonRegistrationCompetitionDetails.propTypes = {
  competitionId: PropTypes.string.isRequired,
  ageGroupId: PropTypes.string,
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    qualificationScore: PropTypes.object,
    Competition: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    PersonInTeam: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        position: PropTypes.string,
      }),
    ).isRequired,
  }),
  index: PropTypes.number.isRequired,
  regId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

PersonRegistrationCompetitionDetails.defaultProps = {
  team: null,
  ageGroupId: null,
  readOnly: false,
};

export default PersonRegistrationCompetitionDetails;
