import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Icon, Spin, Typography } from "antd";
import { Formik } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import gql from "fraql";
import { isNil, get } from "lodash";
import * as yup from "yup";
import ENV from "../constants/envConstants";
import SpinPageContent from "../components/SpinPageContent";
import { useTheme } from "../components/Theme/ThemeContext";
import "./Login.scss";

// GQL Queries
export const RESET_PASSWORD = gql`
  mutation resetPassword($username: String!, $password: String!, $reset_key: String!, $recaptchaToken: String!) {
    resetPassword(username: $username, password: $password, reset_key: $reset_key, recaptchaToken: $recaptchaToken) {
      result
    }
  }
`;

// Form Validation Initial values + schema validation
const resetInitialValues = {
  password: undefined,
  confirmPassword: undefined,
};

const resetValidationSchema = yup.object({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(6, "Password must be at least 6 characters long.")
    .matches(/^(?=.*[A-Z])(?=.*\d.*\d).+$/, "Password must contain at least one capital letter and two digits."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords do not match. Please check and try again.")
    .required("Please confirm your password."),
});

// React Component
const Reset = () => {
  const [mode, setMode] = useState("RESET");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const themeContext = useTheme();

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const { key, user } = useMemo(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    return { key: get(params, "key", null), user: get(params, "user", null) };
  }, []);

  // Fetch the reCAPTCHA token. Called by useEffect below.
  const fetchRecaptchaToken = useCallback(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(ENV.RECAPTCHA_PUBLIC_SITE_KEY, { action: mode }).then(token => {
        setRecaptchaToken(token);
      });
    });
  }, [mode]);

  // Download and attach reCAPTCHA script. Set up event handler once script has downloaded.
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${ENV.RECAPTCHA_PUBLIC_SITE_KEY}`;
    script.addEventListener("load", fetchRecaptchaToken);
    // Tokens expire every 120 seconds. Therefore, set up timer to refresh token every 100 seconds, in case user idles on screen.
    setTimeout(fetchRecaptchaToken, 100000);
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetSubmit = useCallback(
    async (values, actions) => {
      const { password } = values;
      const { setSubmitting, setFieldError } = actions;

      // the reCAPTCHA token may not be ready upon form submission. Simply wait until it is available...
      while (recaptchaToken === "") {
        console.log("Awaiting recaptcha token...");
        // eslint-disable-next-line no-await-in-loop
        await new Promise(r => setTimeout(r, 1000));
      }

      await resetPassword({
        variables: {
          username: user,
          password,
          reset_key: key,
          recaptchaToken,
        },
      })
        .then(({ data }) => {
          const success = get(data, "resetPassword.result.success", null);
          if (!isNil(success)) {
            if (success) {
              setMode("RESETSUCCESS");
              return;
            }
            const resultError = get(data, "resetPassword.result.error", null);
            if (!isNil(resultError)) {
              setFieldError("confirmPassword", resultError);
              setSubmitting(false);
              fetchRecaptchaToken();
            }
          }
          setFieldError("confirmPassword", "An error occured. Please try again later.");
          setSubmitting(false);
          fetchRecaptchaToken();
        })
        .catch(e => {
          console.error("Reset mutation failed. Error below.");
          console.error(e);
          setFieldError("confirmPassword", "An error occured. Please try again later.");
          setSubmitting(false);
          fetchRecaptchaToken();
        });
    },
    [recaptchaToken, resetPassword, fetchRecaptchaToken, key, user],
  );

  return (
    <>
      <div className="login-form__main">
        <div>
          {isNil(themeContext) && <SpinPageContent />}
          {!isNil(themeContext) && <img alt="logo" className="login-form__img" src={themeContext.login_logo_path} />}
        </div>
        <div style={{ textAlign: "center", padding: "1em 0 1em 0" }}>
          {isNil(themeContext) && <Spin size="small" />}
          {!isNil(themeContext) && <Typography.Title level={4}>{themeContext.siteName}</Typography.Title>}
        </div>

        {mode === "RESET" && (
          <Formik
            initialValues={resetInitialValues}
            validationSchema={resetValidationSchema}
            onSubmit={handleResetSubmit}
          >
            <Form name="reset">
              <div className="login-form__input-div" style={{ marginTop: 0 }}>
                <Form.Item name="password">
                  <Input
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    addonBefore={<Icon type="lock" />}
                  />
                </Form.Item>
                <Form.Item name="confirmPassword">
                  <Input
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm your password"
                    addonBefore={<Icon type="lock" />}
                  />
                </Form.Item>
              </div>
              <SubmitButton type="primary" block className="login-form__submit-btn">
                Reset Password &gt;
              </SubmitButton>
            </Form>
          </Formik>
        )}
        {mode === "RESETSUCCESS" && (
          <div className="login-form__input-div">
            <div style={{ paddingBottom: "1em" }}>
              <Icon type="check-circle" theme="twoTone" twoToneColor="#53c41a" style={{ fontSize: "5em" }} />
            </div>
            <p>Success!</p>
            <p>You can now return to the login screen and log in with your new credentials.</p>
          </div>
        )}
      </div>
    </>
  );
};

Reset.propTypes = {};

export default Reset;
