import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Drawer } from "antd";
import gql from "fraql";
import get from "lodash/get";
import isNil from "lodash/isNil";
import LayoutTabsState from "../../../LayoutTabsState";
import SpinPageContent from "../../../SpinPageContent";
import ViewTeamDrawerJoinedTab from "./ViewTeamDrawerJoinedTab";
import ViewTeamDrawerPendingTab from "./ViewTeamDrawerPendingTab";

const TAB_JOINED = "joined";
const TAB_PENDING = "pending";

const TAB_ITEMS = [
  {
    key: TAB_JOINED,
    title: "Joined",
  },
  {
    key: TAB_PENDING,
    title: "Pending",
  },
];

export const GET_TEAM = gql`
  query ViewTeamDrawer_GetTeam($teamId: String!) {
    Team_by_pk(id: $teamId) {
      id
      name
      Competition {
        id
        name
        SportMeet {
          id
          name
        }
      }
    }
  }
`;

function ViewTeamDrawer({ visible, teamId, regId, handleClose, readOnly }) {
  const [currentTab, setCurrentTab] = useState(TAB_JOINED);

  const { loading: teamLoading, error: teamError, data: teamData } = useQuery(GET_TEAM, {
    variables: { teamId },
    skip: isNil(teamId),
  });

  const drawerTitle = useMemo(() => {
    if (teamError || !teamData) {
      return "Team";
    }

    const teamName = get(teamData, "Team_by_pk.name", "Team", "");
    const competitionName = get(teamData, "Team_by_pk.Competition.name", "Competition", "");
    const sportMeetName = get(teamData, "Team_by_pk.Competition.SportMeet.name", "");

    let name = `${competitionName} - ${teamName}`;

    if (sportMeetName) {
      name = `${sportMeetName} - ${name}`;
    }

    return name;
  }, [teamError, teamData]);

  const handleCloseDrawer = useCallback(() => {
    handleClose();

    // Change to the default "Joined" tab *after* the drawer has been closed, for better UX.
    setTimeout(() => {
      setCurrentTab(TAB_JOINED);
    }, 300);
  }, [handleClose]);

  const tabContent = useMemo(() => {
    if (teamLoading && !teamData) {
      return <SpinPageContent />;
    }

    if (teamError) {
      return (
        <Alert
          message="Team failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      );
    }

    if (currentTab === TAB_JOINED) {
      return <ViewTeamDrawerJoinedTab teamId={teamId} regId={regId} readOnly={readOnly} />;
    }

    return <ViewTeamDrawerPendingTab teamId={teamId} regId={regId} readOnly={readOnly} />;
  }, [currentTab, teamLoading, teamError, teamData, regId, teamId, readOnly]);

  function handleClickTab({ key }) {
    setCurrentTab(key);
  }

  return (
    <Drawer
      title={
        <div>
          <div style={{ paddingLeft: 24, paddingBottom: 10 }}>{drawerTitle}</div>
          <LayoutTabsState items={TAB_ITEMS} currentItemKey={currentTab} handleClick={handleClickTab} />
        </div>
      }
      onClose={handleCloseDrawer}
      visible={visible}
      width={
        // Wide enough for 4 columns in the table with each column having a suitable width.
        845
      }
      className="ant-drawer-tabs"
    >
      {tabContent}
    </Drawer>
  );
}

ViewTeamDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  teamId: PropTypes.string,
  regId: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

ViewTeamDrawer.defaultProps = {
  teamId: undefined,
  regId: undefined,
  readOnly: false,
};

export default ViewTeamDrawer;
