import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { message } from "antd";
import gql from "fraql";
import moment from "moment";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { FIELD_TYPES } from "../../constants/fieldConstants";
import { USER_REGISTRATIONS } from "../../constants/routeConstants";
import { TYPE_ERROR_MESSAGES } from "../../constants/schemaConstants";
import { schemaPhone, schemaTransformBlankToUndefined } from "../../utils/yupUtils";
import ActionForm from "../ActionForm";
import { CONDITION_TYPES } from "../MenuAdmin/Forms/FormFieldCondition";
import { ConnectedFormBottomComponent } from "./Registrations/FormBottomComponent";

const VALIDATION_SCHEMA = yup.object().shape({
  isOwnProfile: yup.bool().strip(),
  firstName: yup
    .string()
    .trim()
    .required("Please enter a first name."),
  middleNames: yup
    .string()
    .trim()
    .default(""),
  lastName: yup
    .string()
    .trim()
    .required("Please enter a last name."),
  dob: yup
    .string()
    .trim()
    .required("Please enter a date of birth.")
    .test("dateOfBirthIsInPast", "Date of birth must be in the past.", function checkDateOfBirthIsInPast(value) {
      return moment.parseZone(value, "YYYY-MM-DD").isBefore(moment());
    }),
  gender: yup
    .string()
    .trim()
    .required("Please select a gender."),
  email: yup
    .string()
    .trim()
    .email(TYPE_ERROR_MESSAGES[FIELD_TYPES.email])
    .default(""),
  phone: schemaPhone(),
  timezoneName: schemaTransformBlankToUndefined(yup.string().default("")),
});

const PERSON_UPDATE = gql`
  mutation PersonEditForm_PersonUpdate(
    $PersonId: ID!
    $firstName: String!
    $middleNames: String
    $lastName: String!
    $dob: date!
    $gender: String
    $email: String
    $phone: String
    $timezoneName: String
  ) {
    updatePerson(
      PersonId: $PersonId
      dob: $dob
      email: $email
      firstName: $firstName
      gender: $gender
      lastName: $lastName
      middleNames: $middleNames
      phone: $phone
      timezoneName: $timezoneName
    ) {
      returning {
        id
        firstName
        middleNames
        lastName
        dob
        gender
        email
        phone
        timezoneName
      }
    }
  }
`;

function PersonEditForm({
  PersonId,
  initialValues,
  handleSuccess,
  persistId,
  saveContext,
  formControls,
  disabled,
  showFormBottomComponent,
  readOnly,
  useFormValidityForSubmitDisabled,
}) {
  const history = useHistory();
  const isEditingUserEmail = !history.location.pathname.includes(USER_REGISTRATIONS);

  const FIELDS = {
    isOwnProfile: {
      type: "hidden",
    },
    personalHeading: {
      type: "heading",
      label: "Personal",
    },
    firstName: {
      type: "text",
      label: "First name",
      required: true,
    },
    middleNames: {
      type: "text",
      label: "Middle name(s)",
    },
    lastName: {
      type: "text",
      label: "Last name",
      required: true,
    },
    dob: {
      type: "date",
      label: "Date of birth",
      required: true,
    },
    gender: {
      type: "gender",
      label: "Gender",
      required: true,
    },
    contactDivider: {
      type: "divider",
    },
    contactHeading: {
      type: "heading",
      label: "Contact",
    },
    email: isEditingUserEmail
      ? {
          type: "email",
          label: "Email",
          disabled: true,
          isEditingUserEmail: true,
        }
      : {
          type: "email",
          label: "Email",
        },
    phone: {
      type: "phone",
      label: "Phone",
    },
    locationDivider: {
      type: "divider",
      conditions: [
        {
          type: CONDITION_TYPES.showOnMatch,
          field: "isOwnProfile",
          fieldValue: true,
        },
      ],
    },
    locationHeading: {
      type: "heading",
      label: "Location",
      conditions: [
        {
          type: CONDITION_TYPES.showOnMatch,
          field: "isOwnProfile",
          fieldValue: true,
        },
      ],
    },
    timezoneName: {
      type: "timezone",
      label: "Time zone",
      required: false,
      helpText: "Allows you to see date/time values in the time zone of your choice",
      // See FUS-1028 for an explanation of why the time zone field should only be shown when editing your own profile.
      conditions: [
        {
          type: CONDITION_TYPES.showOnMatch,
          field: "isOwnProfile",
          fieldValue: true,
        },
      ],
    },
  };

  const [personUpdate] = useMutation(PERSON_UPDATE);

  const ControlFormBottomComponent = useCallback(
    props => {
      const { step, stepIndex, prevStep, nextStep } = formControls;

      return (
        <ConnectedFormBottomComponent
          step={step}
          stepIndex={stepIndex}
          prevStep={prevStep}
          nextStep={nextStep}
          {...props}
        />
      );
    },
    [formControls],
  );

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        await personUpdate({
          variables: { ...values, PersonId },
        });

        if (handleSuccess) {
          await handleSuccess();
        }

        actions.setSubmitting(false);
        message.success("Profile updated.");
      } catch (submitError) {
        console.error(submitError);

        actions.setSubmitting(false);
        actions.setStatus({ type: "error" });

        message.error("Failed to update profile.");

        Sentry.captureException(submitError);
      }
    },
    [PersonId, personUpdate, handleSuccess],
  );

  return (
    <ActionForm
      disabled={disabled}
      fields={FIELDS}
      validationSchema={VALIDATION_SCHEMA}
      FormBottomComponent={formControls ? ControlFormBottomComponent : null}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      saveContext={saveContext}
      persistId={persistId}
      showFormBottomComponent={showFormBottomComponent}
      readOnly={readOnly}
      submitText="Save"
      useFormValidityForSubmitDisabled={useFormValidityForSubmitDisabled}
    />
  );
}

PersonEditForm.propTypes = {
  PersonId: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  formControls: PropTypes.object,
  handleSuccess: PropTypes.func,
  persistId: PropTypes.string,
  saveContext: PropTypes.bool,
  disabled: PropTypes.bool,
  showFormBottomComponent: PropTypes.bool,
  readOnly: PropTypes.bool,
  useFormValidityForSubmitDisabled: PropTypes.bool,
};

PersonEditForm.defaultProps = {
  handleSuccess: null,
  saveContext: false,
  persistId: "",
  formControls: null,
  disabled: false,
  showFormBottomComponent: true,
  readOnly: false,
  useFormValidityForSubmitDisabled: false,
};

export default PersonEditForm;
