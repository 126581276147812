import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { message } from "antd";
import { Formik } from "formik";
import { Form } from "formik-antd";
import gql from "fraql";
import * as yup from "yup";
import get from "lodash/get";
import isInteger from "lodash/isInteger";
import isNil from "lodash/isNil";
import { FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT } from "../../../../constants/formConstants";
import { REG_STEP_STATUSES } from "../../../../constants/regStatusConstants";
import FormikPersist from "../../../../utils/formikPersist";
import { GET_STEP_REG_STEP_VALUE, SAVE_STEP_REG_STEP_VALUE } from "../../../../schemas/regStepValueGql";
import AlertFailedLoading from "../../../AlertFailedLoading";
import FormFieldSport, { DISPLAY_MODES as FORM_FIELD_SPORT_DISPLAY_MODES } from "../../../FormFields/FormFieldSport";
import FormStatusMessage from "../../../FormStatusMessage";
import FormValidationMessage from "../../../FormValidationMessage";
import PromptDirtyForm from "../../../PromptDirtyForm";
import SpinPageContent from "../../../SpinPageContent";
import TextEditorPreview from "../../../TextEditor/TextEditorPreview";
import { ConnectedFormBottomComponent } from "../FormBottomComponent";

const GET_SPORTS_STEP_SPORT_LIST = gql`
  query RegistrationStepSports_GetSports($StepId: String!) {
    getSportsStepSports(StepId: $StepId) {
      result
    }
  }
`;

const fields = {
  sports: {
    name: "sports",
    type: "sport",
    label: "Sports",
    displayMode: FORM_FIELD_SPORT_DISPLAY_MODES.checkboxList,
  },
};

const RegistrationStepSports = ({
  step,
  stepIndex,
  updateRegStepStatus,
  nextStep,
  prevStep,
  regIsReadOnly,
  PersonId,
  showFormBottomComponent,
  readOnly,
}) => {
  const content = get(step, "meta.content", "");

  const validationSchema = useMemo(() => {
    const minSports = get(step, "meta.minSports", 0);
    const maxSports = get(step, "meta.maxSports", null);

    const sportsText = minSports === 1 ? "sport" : "sports";

    // Generate the dynamic schema based on Mix/Max Sports
    return yup.object().shape({
      sports: yup
        .array()
        .test("minSports", `Please select at minimum ${minSports} ${sportsText}.`, function checkMinSportsSelection(
          value,
        ) {
          if (!isNil(minSports) && isInteger(minSports)) {
            return value.length >= minSports;
          }

          return true;
        })
        .test(
          "maxSports",
          `The maximum number of sports you can select is ${maxSports}.`,
          function checkMaxSportsSelection(value) {
            if (!isNil(maxSports) && isInteger(maxSports)) {
              return value.length <= maxSports;
            }

            return true;
          },
        ),
    });
  }, [step]);

  const {
    loading: regStepValueLoading,
    error: regStepValueError,
    data: regStepValueData,
    refetch: refetchRegStepValue,
  } = useQuery(GET_STEP_REG_STEP_VALUE, {
    variables: {
      PersonId,
      RegId: step.RegId,
      StepId: step.id,
    },
  });

  const [saveStepRegStepValue] = useMutation(SAVE_STEP_REG_STEP_VALUE);

  const initialValues = useMemo(() => {
    const regStepValue = get(regStepValueData, "RegStepValue.0", null);

    if (!regStepValue) {
      return { sports: [] };
    }

    const sports = get(regStepValue, "value.sports", []);

    return { sports };
  }, [regStepValueData]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        const regStepValueObjects = [
          {
            PersonId,
            RegId: step.RegId,
            StepId: step.id,
            value: values,
          },
        ];

        await saveStepRegStepValue({ variables: { objects: regStepValueObjects } });

        await updateRegStepStatus(step, REG_STEP_STATUSES.completed);

        await refetchRegStepValue();

        actions.setSubmitting(false);

        message.success("Sports selection saved.");

        nextStep(true);
      } catch (submitError) {
        actions.setSubmitting(false);
        actions.setStatus({ type: "error" });

        message.error("Failed to save sports.");
        console.error(submitError);

        Sentry.captureException(submitError);
      }
    },
    [step, PersonId, updateRegStepStatus, nextStep, saveStepRegStepValue, refetchRegStepValue],
  );

  if (regStepValueLoading && !regStepValueData) {
    return <SpinPageContent />;
  }

  if (regStepValueError) {
    return <AlertFailedLoading message="Sports selection data failed to load" />;
  }

  return (
    <>
      <TextEditorPreview defaultValue={content} style={{ marginBottom: 24 }} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => handleSubmit(validationSchema.cast(values), actions)}
        isInitialValid={validationSchema.isValidSync(initialValues)}
        enableReinitialize
      >
        {({ status, errors, submitCount, submitForm }) => {
          return (
            <Form {...FORM_ITEM_LAYOUT_PROPS_BY_FORM_LAYOUT.vertical}>
              <FormFieldSport
                name={fields.sports.name}
                meta={{
                  ...fields.sports,
                }}
                disabled={regIsReadOnly}
                submitCount={submitCount}
                query={GET_SPORTS_STEP_SPORT_LIST}
                queryOptions={{ variables: { StepId: step.id } }}
                readOnly={readOnly}
                dataPath="getSportsStepSports.result"
              />

              <FormValidationMessage fields={fields} errors={errors} submitCount={submitCount} />

              <FormStatusMessage status={status} />

              {showFormBottomComponent && (
                <ConnectedFormBottomComponent
                  step={step}
                  stepIndex={stepIndex}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  handleSubmit={submitForm}
                  disableSubmit={regIsReadOnly}
                />
              )}

              <PromptDirtyForm />

              <FormikPersist uuid={step.id} setFormikState={false} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

RegistrationStepSports.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string.isRequired,
    RegId: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      content: PropTypes.string.isRequired,
      minSports: PropTypes.number.isRequired,
      maxSports: PropTypes.number,
    }),
  }).isRequired,
  stepIndex: PropTypes.number.isRequired,
  updateRegStepStatus: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  regIsReadOnly: PropTypes.bool.isRequired,
  PersonId: PropTypes.string.isRequired,
  showFormBottomComponent: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default RegistrationStepSports;
