/**
 * Provides Google reCaptcha V3 services. Non React-Component.
 * @returns Null
 */
function getRecaptcha3() {
  /**
   * This file was inspired/heavily copied from this GitHub Repo: https://github.com/armenstepanyan/react-google-recaptcha3/blob/main/src/lib/react-google-recaptcha3.js
   * All functionality is basically raw JavaScript, absolutely no dependencies.
   * Updated to please the ESLinter, and spelling mistakes.
   */
  const data = {
    baseUrl: "https://www.google.com/recaptcha/api.js",
    siteKey: "",
    isLoaded: false,
    isPending: false,
    scriptId: "",
  };

  return {
    /**
     * @param {String} siteKey - Provide your site reCaptcha key.
     * @returns null
     */
    init: siteKey => {
      window.reactRecaptcha3Loaded = () => {
        data.isLoaded = true;
        data.isPending = false;
      };
      data.scriptId = +new Date();

      return new Promise((resolve, reject) => {
        if (data.isLoaded || data.isPending) {
          resolve("success");
        } else {
          data.isPending = true;
          data.siteKey = siteKey;
          const script = document.createElement("script");
          script.innerHTML = "";
          script.src = `${data.baseUrl}?render=${data.siteKey}&onload=reactRecaptcha3Loaded`;
          script.id = `recaptcha-${data.scriptId}`;
          script.async = true;
          script.defer = true;
          script.onload = () => {
            resolve("success");
          };
          script.onerror = () => {
            reject(new Error("A fatal error occured during reCaptcha Script loading."));
          };
          (document.head || document.boby).appendChild(script);
        }
      });
    },
    /**
     * @param {string} action - The action taking place when requesting the token. Typically, use the Form Mode for FS3.
     * @returns A Promise. Use .then() to receive the token result.
     */
    getToken: action => {
      try {
        return window.grecaptcha
          ? window.grecaptcha.execute(data.siteKey, action)
          : Promise.reject(new Error("grecaptcha is not initialized: Use ReactRecaptcha3.init(...)"));
      } catch (e) {
        return new Promise((resolve, reject) => {
          reject(new Error(e));
        });
      }
    },
    /**
     * Destroys the embedded reCaptcha script, and removes the Badge in the lower corner.
     */
    destroy: () => {
      data.isLoaded = false;
      const script = document.getElementById(`recaptcha-${data.scriptId}`);
      if (script) {
        script.remove();
      }
      const badge = document.getElementsByClassName("grecaptcha-badge")[0];
      if (badge && badge instanceof HTMLElement) {
        badge.style.visibility = "hidden";
      }
      window.grecaptcha = undefined;
    },
  };
}

const ReactRecaptcha3 = getRecaptcha3();
export default ReactRecaptcha3;
