import React from "react";
import { concat } from "lodash";
import filter from "lodash/filter";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";

/**
 * Processes list of options that contain Active options and Archived/Disabled options with the reason why.
 * @param {*} allRecords - Array of Objects containing an id, name, isAvailable, and reason properties.
 * @param {boolean} disableArchivedRecords - Default = true. Marks Archived options as disabled, if set to true.
 * @param {*} labelDecorator - Anon function that hanldes wrapping archived/disabled options with the reason why they are disabled.
 * @returns {
 * [{value: string, title: string, label: string, disabled: boolean}]
 * |
 * [{key: string, label: string, children: [{value: string, title: string, label: string, disabled: boolean}]}]
 * } An array of prepared objects, or an array of "active" and "archived" prepared objects.
 */
function getActiveArchivedOptions({
  allRecords,
  disableArchivedRecords = true,
  labelDecorator = (record, labelComponent) => labelComponent,
}) {
  const archivedRecords = filter(allRecords, { archived: true });

  // Note: Objects with "isAvailable" property as False are still considered as "Active" options.

  if (!archivedRecords.length) {
    return allRecords.map(record => ({
      value: record.id,
      title: record.name,
      label: !record.reason
        ? labelDecorator(record, record.name)
        : concat(labelDecorator(record, record.name), " [", record.reason, "]"),
      disabled: !record.isAvailable && record.reason,
    }));
  }

  const activeRecords = filter(allRecords, { archived: false });

  return [
    {
      key: "active",
      label: "Active",
      children: activeRecords.map(record => ({
        value: record.id,
        title: record.name,
        label: labelDecorator(record, record.name),
        disabled: !record.isAvailable && record.reason,
      })),
    },
    {
      key: "archived",
      label: "Archived",
      children: archivedRecords.map(record => ({
        value: record.id,
        label: labelDecorator(
          record,
          <span>
            {record.name} <StatusText status={STATUS_TEXT_STATUS_OPTIONS.WARNING}>[Archived]</StatusText>
          </span>,
        ),
        title: record.name,
        disabled: disableArchivedRecords,
      })),
    },
  ];
}

export default getActiveArchivedOptions;
