import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import { InputNumber } from "formik-antd";
import toInteger from "lodash/toInteger";
import { COMPETITION_GENDER_FORMAT, COMPETITION_GENDER_FORMAT_LABEL } from "../../constants/personConstants";
import { FORM_FIELD_DATE_WIDTH } from "../../constants/styleConstants";
import CompetitionAgeGroupList from "../CompetitionManagement/Competition/CompetitionAgeGroupList";
import FormFieldBool from "./FormFieldBool";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldRadioButton from "./FormFieldRadioButton";

const GENDER_FORMAT_OPTIONS = [
  { value: COMPETITION_GENDER_FORMAT.male, label: COMPETITION_GENDER_FORMAT_LABEL[COMPETITION_GENDER_FORMAT.male] },
  { value: COMPETITION_GENDER_FORMAT.female, label: COMPETITION_GENDER_FORMAT_LABEL[COMPETITION_GENDER_FORMAT.female] },
  { value: COMPETITION_GENDER_FORMAT.mixed, label: COMPETITION_GENDER_FORMAT_LABEL[COMPETITION_GENDER_FORMAT.mixed] },
  {
    value: COMPETITION_GENDER_FORMAT.open,
    label: COMPETITION_GENDER_FORMAT_LABEL[COMPETITION_GENDER_FORMAT.open],
  },
];

function FormFieldCompetitorsFilter({ submitCount, meta }) {
  return (
    <>
      <Typography.Text>Specify your competitor categories for this competition.</Typography.Text>

      <div style={{ marginTop: 20 }}>
          <FormFieldBool name="allowAgeGroupOverlap" meta={ { required: true, "label": "Allow age group overlap?"} } submitCount={submitCount} />
      </div>

      <div>
        <CompetitionAgeGroupList
          name="AgeGroup"
          meta={meta}
          submitCount={submitCount}
        />
      </div>

      <div>
        <FormFieldRadioButton
          name="gender"
          meta={{ label: "Gender", options: GENDER_FORMAT_OPTIONS, required: true }}
          submitCount={submitCount}
        />
      </div>

      <FormFieldFormItem
        name="minCompetitors"
        submitCount={submitCount}
        meta={{ ...meta, label: "Minimum number of competitors" }}
        style={{ marginRight: 10 }}
      >
        <InputNumber
          style={{ width: FORM_FIELD_DATE_WIDTH }}
          min={0}
          step={1}
          formatter={value => value && toInteger(value)}
          parser={value => value && toInteger(value)}
          name="minCompetitors"
          placeholder="Enter a number"
        />
      </FormFieldFormItem>

      <FormFieldFormItem
        name="maxCompetitors"
        submitCount={submitCount}
        meta={{ ...meta, label: "Maximum number of competitors" }}
      >
        <InputNumber
          style={{ width: FORM_FIELD_DATE_WIDTH }}
          min={0}
          step={1}
          formatter={value => value && toInteger(value)}
          parser={value => value && toInteger(value)}
          name="maxCompetitors"
          placeholder="Enter a number"
        />
      </FormFieldFormItem>
    </>
  );
}

FormFieldCompetitorsFilter.propTypes = {
  submitCount: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
};

export default FormFieldCompetitorsFilter;
