import React, { useState } from "react";
import { Button, Icon } from "antd";
import { get } from "lodash";
import { REG_SUBMISSION_STATUSES } from "../../../constants/regStatusConstants";
import { useRegContext } from "../../../utils/useRegContext";
import { preparedStepsWithoutSubmissionsStatusStep } from "./PersonDetailRegistrationsConstants";

/**
 * Custom 'Alert' box containing Edit button that reverts a User's RegSubmission. User's can see this themselves.
 * @returns JSX Component with an Edit Registration Button.
 */
function PersonRegistrationRevertAlert() {
  const [loading, setLoading] = useState(false);
  const regContext = useRegContext();

  const revertRegSubmissionStatus = async () => {
    setLoading(true);
    const { updateRegSubmissionStatus, steps, RegSubmission } = get(regContext, "regContextData", {});
    const stepIds = preparedStepsWithoutSubmissionsStatusStep(steps).map(step => get(step, "id"));

    const status = REG_SUBMISSION_STATUSES.reverted;

    await updateRegSubmissionStatus({
      variables: { RegSubmissionId: get(RegSubmission, "id", null), status, stepIds },
    });
  };

  const renderButton = () => {
    if (loading) {
      return "Unlocking...";
    }
    return (
      <>
        <Icon type="edit" /> Edit Registration
      </>
    );
  };

  return (
    <>
      <div className="sidebar-alert-yellow">
        <h4>Need to update your registration?</h4>

        <Button onClick={() => revertRegSubmissionStatus()} loading={loading}>
          {renderButton()}
        </Button>
      </div>
    </>
  );
}

export default PersonRegistrationRevertAlert;
