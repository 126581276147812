import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Typography, Table, Row } from "antd";
import { Col } from "antd/es/grid";
import { isEmpty } from "lodash";
import uuidv4 from "uuid/v4";

const invoiceTableColumns = [
  {
    title: "Items",
    dataIndex: "Description",
  },
  {
    title: "Quantity",
    dataIndex: "Quantity",
    align: "center",
  },
  {
    title: "Rate",
    dataIndex: "RateStr",
    align: "right",
  },
  {
    title: "Tax",
    dataIndex: "TaxStr",
    align: "right",
  },
  {
    title: "Discount",
    dataIndex: "DiscountAmountStr",
    align: "right",
  },
  {
    title: "Amount",
    dataIndex: "AmountStr",
    align: "right",
  },
];

function getLocalDateTimeString(dt) {
  try {
    const utctime = new Date(`${dt}Z`);
    return utctime.toLocaleDateString();
  } catch (error) {
    console.error("Issue converting timestamp into locale string. Error below.");
    console.error(error);
    return dt;
  }
}

function RegistrationSubmissionInvoiceMenuItem({ invoice }) {
  const [showInvoice, setShowInvoice] = useState(false);

  return (
    <>
      <div className="sidebar-alert">
        <h3>Invoice #{invoice.InvoiceNumber}</h3>
        <Button onClick={() => setShowInvoice(true)}>View Invoice Details</Button>
      </div>

      <Modal
        title={`Invoice #${invoice.InvoiceNumber}`}
        visible={showInvoice}
        okText="Dismiss"
        onOk={() => setShowInvoice(false)}
        cancelButtonProps={{ disabled: true }}
        onCancel={() => setShowInvoice(false)}
        width={600}
      >
        <Row>
          <Col span={12}>
            <div style={{ display: "inline-block", maxWidth: "400px" }}>
              {isEmpty(invoice.Logo) ? (
                <div style={{ height: "auto", width: "250px", maxWidth: "100%", verticalAlign: "middle" }}>&nbsp;</div>
              ) : (
                <img
                  src={invoice.Logo}
                  alt="Invoice_Logo"
                  style={{ height: "auto", width: "250px", maxWidth: "100%", verticalAlign: "middle" }}
                />
              )}
            </div>
          </Col>
          <Col span={12}>
            <Typography.Title style={{ textAlign: "center", verticalAlign: "middle" }}>
              {invoice.InvoiceLabel ?? "Invoice"}
            </Typography.Title>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col span={10}>
            <p>Invoice To: </p>
            <p>{invoice.CustomerName}</p>
            <p>{invoice.CustomerEmail}</p>
            <p>{invoice.CustomerPhone}</p>
          </Col>
          <Col span={6}>
            <span style={{ textAlign: "right" }}>
              <p>Invoice No:</p>
              <p>Purchase Date:</p>
              <p>Amount Due:</p>
            </span>
          </Col>
          <Col span={8} style={{ paddingLeft: "1em" }}>
            <span style={{ textAlign: "left" }}>
              <p>{invoice.InvoiceNumber}</p>
              <p>{getLocalDateTimeString(invoice.InvoiceDateStr)}</p>
              <p>{invoice.AmountDueStr}</p>
            </span>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col span={24}>
            <Table
              rowKey={() => uuidv4()}
              size="small"
              bordered={false}
              pagination={false}
              columns={invoiceTableColumns}
              dataSource={invoice.LineItems}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={16}>
            <div style={{ textAlign: "right", marginTop: "1em" }}>
              <p>Subtotal:</p>
              <p>Tax:</p>
              <p>Total:</p>
              <p>Amount Due:</p>
            </div>
          </Col>
          <Col span={4}>
            <div style={{ textAlign: "right", paddingRight: "1em", marginTop: "1em" }}>
              <p>{invoice.SubTotalStr}</p>
              <p>{invoice.TaxTotalStr}</p>
              <p>{invoice.InvoiceTotalStr}</p>
              <p>{invoice.AmountDueStr}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>
              Will appear on statement as <span style={{ fontStyle: "italic" }}>{invoice.CreditCardStatement}</span>.
            </p>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

RegistrationSubmissionInvoiceMenuItem.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default RegistrationSubmissionInvoiceMenuItem;
