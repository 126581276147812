import gql from "fraql";

export const IMAGE_TYPES = ["jpg", "png"];
export const FILE_TYPES = [...IMAGE_TYPES, "pdf", "doc", "xls", "csv"];

export const FILE_TYPE_EXTENSION_MAP = {
  jpg: [".jpg", ".jpeg"],
  png: [".png"],
  pdf: [".pdf"],
  doc: [".doc", ".docx"],
  xls: [".xls", ".xlsx"],
  csv: [".csv"],
};

// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types.
export const FILE_TYPE_MIME_TYPE_MAP = {
  jpg: ["image/jpeg"],
  png: ["image/png"],
  pdf: ["application/pdf"],
  doc: ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
  xls: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  csv: ["text/csv"],
};

// Needed for File Uploading.
export const GET_USER_PERSONID = gql`
  query Get_User_PersonId($id: String!) {
    User(where: { auth0Id: { _eq: $id } }) {
      personId
    }
  }
`;
