import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Tooltip } from "antd";
import gql from "fraql";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { PEOPLE } from "../../../../constants/routeConstants";
import ColumnValueGender from "../../../ColumnValueGender";
import ColumnValueText from "../../../ColumnValueText";
import EmptyValueText from "../../../EmptyValueText";
import SpinPageContent from "../../../SpinPageContent";
import TableWithTruncation from "../../../TableWithTruncation/TableWithTruncation";
import PersonName, { getFullName } from "../../PersonName";
import TeamConditions from "./TeamConditions";

const PENDING_COLUMNS = [
  {
    key: "name",
    title: "Name",
    render: (text, record) => {
      return (
        <Tooltip
          placement="topLeft"
          trigger="hover"
          title={getFullName(record.Person.firstName, record.Person.lastName)}
        >
          <ColumnValueText>
            <PersonName firstName={record.Person.firstName} lastName={record.Person.lastName} />
          </ColumnValueText>
        </Tooltip>
      );
    },
    minWidth: 190,
  },
  {
    title: "Gender",
    key: "gender",
    render: (text, record) => {
      return <ColumnValueGender gender={record.Person.gender} />;
    },
    width: 170,
  },
  {
    key: "role",
    title: "Role",
    render: (text, record) => {
      const roleName = get(record, "Role.name", "");

      return (
        <Tooltip placement="topLeft" trigger="hover" title={roleName}>
          <ColumnValueText>{roleName}</ColumnValueText>
        </Tooltip>
      );
    },
    width: 190,
  },
  {
    title: "Alternate",
    key: "alternate",
    render: (text, record) => {
      const alternate = get(record, "alternate", null);

      if (isNil(alternate)) {
        return <EmptyValueText />;
      }

      return <ColumnValueText>{alternate ? "Yes" : "No"}</ColumnValueText>;
    },
    width: 190,
  },
];

export const GET_TEAM = gql`
  query ViewTeamDrawerPendingTab_GetTeam($teamId: String!) {
    Team_by_pk(id: $teamId) {
      id
      TeamPeople {
        id
        PersonId
      }
    }
  }
`;

const GET_PENDING_TEAM_MEMBERS = gql`
  query Comp_GetPendingTeamMembers($teamId: ID!, $regId: ID!) { 
    getPendingTeamMembers(teamId: $teamId, regId: $regId)
    {
        result
    }
}
`;

function ViewTeamDrawerPendingTab({ teamId, regId, readOnly }) {
  const history = useHistory();

  const { loading: teamLoading, error: teamError, data: teamData } = useQuery(GET_TEAM, {
    variables: { teamId },
    skip: isNil(teamId),
  });

  const {
    loading: pendingTeamMembersLoading,
    error: pendingTeamMembersError,
    data: pendingTeamMembersData,
  } = useQuery(GET_PENDING_TEAM_MEMBERS, {
    variables: { regId, teamId },
    skip: isNil(regId) || isNil(teamId),
  });

  const pendingDataSource = useMemo(() => {
    if (isNil(pendingTeamMembersData)){
      return null;
    }

    return pendingTeamMembersData.getPendingTeamMembers.result;
  }, [pendingTeamMembersData]);

  const onRow = useCallback(
    record => ({
      onClick: () => {
        if (!readOnly){
          history.push(`${PEOPLE}/${record.Person.id}/registrations`);
        }
      },
    }),
    [history, readOnly],
  );

  if (
    (teamLoading && !teamData) ||
    (pendingTeamMembersLoading && !pendingTeamMembersData) 
  ) {
    return <SpinPageContent />;
  }

  if (teamError || pendingTeamMembersError) {
    return (
      <div style={{ padding: 24 }}>
        <Alert
          message="Team failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <div>
      <TeamConditions teamId={teamId} regId={regId} />

      <TableWithTruncation
        columns={PENDING_COLUMNS}
        includeIndexColumn
        dataSource={pendingDataSource}
        loading={pendingTeamMembersLoading}
        onRow={onRow}
        rowKey="id"
        rowClassName="cursor-pointer"
        size="small"
        pagination={false}
        bordered={false}
        scroll={{ y: 54 * 10 }}
        locale={{
          emptyText: "No pending team members",
        }}
      />
    </div>
  );
}

ViewTeamDrawerPendingTab.propTypes = {
  teamId: PropTypes.string,
  regId: PropTypes.string,
  readOnly: PropTypes.bool,
};

ViewTeamDrawerPendingTab.defaultProps = {
  teamId: undefined,
  regId: undefined,
  readOnly: false,
};

export default ViewTeamDrawerPendingTab;
