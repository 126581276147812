import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Typography } from "antd";
import gql from "fraql";
import each from "lodash/each";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import uniq from "lodash/uniq";
import { REG_SUBMISSION_STATUSES } from "../../../../constants/regStatusConstants";
import { STEP_TYPE } from "../../../../constants/stepsConstants";
import isBlank from "../../../../utils/isBlank";
import SectionHeaderContainer from "../../../SectionHeaderContainer";
import SpinPageContent from "../../../SpinPageContent";
import PersonRegistrationCompetitionDetails from "./PersonRegistrationCompetitionDetails";

const GET_REG_SUBMISSION = gql`
  query PersonRegistrationCompetitions_GetPersonRegSubmissions($RegSubmissionId: String!) {
    RegSubmission_by_pk(id: $RegSubmissionId) {
      id
      status

      Reg {
        id
        name
      }
    }
  }
`;

const GET_REG_STEP_VALUES_FOR_REG_SUBMISSION = gql`
  query PersonRegistrationCompetitions_GetRegStepValuesForRegSubmission($PersonId: String!, $RegId: String!) {
    RegStepValue(
      where: { Step: {meta: {_contains: {type: "${STEP_TYPE.COMPETITIONS}"}}}, PersonId: { _eq: $PersonId }, RegId: { _eq: $RegId } }
    ) {
      id
      value
    }
  }
`;

const GET_TEAM_INFORMATION_FOR_COMPETITION = gql`
  query PersonRegistrationCompetitions_GetTeamInformationForCompetition($TeamIds: [String!]!, $PersonId: String!) {
    Team(
      where: { id: { _in: $TeamIds } }
      order_by: [{ Competition: { SportMeet: { name: asc } } }, { Competition: { name: asc } }]
    ) {
      id
      name
      qualificationScore

      Competition {
        id
      }

      PersonInTeam: TeamPeople(where: { PersonId: { _eq: $PersonId } }) {
        id
        position
      }
    }
  }
`;

// This query is performed only to have the competitions ordered by hasura
// by sport meet names asc, and competition name asc.
const GET_COMPETITIONS = gql`
  query PersonRegistrationCompetitions_GetCompetitions($CompetitionIds: [String!]!) {
    Competition(
      where: { id: { _in: $CompetitionIds } }
      order_by: { SportMeet: { name: asc }, name: asc }
    ) {
      id
    }
  }
`;

function PersonRegistrationCompetitions({ personId, regSubmissionId, readOnly }) {
  const { loading: regSubmissionLoading, error: regSubmissionError, data: regSubmissionData } = useQuery(
    GET_REG_SUBMISSION,
    {
      variables: { RegSubmissionId: regSubmissionId },
      skip: isNil(regSubmissionId),
    },
  );

  const regId = useMemo(() => {
    return get(regSubmissionData, "RegSubmission_by_pk.Reg.id");
  }, [regSubmissionData]);

  const { loading: regStepValuesLoading, error: regStepValuesError, data: regStepValuesData } = useQuery(
    GET_REG_STEP_VALUES_FOR_REG_SUBMISSION,
    {
      variables: { PersonId: personId, RegId: regId },
      skip: isNil(regId),
    },
  );

  const mapOfSelectedTeamsByCompetitionIds = useMemo(() => {
    const regStepValues = get(regStepValuesData, "RegStepValue", []);
    const mapValues = [];

    each(regStepValues, regStepValue => {
      const competitions = get(regStepValue, "value.competitions", []);

      each(competitions, competition => {
        const teamId = get(competition, "team");
        const ageGroup = get(competition, "ageGroup");

        const mapTeamToCompetition = {
          competitionId: competition.id,
          isTeam: !isBlank(teamId),
          teamId,
          ageGroup,
        };
        mapValues.push(mapTeamToCompetition);
      });
    });
    return mapValues;
  }, [regStepValuesData]);

  const selectedTeamIds = useMemo(() => {
    return uniq(map(mapOfSelectedTeamsByCompetitionIds, "teamId"));
  }, [mapOfSelectedTeamsByCompetitionIds]);

  const selectedCompetitionIds = useMemo(() => {
    return uniq(map(mapOfSelectedTeamsByCompetitionIds, "competitionId"));
  }, [mapOfSelectedTeamsByCompetitionIds]);

  const { loading: competitionsLoading, error: competitionsError, data: competitionsData } = useQuery(
    GET_COMPETITIONS,
    {
      variables: { CompetitionIds: selectedCompetitionIds },
      skip: isEmpty(selectedCompetitionIds),
    },
  );

  const { loading: teamsLoading, error: teamsError, data: teamsData } = useQuery(GET_TEAM_INFORMATION_FOR_COMPETITION, {
    variables: { TeamIds: selectedTeamIds, PersonId: personId },
    skip: isEmpty(selectedTeamIds),
  });

  const regSubmissionCompleted = useMemo(() => {
    const status = get(regSubmissionData, "RegSubmission_by_pk.status");
    return [REG_SUBMISSION_STATUSES.submitted, REG_SUBMISSION_STATUSES.approved].includes(status);
  }, [regSubmissionData]);

  const teams = get(teamsData, "Team", []);
  const hasTeams = !!selectedTeamIds.length;
  const competitions = get(competitionsData, "Competition", []);

  if (
    (regSubmissionLoading && !regSubmissionData) ||
    (regStepValuesLoading && !regStepValuesData) ||
    (teamsLoading && !teamsData) ||
    (competitionsLoading && !competitionsData)
  ) {
    return <SpinPageContent />;
  }

  if (regSubmissionError || regStepValuesError || teamsError || competitionsError) {
    return (
      <div style={{ padding: 24 }}>
        <Alert
          message="Competitions failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <SectionHeaderContainer>
        <Typography.Title level={3}>Competitions</Typography.Title>
      </SectionHeaderContainer>

      {!regSubmissionId && (
        <Alert message="Select a registration to view competitions." type="info" className="form-field-alert" />
      )}

      {regSubmissionId && hasTeams && !regSubmissionCompleted && (
        <Alert
          type="info"
          message="Registration not yet submitted"
          description="This person's registration has not yet been submitted, so they have not yet joined any of the competitions which have been selected in their registration submission."
          showIcon
          style={{ marginBottom: 24 }}
        />
      )}

      {regSubmissionId && !hasTeams && (
        <Alert
          type="info"
          message="No teams selected"
          description="No competitions have been selected in this person's registration submission."
          showIcon
          style={{ marginBottom: 24 }}
        />
      )}

      {competitions.map((competition, index) => {
        const mappedTeamToCompetition = find(mapOfSelectedTeamsByCompetitionIds, { competitionId: competition.id });

        if (isUndefined(mappedTeamToCompetition)) {
          return null;
        }

        const ageGroupId = mappedTeamToCompetition.ageGroup;

        const teamSelected = find(teams, { id: mappedTeamToCompetition.teamId });

        return (
          <PersonRegistrationCompetitionDetails
            competitionId={competition.id}
            ageGroupId={ageGroupId}
            team={teamSelected}
            index={index}
            regId={regId}
            key={competition.id}
            readOnly={readOnly}
          />
        );
      })}
    </>
  );
}

PersonRegistrationCompetitions.propTypes = {
  personId: PropTypes.string.isRequired,
  regSubmissionId: PropTypes.string,
  readOnly: PropTypes.bool,
};

PersonRegistrationCompetitions.defaultProps = {
  regSubmissionId: null,
  readOnly: false,
};

export default PersonRegistrationCompetitions;
