import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import { connect } from "formik";
import { InputNumber } from "formik-antd";
import { cloneDeep, each } from "lodash";
import toInteger from "lodash/toInteger";
import FormFieldFormItem from "../../FormFields/FormFieldFormItem";

function CompetitionAgeGroup(props) {
  const {
    index,
    submitCount,
    meta,
    formik,
    ageGroup
  } = props;

  const handleClickRemoveAgeGroup = () => {
    const existingValues = cloneDeep(formik.values.ageGroups);

    const newValues = [];

    each (existingValues, function(val){
      if (val.id !== ageGroup.id){
        newValues.push(val);
      }
    });

    formik.setFieldValue("ageGroups", newValues);
  }

  return (
    <div style={{ padding: 12, borderBottom: "1px solid #d9d9d9", width: "100%" }}>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flexGrow: 1, display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%", marginRight: 12 }}>
                <FormFieldFormItem
                  name={`ageGroups.${index}.lower_limit`}
                  style={{marginBottom: 0}}
                  displayDefaultLabel={false}
                  submitCount={submitCount}
                  meta={meta}
                >
                  <InputNumber
                    name={`ageGroups.${index}.lower_limit`}
                    placeholder= "Minimum age"
                    submitCount={submitCount}
                    min={0}
                    step={1}
                    formatter={value => value && toInteger(value)}
                    parser={value => value && toInteger(value)}
                  />
                </FormFieldFormItem>
              </div>

              <div style={{ width: "50%" }}>
                <FormFieldFormItem
                  name={`ageGroups.${index}.upper_limit`}
                  style={{marginBottom: 0}}
                  displayDefaultLabel={false}
                  submitCount={submitCount}
                  meta={meta}
                >
                  <InputNumber
                    name={`ageGroups.${index}.upper_limit`}
                    placeholder= "Maximum age (leave blank for no limit)"
                    submitCount={submitCount}
                    min={0}
                    step={1}
                    formatter={value => value && toInteger(value)}
                    parser={value => value && toInteger(value)}
                  />
                </FormFieldFormItem>
              </div>
            </div>
          </div>

          <Icon
            type="close"
            style={{ fontSize: 18, marginLeft: 5, cursor: "pointer", height: 32, padding: "7px 0 7px 7px" }}
            onClick={handleClickRemoveAgeGroup}
          />
        </div>
      </div>
    </div>
  );
}

CompetitionAgeGroup.propTypes = {
  index: PropTypes.number.isRequired,
  submitCount: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  ageGroup: PropTypes.object.isRequired,
};

export default connect(CompetitionAgeGroup);
