import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Alert, Typography } from "antd";
import classnames from "classnames";
import { connect, FieldArray } from "formik";
import { Checkbox, Input, Radio } from "formik-antd";
import { concat } from "lodash";
import each from "lodash/each";
import get from "lodash/get";
import includes from "lodash/includes";
import { COMPETITION_SCORE_TYPE } from "../../../../../constants/competitionConstants";
import { formFieldHasError } from "../../../../../utils/formikUtils";
import { useRegContext } from "../../../../../utils/useRegContext";
import FormFieldCompetitionScore from "../../../../FormFields/FormFieldCompetitionScore";
import FormFieldFormItem from "../../../../FormFields/FormFieldFormItem";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../../../../StatusText";
import RegistrationStepCompetitionsFormFieldTeam from "./RegistrationStepCompetitionsFormFieldTeam";

function RegistrationStepCompetitionsFormFieldCompetitions(props) {
  const { name, disabled, formik, competitionsData, readOnly, showIneligibleCompetitions } = props;

  const { values, errors, touched, submitCount } = formik;

  const isSubmitted = !!submitCount;

  const regContext = useRegContext();
  const lockedCompetitions = get(regContext, "regContextData.lockedFields.locked_competitions", []);

  const competitionsValue = useMemo(() => {
    const result = get(values, name, undefined);

    return result;
  }, [values, name]);

  const competitionObjects = useMemo(() => {
    const competitions = get(competitionsData, "Competition", []);

    const competitionObjectsValue = {};

    each(competitions, competition => {
      competitionObjectsValue[competition.id] = competition;
    });

    return competitionObjectsValue;
  }, [competitionsData]);

  return (
    <FormFieldFormItem
      name="competitions"
      meta={{}}
      submitCount={submitCount}
      displayDefaultLabel={false}
      displayForInput={false}
    >
      <div className="registration-step-competitions-form-field-competitions">
        <FieldArray
          name={name}
          render={() => {
            return competitionsValue.map((competitionValue, competitionValueIndex) => {
              if (
                (readOnly && !competitionValue.selected) ||
                (!showIneligibleCompetitions && !competitionValue.isEligible && !competitionValue.selected)
              ) {
                return null;
              }
              // Note: every field which can be shown for a competition (including hidden fields) should be included in
              // this list, so that if there is a validation error for any of these fields,
              // `anyCompetitionFieldHasError` will have the correct value.
              const competitionFields = [
                "selected",
                "isEligible",
                "team",
                "teamPosition",
                "qualificationScoreValue",
                "qualificationScoreType",
                "qualificationScoreInputDisplayed",
                "qualificationScoreRequired",
                "qualificationScoreLabel",
                "qualificationScoreLimitMin",
                "qualificationScoreLimitMax",
                "teamSelectionRequired",
                "teamPositionRequired",
                "teamPositions",
                "ageGroup",
              ];

              const competitionItemIsTouched = !!get(touched, [name, competitionValueIndex], false);

              let anyCompetitionFieldHasError = false;

              // eslint-disable-next-line consistent-return
              each(competitionFields, field => {
                const fieldHasError = formFieldHasError([name, competitionValueIndex, field], errors);

                if (fieldHasError) {
                  anyCompetitionFieldHasError = true;

                  // Exit iteration early.
                  return false;
                }
              });

              const competitionItemHasError = anyCompetitionFieldHasError && (competitionItemIsTouched || isSubmitted);

              const competitionId = competitionValue.id;

              const competitionName = get(competitionObjects, [competitionId, "name"], "") || "[Competition]";

              const ageGroups = get(competitionObjects, [competitionId, "AgeGroups"], null);
              const eligibleAgeGroups = ageGroups.filter(x => x.isEligible);
              const showAgeGroups = eligibleAgeGroups && eligibleAgeGroups.length > 0;

              const registrantsCanCreateTeams = get(
                competitionObjects,
                [competitionId, "registrantsCanCreateTeams"],
                false,
              );

              const registrantsCanCreateTeamsRoles = get(
                competitionObjects,
                [competitionId, "CompetitionCreateTeamRoles"],
                [],
              );

              const managersCanCreateTeams = get(competitionObjects, [competitionId, "managersCanCreateTeams"], false);
              const teamSelectionRequired = get(competitionObjects, [competitionId, "teamSelectionRequired"], false);
              const qualificationScoreRequired = get(
                competitionObjects,
                [competitionId, "qualificationScoreRequired"],
                false,
              );
              const qualificationScoreLabel =
                get(competitionObjects, [competitionId, "qualificationScoreLabel"], "") || "Qualification score";
              const competitionQualificationScoreType = get(
                competitionObjects,
                [competitionId, "qualificationScoreType"],
                null,
              );

              const isTeamCompetition = get(competitionObjects, [competitionId, "teamCompetition"], false);
              const teamPositionRequired = get(competitionObjects, [competitionId, "teamPositionRequired"], false);

              let qualificationScoreValueHelpText = "";

              if (competitionQualificationScoreType === COMPETITION_SCORE_TYPE.ELAPSED_TIME) {
                qualificationScoreValueHelpText = "Enter a value in the format hh:mm:ss.SS (e.g. 11:55:24.81)";
              } else if (competitionQualificationScoreType === COMPETITION_SCORE_TYPE.NUMBER_INTEGER) {
                qualificationScoreValueHelpText = "Enter a number without decimal places (e.g. 100)";
              } else if (
                includes(
                  [
                    COMPETITION_SCORE_TYPE.LENGTH_METRES,
                    COMPETITION_SCORE_TYPE.MASS_KILOGRAMS,
                    COMPETITION_SCORE_TYPE.NUMBER,
                  ],
                  competitionQualificationScoreType,
                )
              ) {
                qualificationScoreValueHelpText =
                  "Enter a number, optionally with up to 2 decimal places (e.g. 100 or 100.99)";
              }

              const className = classnames("competition-option", {
                "competition-option--error": competitionItemHasError,
              });

              const renderAgeGroupLabel = ag => {
                let result = ag.lower_limit;

                if (ag.upper_limit) {
                  result = concat(result, " - ", ag.upper_limit);
                } else {
                  result = concat(result, "+");
                }

                return result;
              };

              const isDisabled = disabled || lockedCompetitions.includes(competitionValue.id);

              return (
                <div key={competitionValue.id} className={className}>
                  {readOnly ? (
                    <>
                      <Typography.Title className="competition-name--read-only" level={4}>
                        {competitionName}
                      </Typography.Title>

                      {!competitionValue.isEligible && (
                        <StatusText status={STATUS_TEXT_STATUS_OPTIONS.WARNING}>[Ineligible]</StatusText>
                      )}
                    </>
                  ) : (
                    <>
                      {competitionValue.selected && !competitionValue.isEligible && (
                        <div style={{ paddingBottom: "22px" }}>
                          <Alert
                            type="error"
                            message="Please unselect this competition. Competition eligibility requirements are not met."
                          />
                        </div>
                      )}

                      <Checkbox
                        name={`${name}.${competitionValueIndex}.selected`}
                        disabled={isDisabled}
                        className="competition-option__checkbox"
                      >
                        <span style={{ color: "rgba(0, 0, 0, 0.85)" }}>{competitionName}</span>

                        {!competitionValue.isEligible && (
                          <StatusText status={STATUS_TEXT_STATUS_OPTIONS.WARNING}> [Ineligible]</StatusText>
                        )}
                      </Checkbox>
                      {showAgeGroups && values.competitions[competitionValueIndex].selected && (
                        <>
                          <div className="indent">Select Age Group</div>
                          <Radio.Group name={`${name}.${competitionValueIndex}.ageGroup`}>
                            {eligibleAgeGroups.map(ageGroup => (
                              <div key={ageGroup.id}>
                                <Radio
                                  name={`${name}.${competitionValueIndex}.ageGroup`}
                                  value={ageGroup.id}
                                  disabled={isDisabled}
                                  className="competition-option__checkbox indent"
                                >
                                  {renderAgeGroupLabel(ageGroup)}
                                </Radio>
                              </div>
                            ))}
                          </Radio.Group>
                        </>
                      )}
                    </>
                  )}

                  {competitionValue.selected && (
                    <div className="competition-option--items">
                      {isTeamCompetition && (
                        <>
                          <RegistrationStepCompetitionsFormFieldTeam
                            name={`${name}.${competitionValueIndex}`}
                            disabled={isDisabled}
                            submitCount={submitCount}
                            competitionId={competitionId}
                            registrantsCanCreateTeams={registrantsCanCreateTeams}
                            registrantsCanCreateTeamsRoles={registrantsCanCreateTeamsRoles}
                            managersCanCreateTeams={managersCanCreateTeams}
                            teamSelectionRequired={teamSelectionRequired}
                            teamPositionRequired={teamPositionRequired}
                            readOnly={readOnly}
                          />
                        </>
                      )}

                      {competitionValue.qualificationScoreInputDisplayed && (
                        <>
                          <FormFieldCompetitionScore
                            name={`${name}.${competitionValueIndex}.qualificationScoreValue`}
                            meta={{
                              label: qualificationScoreLabel,
                              helpText: qualificationScoreValueHelpText,
                              required: qualificationScoreRequired,
                              competitionScoreTypeFieldId: `${name}.${competitionValueIndex}.qualificationScoreType`,
                            }}
                            disabled={isDisabled}
                            submitCount={submitCount}
                            readOnly={readOnly}
                          />

                          <Input name={`${name}.${competitionValueIndex}.qualificationScoreType`} type="hidden" />
                        </>
                      )}
                    </div>
                  )}
                </div>
              );
            });
          }}
        />
      </div>
    </FormFieldFormItem>
  );
}

RegistrationStepCompetitionsFormFieldCompetitions.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  competitionsData: PropTypes.object,
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  showIneligibleCompetitions: PropTypes.bool.isRequired,
};

RegistrationStepCompetitionsFormFieldCompetitions.defaultProps = {
  competitionsData: null,
  readOnly: false,
};

export default connect(RegistrationStepCompetitionsFormFieldCompetitions);
