import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { message, Alert } from "antd";
import gql from "fraql";
import each from "lodash/each";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import mapFormFieldsToActionFormData from "../../utils/mapFormFieldsToActionFormData";
import mapFormValuesToDbValues from "../../utils/mapFormValuesToDbValues";
import useCurrentUser from "../../utils/useCurrentUser";
import { useRegContext } from "../../utils/useRegContext";
import ActionForm from "../ActionForm";
import AlertFailedLoading from "../AlertFailedLoading";
import SpinPageContent from "../SpinPageContent";

const GET_FORM_FIELDS_WITH_VALUES = gql`
  query FormSubmission_GetFormFieldsWithValues($FormId: String!, $ValuesWhere: Value_bool_exp) {
    FormField(where: { _and: { FormId: { _eq: $FormId }, deleted: { _eq: false } } }, order_by: { order: asc }) {
      id
      conditions
      type
      meta
      FieldId
      Values(where: $ValuesWhere) {
        id
        value
      }
    }
  }
`;

const UPSERT_VALUES = gql`
  mutation FormSubmission_UpsertValues($ValueObjects: [Value_insert_input!]!, $upsertConstraint: Value_constraint!) {
    insert_Value(
      objects: $ValueObjects
      on_conflict: {
        constraint: $upsertConstraint
        update_columns: [FormFieldId, GroupId, PersonId, EntrantId, value, updated_by]
      }
    ) {
      affected_rows
      returning {
        id
        value
        FormFieldId
        GroupId
        PersonId
        EntrantId
      }
    }
  }
`;

/*
 * Props:
 * - relatedRecordsData - specifies which records will be related to the Value records that are saved when this Form
 *   is submitted.
 * - upsertConstraint - the DB constraint to use, which needs to be the appropriate one based on what related records
 *   are being passed in `relatedRecordsData`. Note that the Value table has different DB constraints for different
 *   combinations of fields used as related records.
 */
function FormSubmission({
  FormId,
  relatedRecordsData,
  upsertConstraint,
  handleSubmitSuccess,
  handleSubmitDraftSuccess,
  disabled,
  formLayout,
  FormBottomComponent,
  showFormBottomComponent,
  readOnly,
  persistId,
  saveContext,
  regTenantId,
}) {
  const currentUser = useCurrentUser();

  // regContext used here to determine if fields are to be locked during a Registration Submission.
  const regContext = useRegContext();
  const lockedFormFields = get(regContext, "regContextData.lockedFields.locked_custom_fields", []);

  const ValuesWhere = useMemo(() => {
    const where = {};

    each(relatedRecordsData, (value, key) => {
      where[key] = { _eq: value };
    });

    return where;
  }, [relatedRecordsData]);

  const {
    loading: formFieldsLoading,
    data: formFieldsData,
    error: formFieldsError,
    refetch: refetchFormFields,
  } = useQuery(GET_FORM_FIELDS_WITH_VALUES, {
    variables: { FormId, ValuesWhere },
    fetchPolicy: "network-only",
  });

  const [upsertValues] = useMutation(UPSERT_VALUES);

  const { fields, initialValues } = useMemo(() => {
    const formFields = get(formFieldsData, "FormField", []);

    return mapFormFieldsToActionFormData(formFields);
  }, [formFieldsData]);

  const getValueObjectsFromFormValues = useCallback(
    values => {
      if (!currentUser) {
        return null;
      }

      const formFields = get(formFieldsData, "FormField", []);

      return mapFormValuesToDbValues(formFields, values, currentUser.personId, relatedRecordsData);
    },
    [formFieldsData, currentUser, relatedRecordsData],
  );

  const checkCanSubmit = useCallback(() => {
    if (!currentUser || !currentUser.personId) {
      console.error("Failed to get current user information.");
      throw new Error("Sorry, something went wrong submitting this form. Please try again later.");
    }
  }, [currentUser]);

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        checkCanSubmit();

        await upsertValues({ variables: { ValueObjects: getValueObjectsFromFormValues(values), upsertConstraint } });

        // Refetch form fields so that the value passed as the `initialValues` prop to ActionForm will be re-calculated
        // and the form's `isInitialValid` will be correctly re-calculated as a result.
        await refetchFormFields();

        message.success("Form saved.");

        await handleSubmitSuccess();

        actions.setSubmitting(false);

        // Reset form so that it appears as though it had just been loaded. This would mean for example that if
        // `FormValidationMessage` was currently rendering and displaying a message about errors with some fields, it
        // would stop rendering (since `submitCount` would be reset, and currently `FormValidationMessage` only renders
        // errors when the user has attempted to submit the form at least once).
        actions.resetForm(values);
      } catch (submitError) {
        console.error(submitError);

        actions.setSubmitting(false);
        actions.setStatus({ type: "error" });

        message.error("Failed to save form.");

        Sentry.captureException(submitError);
      }
    },
    [
      checkCanSubmit,
      upsertValues,
      getValueObjectsFromFormValues,
      upsertConstraint,
      refetchFormFields,
      handleSubmitSuccess,
    ],
  );

  const handleSubmitDraft = useCallback(
    async (values, actions) => {
      try {
        checkCanSubmit();

        actions.setSubmitting(true);

        await upsertValues({ variables: { ValueObjects: getValueObjectsFromFormValues(values), upsertConstraint } });

        await refetchFormFields();

        message.success("Draft saved.");

        await handleSubmitDraftSuccess();

        actions.setSubmitting(false);
        actions.resetForm(values);
      } catch (submitError) {
        console.error(submitError);

        actions.setSubmitting(false);
        actions.setStatus({ type: "error" });

        message.error("Failed to save draft.");

        Sentry.captureException(submitError);
      }
    },
    [
      checkCanSubmit,
      upsertValues,
      getValueObjectsFromFormValues,
      upsertConstraint,
      refetchFormFields,
      handleSubmitDraftSuccess,
    ],
  );

  if (!currentUser || (formFieldsLoading && !formFieldsData)) {
    return <SpinPageContent />;
  }

  if (formFieldsError) {
    return <AlertFailedLoading message="Form fields failed to load" />;
  }

  if (isEmpty(fields)) {
    return <Alert message="No fields" description="There are no fields for this form." type="warning" showIcon />;
  }

  return (
    <ActionForm
      fields={fields}
      initialValues={initialValues}
      lockedValues={lockedFormFields}
      handleSubmit={handleSubmit}
      handleSubmitDraft={handleSubmitDraft}
      submitText="Save"
      disabled={disabled}
      formLayout={formLayout}
      relatedRecordsData={relatedRecordsData}
      showFormFieldContentAsPreview
      useFormValidityForSubmitDisabled
      FormBottomComponent={FormBottomComponent}
      showFormBottomComponent={showFormBottomComponent}
      readOnly={readOnly}
      persistId={persistId}
      saveContext={saveContext}
      regTenantId={regTenantId}
    />
  );
}

FormSubmission.propTypes = {
  FormId: PropTypes.string.isRequired,
  relatedRecordsData: PropTypes.object.isRequired,
  upsertConstraint: PropTypes.string.isRequired,
  handleSubmitSuccess: PropTypes.func.isRequired,
  handleSubmitDraftSuccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  formLayout: PropTypes.string,
  showFormBottomComponent: PropTypes.bool,
  readOnly: PropTypes.bool,
  FormBottomComponent: PropTypes.func,
  persistId: PropTypes.string,
  saveContext: PropTypes.bool,
  regTenantId: PropTypes.string,
};

FormSubmission.defaultProps = {
  disabled: false,
  formLayout: "horizontal",
  showFormBottomComponent: true,
  readOnly: false,
  FormBottomComponent: null,
  persistId: "",
  saveContext: false,
  regTenantId: null,
};

export default FormSubmission;
