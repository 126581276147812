import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { connect } from "formik";
import { cloneDeep } from "lodash";
import uuidv4 from "uuid/v4";
import CompetitionAgeGroup from "./CompetitionAgeGroup";
import "./CompetitionAgeGroupList.scss";

function CompetitionAgeGroupList(props) {
  const { 
    formik, 
    submitCount, 
    meta 
  } = props;

  const handleAddAgeGroup = () => {
    const existingValues = cloneDeep(formik.values.ageGroups);

    existingValues.push({"id": uuidv4(), "lower_limit": 0, "upper_limit": null});

    formik.setFieldValue("ageGroups", existingValues);
  }

  const errorMessages = () => {
    const { ageGroups } = formik.errors;

    if (ageGroups){
      return ageGroups
    }
    
    return "";
  }

  return (
    <div style={{marginBottom: 25}}>
      <div className="ant-col ant-form-item-label">
        <label title="Age Groups">Age groups</label>
      </div>

      <div>
        <div className="competition-age-group-list__container">
            {formik.values && formik.values.ageGroups &&
                formik.values.ageGroups.map((ageGroup, index) => (
                  <CompetitionAgeGroup index={index} submitCount={submitCount} ageGroup={ageGroup} key={ageGroup.id} meta={meta} />
                ))
            }
          <div className="competition-age-group-list__add-button-container">
            <Button icon="plus" onClick={handleAddAgeGroup}>
              Add Age Group
            </Button>
          </div>
        </div>
        <div className="form-item-error">{errorMessages()}</div>
        <div className="ant-form-explain">Add an age group for this competition.</div>
      </div>
    </div>
  );
}

CompetitionAgeGroupList.propTypes = {
  submitCount: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
};

export default connect(CompetitionAgeGroupList);
