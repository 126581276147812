import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Col, Icon, Row } from "antd";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { PROFILE } from "../../constants/routeConstants";
import { PERSON_FETCH } from "../../schemas/personGql";
import AlertFailedLoading from "../AlertFailedLoading";
import AvatarUpload from "../AvatarUpload/AvatarUpload";
import LayoutPageHeader from "../LayoutPageHeader";
import LayoutTabsNavigation from "../LayoutTabsNavigation";
import SpinPageContent from "../SpinPageContent";
import PersonAge from "./PersonAge";
import PersonGender from "./PersonGender";
import PersonName from "./PersonName";
import PersonProfileActionsMenu from "./PersonProfileActionsMenu";

function renderPageHeader(tabItems, person) {
  if (tabItems) {
    return (
      <LayoutPageHeader
        title={<PersonName firstName={person.firstName} lastName={person.lastName} />}
        style={{
          // Combined with the padding-right on the title (10px), give the title the same spacing on both the left
          // and right (24px).
          marginRight: 24 - 10,
        }}
      />
    );
  }

  return (
    <Link to={PROFILE}>
      <LayoutPageHeader
        title={<PersonName firstName={person.firstName} lastName={person.lastName} />}
        style={{
          // Combined with the padding-right on the title (10px), give the title the same spacing on both the left
          // and right (24px).
          marginRight: 24 - 10,
        }}
      />
    </Link>
  );
}

function PersonProfileHeaderInfo({ PersonId, tabItems, rootStyle, avatarStyle, infoStyle, disableAvatarUpload }) {
  const { loading, error, data } = useQuery(PERSON_FETCH, {
    variables: { id: PersonId },
  });

  const person = useMemo(() => get(data, "Person_by_pk", null), [data]);

  const tabsNavigation = useMemo(() => {
    if (!tabItems) {
      return null;
    }

    return (
      <div style={{ padding: "0 24px" }}>
        <LayoutTabsNavigation items={tabItems} />
      </div>
    );
  }, [tabItems]);

  if (loading && !data) {
    return <SpinPageContent style={{ padding: "24px 24px 0 24px", height: 100 }} />;
  }

  if (error || !person) {
    return (
      <div style={{ padding: "24px 24px 0 24px" }}>
        <AlertFailedLoading message="Person failed to load" />
      </div>
    );
  }

  return (
    <Row type="flex" align="middle" style={{ borderBottom: "1px solid #e8e8e8", ...rootStyle }}>
      <Col span={1} style={{ flexShrink: 1, flexGrow: 1 }}>
        <div
          style={{ padding: "15px 24px", display: "flex", flexDirection: "row", alignItems: "center", ...infoStyle }}
        >
          {renderPageHeader(tabItems, person)}
          <strong style={{ marginRight: 24 }}>{person.id}</strong>

          <div style={{ marginRight: 24, display: "flex", alignItems: "center" }}>
            <div>
              <PersonAge dob={person.dob} showYears showUnknownExplicit />
            </div>

            <div style={{ marginLeft: 5, marginRight: 5 }}>/</div>

            <PersonGender gender={person.gender} verbose showUnknownExplicit />
          </div>

          {(!!person.email || !!person.phone) && (
            <div style={{ marginRight: 24, display: "flex", alignItems: "center" }}>
              {!!person.email && (
                <a href={`mailto:${person.email}`} style={{ color: "#4A4A4A" }}>
                  <Icon type="mail" style={{ fontSize: "18px", marginTop: 2 }} />
                </a>
              )}

              {!!person.phone && (
                <a href={`sms://${person.phone}`} style={{ color: "#4A4A4A", marginLeft: 12 }}>
                  <Icon type="message" style={{ fontSize: "18px", marginTop: 2 }} />
                </a>
              )}

              {!!person.phone && (
                <a href={`tel:${person.phone}`} style={{ color: "#4A4A4A", marginLeft: 12 }}>
                  <Icon type="phone" style={{ fontSize: "18px", marginTop: 2 }} />
                </a>
              )}
            </div>
          )}

          <PersonProfileActionsMenu personId={PersonId} style={{ marginRight: 24 }} />
        </div>

        {tabsNavigation}
      </Col>

      <Col style={avatarStyle}>
        <AvatarUpload PersonId={PersonId} disableUpload={disableAvatarUpload} />
      </Col>
    </Row>
  );
}

PersonProfileHeaderInfo.propTypes = {
  PersonId: PropTypes.string.isRequired,
  tabItems: PropTypes.arrayOf(PropTypes.object),
  rootStyle: PropTypes.object,
  avatarStyle: PropTypes.object,
  infoStyle: PropTypes.object,
  disableAvatarUpload: PropTypes.bool,
};

PersonProfileHeaderInfo.defaultProps = {
  tabItems: null,
  rootStyle: {},
  avatarStyle: {},
  infoStyle: {},
  disableAvatarUpload: false,
};

export default PersonProfileHeaderInfo;
