import gql from "fraql";

export const PERSON_FETCH = gql`
  query PersonFetch($id: String!) {
    Person_by_pk(id: $id) {
      id
      firstName
      middleNames
      lastName
      dob
      gender
      email
      phone
      timezoneName
    }
  }
`;

export const GET_PERSON_DOB_GENDER = gql`
  query GetPersonDob($personId: String!) {
    Person_by_pk(id: $personId) {
      id
      dob
      gender
    }
  }
`;

export const PERSON_DETAIL_INFO = gql`
  query PersonDetailInfo($id: String!) {
    Reg_aggregate(where: { RegSubmissions: { PersonId: { _eq: $id } } }) {
      aggregate {
        count
      }
    }

    Member_aggregate(where: { PersonId: { _eq: $id } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PERSON_USER = gql`
  query Get_Person_User($PersonId: String!) {
    Person_by_pk(id: $PersonId) {
      id
      Users {
        id
        auth0Id
        email
      }
    }
  }
`;
