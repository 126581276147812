import gql from "fraql";
import each from "lodash/each";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import values from "lodash/values";
import * as ROUTES from "../../constants/routeConstants";
import isBlank from "../../utils/isBlank";
import {
  Person,
  Field,
  Role,
  Country,
  GroupType,
  Group,
  Sport,
  Format,
  ScoreType,
  Form,
  Reg,
  ResultStatus,
} from "../../schemas/globalSearchGql";

export const GLOBAL_SEARCH_OPTIONS = {
  "people": {
    gql: Person,
    type: "Person",
    groupname: "People",
    pathname: ROUTES.PEOPLE,
    fields: ["id", "firstName", "lastName", "email", "phone"],
    containMatch: ["firstName", "lastName", "email", "phone"],
    exactMatch: ["id"],
    perWordSearch: true,
    order: [{ lastName: "asc_nulls_first" }, { firstName: "asc_nulls_first" }],
  },
  "fields": {
    gql: Field,
    type: "Field",
    groupname: "Fields",
    pathname: ROUTES.FIELDS,
    fields: ["name"],
    containMatch: ["name"],
    filterByArchived: true,
    perWordSearch: false,
  },
  "roles": {
    gql: Role,
    type: "Role",
    groupname: "Roles",
    pathname: ROUTES.ROLES,
    fields: ["name"],
    containMatch: ["name"],
    filterByArchived: true,
    perWordSearch: false,
  },
  "countries": {
    gql: Country,
    type: "Country",
    groupname: "Countries",
    pathname: ROUTES.COUNTRIES,
    fields: ["name"],
    containMatch: ["name"],
  },
  "group-types": {
    gql: GroupType,
    type: "GroupType",
    groupname: "Group Types",
    pathname: ROUTES.GROUP_TYPES,
    fields: ["name"],
    containMatch: ["name"],
    perWordSearch: false,
  },
  "groups": {
    gql: Group,
    type: "Group",
    groupname: "Groups",
    pathname: ROUTES.GROUPS,
    fields: ["name"],
    containMatch: ["name"],
    perWordSearch: false,
  },
  "sports": {
    gql: Sport,
    type: "Sport",
    groupname: "Sports",
    pathname: ROUTES.SPORTS,
    fields: ["name"],
    containMatch: ["name"],
    filterByArchived: true,
    perWordSearch: false,
  },
  "result-statuses": {
    gql: ResultStatus,
    type: "ResultStatus",
    groupname: "Result Statuses",
    pathname: ROUTES.RESULT_STATUSES,
    fields: ["name"],
    containMatch: ["name"],
    filterByArchived: false,
    perWordSearch: false,
  },
  "formats": {
    gql: Format,
    type: "Format",
    groupname: "Formats",
    pathname: ROUTES.FORMATS,
    fields: ["name"],
    containMatch: ["name"],
    perWordSearch: false,
  },
  "score-types": {
    gql: ScoreType,
    type: "ScoreType",
    groupname: "Score Types",
    pathname: ROUTES.SCORE_TYPES,
    fields: ["name"],
    containMatch: ["name"],
    perWordSearch: false,
  },
  "forms": {
    gql: Form,
    type: "Form",
    groupname: "Forms",
    pathname: ROUTES.FORMS,
    fields: ["name"],
    containMatch: ["name"],
    filterByArchived: true,
    perWordSearch: false,
  },
  "registrations": {
    gql: Reg,
    type: "Reg",
    groupname: "Registrations",
    pathname: ROUTES.REGISTRATIONS,
    fields: ["name"],
    containMatch: ["name"],
    filterByArchived: true,
    perWordSearch: false,
  },
};

function getWhereGQLMask(queryObj) {
  let mask = "";
  if (!isEmpty(queryObj)) {
    const { type } = queryObj;
    mask = `$where${type}: ${type}_bool_exp`;
  } else {
    console.error("Field object is missing !");
  }

  return mask;
}

function getOrderGQLMask(queryObj) {
  let mask = "";
  if (!isEmpty(queryObj)) {
    const { type } = queryObj;
    mask = `$order${type}: [${type}_order_by!]`;
  } else {
    console.error("Field object is missing !");
  }

  return mask;
}

function whereOrGenerator(valParts, query) {
  const containMatch = get(query, "containMatch", []);
  const exactMatch = get(query, "exactMatch", []);
  const filterByArchived = get(query, "filterByArchived", false);

  const whereFilters = [];

  const or = [];

  each(valParts, valPart => {
    if (isEmpty(valPart)) {
      return;
    }

    each(containMatch, field => {
      or.push({ [field]: { _ilike: `%${valPart}%` } });
    });

    each(exactMatch, field => {
      or.push({ [field]: { _ilike: valPart } });
    });
  });

  whereFilters.push({ _or: or });

  if (filterByArchived) {
    whereFilters.push({ archived: { _eq: false } });
  }

  let where;

  if (!isEmpty(whereFilters)) {
    where = { _and: whereFilters };
  } else {
    where = null;
  }

  return where;
}

function whereGenerator(query, value) {
  let where = null;

  const splitSearchValue = get(query, "perWordSearch", true);

  let valueParts;
  if (isBlank(value)) {
    valueParts = [""];
  } else if (splitSearchValue) {
    valueParts = value.split(" ");
  } else {
    valueParts = [value];
  }

  if (!isEmpty(query)) {
    where = whereOrGenerator(valueParts, query);
  } else {
    values(GLOBAL_SEARCH_OPTIONS).forEach(arrQuery => {
      where = whereOrGenerator(valueParts, arrQuery);
    });
  }

  return where;
}

function orderGenerator(query) {
  let order = null;
  if (!isEmpty(query)) {
    if (!isEmpty(query.order)) {
      order = query.order;
    } else if (!isEmpty(query.fields)) {
      order = { [query.fields[0]]: "asc" };
    }
  }
  return order;
}

export const getWhereGQLVariables = (query, val) => {
  let queryVariable = {};
  if (!isEmpty(query)) {
    queryVariable = { ...queryVariable, [`where${query.type}`]: whereGenerator(query, val) };
  } else {
    values(GLOBAL_SEARCH_OPTIONS).forEach(searchObj => {
      queryVariable = { ...queryVariable, [`where${searchObj.type}`]: whereGenerator(searchObj, val) };
    });
  }
  return queryVariable;
};

export const getOrderGQLVariables = query => {
  let queryVariable = {};
  if (!isEmpty(query)) {
    queryVariable = { ...queryVariable, [`order${query.type}`]: orderGenerator(query) };
  } else {
    values(GLOBAL_SEARCH_OPTIONS).forEach(searchObj => {
      queryVariable = { ...queryVariable, [`order${searchObj.type}`]: orderGenerator(searchObj) };
    });
  }

  return queryVariable;
};

export const getGQLSearch = query => {
  const querySource = [];
  const queryVariables = [];
  if (!isEmpty(query)) {
    querySource.push(query.gql);
    queryVariables.push(getWhereGQLMask(query));
    queryVariables.push(getOrderGQLMask(query));
  } else {
    values(GLOBAL_SEARCH_OPTIONS).forEach(n => {
      querySource.push(n.gql);
      queryVariables.push(getWhereGQLMask(n));
      queryVariables.push(getOrderGQLMask(n));
    });
  }
  return gql`query GlobalSearch(${queryVariables.join("")}, $limit: Int!) {${querySource.join("")}}`;
};
