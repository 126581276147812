import gql from "fraql";

// These GraphQL Queries are used within the RegistrationEdit + Condition pages.

export const GET_ROLES_BY_TENANTKEY = gql`
  query RegistrationStepEdit_GetRoles($regTenantKey: String!) {
    Role(order_by: { name: asc }, where: { Tenant: { key: { _eq: $regTenantKey } } }) {
      id
      name
      archived
    }
  }
`;

export const GET_SPORTS_BY_TENANTKEY = gql`
  query RegistrationStepEdit_GetSports($regTenantKey: String!) {
    Sport(order_by: { name: asc }, where: { Tenant: { key: { _eq: $regTenantKey } } }) {
      id
      name
      archived
      Tenant {
        key
      }
    }
  }
`;

export const GET_GROUPS_BY_TENANTKEY = gql`
  query RegistrationStepEdit_GetGroups($regTenantKey: String!) {
    Group(order_by: { name: asc }, where: { Tenant: { key: { _eq: $regTenantKey } } }) {
      id
      name
      parentId
      path
      tenantId
    }
  }
`;

export const GET_FORMS_BY_TENANTKEY = gql`
  query RegistrationStepEdit_GetForms($regTenantKey: String) {
    Form(order_by: { name: asc }, where: { Group: { Tenant: { key: { _eq: $regTenantKey } } } }) {
      id
      name
      archived
    }
  }
`;
