import parseEnvironmentVariables from "../utils/envUtils";

const ENV_VARIABLES_RAW = {
  ENVIRONMENT: process.env.NODE_ENV,
  PUBLIC_URL: process.env.PUBLIC_URL,
  API_GRAPHQL_HTTP_URL: process.env.REACT_APP_API_GRAPHQL_HTTP_URL,
  API_GRAPHQL_WEBSOCKET_URL: process.env.REACT_APP_API_GRAPHQL_WEBSOCKET_URL,
  VALIDATE_PAYMENT_URL: process.env.REACT_APP_VALIDATE_PAYMENT_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE,
  GA_MEASUREMENT_ID: process.env.REACT_APP_GA_MEASUREMENT_ID,
  RECAPTCHA_PUBLIC_SITE_KEY: process.env.REACT_APP_RECAPTCHA_PUBLIC_SITE_KEY,
  PEOPLE_IMPORT_TEMPLATE: process.env.REACT_APP_PEOPLE_IMPORT_TEMPLATE,
  PEOPLE_IMPORT_ERROR_TEMPLATE: process.env.REACT_APP_PEOPLE_IMPORT_ERROR_TEMPLATE,
  // Investigate. Do we even use these anymore for JWT?
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
  // Marked for deprecation. These values are completely unused within the App.
  APOLLO_URL: process.env.REACT_APP_APOLLO_URL,
  APOLLO_WEBSOCKET_URL: process.env.REACT_APP_APOLLO_WEBSOCKET_URL,
  EXPORT_SERVICE_URL: process.env.REACT_APP_EXPORT_SERVICE_URL,
  ROOT_URL: process.env.REACT_APP_ROOT_URL,
  THEME_NAME: process.env.REACT_APP_THEME_NAME,
};

const ENV_VARIABLES = parseEnvironmentVariables(ENV_VARIABLES_RAW);

const ENV = {
  ...ENV_VARIABLES,

  IS_PRODUCTION: process.env.NODE_ENV === "production",
  IS_DEVELOPMENT: process.env.NODE_ENV === "development",
  IS_TEST: process.env.NODE_ENV === "test",
};

if (ENV.IS_DEVELOPMENT) {
  console.log(ENV);
}

export default ENV;
