import React from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import { Input } from "formik-antd";
import get from "lodash/get";
import omit from "lodash/omit";
import { FIELD_TYPES } from "../../constants/fieldConstants";
import isBlank from "../../utils/isBlank";
import FormFieldDate from "./FormFieldDate";
import FormFieldDatetime from "./FormFieldDatetime";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldReadOnlyContent from "./FormFieldReadOnlyFields/FormFieldReadOnlyContent";
import ReadOnlyText from "./FormFieldReadOnlyFields/ReadOnlyText";
import FormFieldTime from "./FormFieldTime";

// This is a blatant copy/paste of FormFieldText, just modifying the Input.Password down below.

const isMinMaxValue = name => {
  let result = false;
  const split = String(name).split(".");
  if (split.length > 1 && (split.includes("minValue") || split.includes("maxValue"))) {
    result = true;
  }
  return result;
};

/**
 * TODO: Check what `bindType` and `isDataType` are doing, see if we can refactor this.
 */
function FormFieldPassword(props) {
  const { name, meta, disabled, formik, readOnly } = props;

  const bindType = get(meta, "bindType", "");
  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const addonAfter = get(meta, "addonAfter", undefined);
  const maxLength = get(meta, "maxLength", undefined);
  const style = get(meta, "style", undefined);
  const autoComplete = get(meta, "autoComplete", true) ? "on" : "off";
  const isDataType = isMinMaxValue(name);

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Enter a value";
  }

  if (isDataType) {
    switch (bindType) {
      case FIELD_TYPES.date:
        return <FormFieldDate {...omit(props, ["formik"])} />;

      case FIELD_TYPES.time:
        return <FormFieldTime {...omit(props, ["formik"])} />;

      case FIELD_TYPES.datetime:
        return <FormFieldDatetime {...omit(props, ["formik"])} />;

      default:
        // return the render to the main return.
        break;
    }
  }

  function getReadOnlyContent() {
    const value = get(formik, `values.${name}`, null);

    if (isBlank(value)) {
      return null;
    }

    return (
      <ReadOnlyText>
        <span>{value}</span>
        {addonAfter && <span> {addonAfter}</span>}
      </ReadOnlyText>
    );
  }

  if (readOnly) {
    return <FormFieldReadOnlyContent name={name} meta={meta} content={getReadOnlyContent()} />;
  }

  return (
    <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel}>
      <Input.Password
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
        addonAfter={addonAfter}
        maxLength={maxLength}
        style={style}
      />
    </FormFieldFormItem>
  );
}

FormFieldPassword.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  formik: PropTypes.object.isRequired,
};

FormFieldPassword.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default connect(FormFieldPassword);
