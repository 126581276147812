import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { message } from "antd";
import gql from "fraql";
import differenceBy from "lodash/differenceBy";
import each from "lodash/each";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import omit from "lodash/omit";
import sortBy from "lodash/sortBy";
import { AUTH_ROLES } from "../../../constants/authConstants";
import { DEFAULT_CONFIRMATION_MSG } from "../../../constants/messageConstants";
import { POST_SUBMISSION_STATUS_STEP_ID, REG_SUBMISSION_STATUSES } from "../../../constants/regStatusConstants";
import { STEP_TYPE, STEP_TYPES_WITH_FORM } from "../../../constants/stepsConstants";
import { getAgeRequirementIsMetFromDob } from "../../../utils/ageUtils";
import { RegContext } from "../../../utils/contextUtils";
import { isRegistrationClosed } from "../../../utils/registrationUtil";
import { useGetUserHasPermission, PERMISSION } from "../../../utils/useGetUserHasPermission";
import { GET_PERSON_MEMBERS } from "../../../schemas/memberGql";
import { GET_SESSION_CHARGES } from "../../../schemas/paymentGql";
import { GET_PERSON_DOB_GENDER } from "../../../schemas/personGql";
import { GET_PERSON_REG_STEP_VALUES } from "../../../schemas/regStepValueGql";
import AlertFailedLoading from "../../AlertFailedLoading";
import { useAuth0 } from "../../Auth0";
import { CONDITION_TYPES } from "../../MenuAdmin/Registrations/RegistrationCondition";
import { getUserConfirmation } from "../../PromptDirtyForm";
import SpinPageContent from "../../SpinPageContent";
import { getRichTextContentIsEmpty } from "../../TextEditor/TextEditorPreview";
import { getAgeFromDob } from "../PersonAge";
import {
  getPersonRegStepValues,
  getPersonRolesAndGroupsFromMemberData,
  getPreparedSteps,
  mapDataToRegStatuses,
  GET_VALUES_FOR_CONDITION_CUSTOM_FIELDS,
  UPDATE_REG_SUBMISSION_STATUS,
} from "./PersonDetailRegistrationsConstants";
import RegistrationsAside from "./RegistrationsAside";
import RegistrationsContent from "./RegistrationsContent/RegistrationsContent";
import "./Registrations.scss";

export const GET_STEP_STATUSES_FOR_REG = gql`
  query PersonDetailRegistrations_GetStatusesForReg($personId: String!, $RegId: String!) {
    RegStatus(where: { RegId: { _eq: $RegId }, PersonId: { _eq: $personId }, Step: { deleted: { _eq: false } } }) {
      id
      status
      StepId
    }
  }
`;

export const GET_REG_SUBMISSION = gql`
  query PersonDetailRegistrations_GetRegSubmission($personId: String!, $RegId: String) {
    RegSubmission(where: { PersonId: { _eq: $personId }, RegId: { _eq: $RegId } }) {
      id
      status
      lastSubmittedAt
      percentComplete
      DelegationGroupId
      RoleId
    }
  }
`;

const GET_PERSON_REG_SUBMISSIONS_WITH_STEPS = gql`
  query PersonDetailRegistrations_GetPersonRegSubmissionsWithSteps($personId: String!) {
    Reg(where: { RegSubmissions: { PersonId: { _eq: $personId } } }, order_by: { name: asc }) {
      id
      name
      openAt
      closeAt
      ageRequirement
      ageAsAtDate
      minimumAge
      maximumAge
      archived
      timezoneName
      GroupId
      postSubmissionContent
      Group {
        id
        tenantId
      }
      RegSubmissions {
        id
        PersonId
        RegId
      }
      Steps(where: { deleted: { _eq: false } }) {
        id
        name
        meta
      }
    }
  }
`;

const PURGE_STALE_STEP_DATA = gql`
  mutation PersonDetailRegistrations_deleteStaleStepData_($PersonId: String!, $RegId: String!, $StepIds: [String]!) {
    deleteStaleStepData(PersonId: $PersonId, RegId: $RegId, StepIds: $StepIds) {
      affected_rows
    }
  }
`;

const GET_LOCKED_FIELDS = gql`
  query PersonDetailRegistrations_GetLockedFields($PersonId: ID!, $RegId: ID!) {
    getLockedFields(PersonId: $PersonId, RegId: $RegId) {
      locked_sports
      locked_competitions
      locked_participation_roles
      locked_custom_fields
    }
  }
`;

function PersonDetailRegistrations({ PersonId, RegId, setActiveRegId, displayAvatarUpload }) {
  const { role: userRole } = useAuth0();
  const canEditClosedRegistration = useGetUserHasPermission(PERMISSION.EDIT_CLOSED_REGISTRATION);
  const [activeStepId, setActiveStepId] = useState();
  const [hasSetPostSubmissionPage, setFlagPostSubmissionPage] = useState(false);
  const [updateRegSubmissionStatus] = useMutation(UPDATE_REG_SUBMISSION_STATUS, {
    refetchQueries: ["PersonDetailRegistrations_GetLockedFields"], // This refetch makes sure that any updated locked_fields will be in effect when an admin/user revisits a RegSubmission without refreshing their page.
    onError: err => {
      console.error(err);
      Sentry.captureException(err);
      message.error("An error occured when updating the registration.");
    },
  });

  const getPersonRegListQuery = useQuery(GET_PERSON_REG_SUBMISSIONS_WITH_STEPS, {
    variables: { personId: PersonId },
  });

  const getPersonRegStatusesQuery = useQuery(GET_STEP_STATUSES_FOR_REG, {
    variables: { personId: PersonId, RegId },
    skip: isNil(RegId),
  });

  const getRegSubmissionQuery = useQuery(GET_REG_SUBMISSION, {
    variables: { personId: PersonId, RegId },
    skip: isNil(RegId),
  });
  // lockedFields is only loaded for the end-users.
  const getLockedFields = useQuery(GET_LOCKED_FIELDS, {
    variables: { PersonId, RegId },
    skip: isNil(PersonId) || isNil(RegId) || userRole !== AUTH_ROLES.BASIC_USER,
  });

  const { refetch: refetchRegSubmission } = getRegSubmissionQuery;

  const RegSubmission = useMemo(() => get(getRegSubmissionQuery.data, "RegSubmission.0"), [getRegSubmissionQuery.data]);

  const getPersonDobGenderQuery = useQuery(GET_PERSON_DOB_GENDER, {
    variables: { personId: PersonId },
    skip: isNil(RegId),
  });

  const { refetch: getPersonDobGenderQueryRefetch } = getPersonDobGenderQuery;

  const getPersonMembersQuery = useQuery(GET_PERSON_MEMBERS, {
    variables: { personId: PersonId },
    skip: isNil(RegId),
  });

  const { refetch: getPersonMembersQueryRefetch } = getPersonMembersQuery;

  const getPersonRegStepValuesQuery = useQuery(GET_PERSON_REG_STEP_VALUES, {
    variables: { PersonId, RegId },
    skip: isNil(RegId),
  });

  const { refetch: getPersonRegStepValuesQueryRefetch } = getPersonRegStepValuesQuery;

  const getRegChargesQuery = useQuery(GET_SESSION_CHARGES, {
    variables: { RegSubmissionId: RegSubmission?.id },
    skip: isNil(RegSubmission?.id),
    fetchPolicy: "no-cache",
  });

  const { refetch: refetchRegCharges } = getRegChargesQuery;

  const regCharges = get(getRegChargesQuery, "data.getSessionCharges.charges", {});

  const lockedFields = get(getLockedFields, "data.getLockedFields", {});

  const regs = useMemo(() => {
    if (!getPersonRegListQuery.data) {
      return [];
    }

    const regRecords = get(getPersonRegListQuery.data, "Reg", []);

    return regRecords.map(registrationData => {
      const registration = omit(registrationData, ["__typename"]);

      const steps = registration.Steps.map(stepData => ({
        ...omit(stepData, ["__typename"]),
        RegId: registration.id,
        RegGroupId: registration.GroupId,
      }));

      const stepsSorted = sortBy(steps, step => step.meta.order);

      return {
        ...registration,
        Steps: stepsSorted,
      };
    });
  }, [getPersonRegListQuery.data]);

  const reg = useMemo(() => find(regs, regItem => regItem.id === RegId), [regs, RegId]);
  const steps = useMemo(() => get(reg, "Steps", []), [reg]);
  const regTenantId = useMemo(() => get(reg, "Group.tenantId", null), [reg]);

  const personDateOfBirth = get(getPersonDobGenderQuery.data, "Person_by_pk.dob", null);
  const personGender = get(getPersonDobGenderQuery.data, "Person_by_pk.gender", null);

  const personAge = useMemo(() => {
    // Don't evaluate the person's age if the `reg` record has not been loaded yet. This avoids us calculating the
    // person's age without knowing the registration's `ageAsAtDate`, and then re-calculating it once we know the
    // registration's `ageAsAtDate`, with two different outcomes in each case, which would result in non-ideal UX as a
    // step may appear and then disappear (based on "Age Requirement" conditions), or vice versa.
    if (isNil(reg)) {
      return null;
    }

    const ageAsAtDate = get(reg, "ageAsAtDate", null);

    return getAgeFromDob(personDateOfBirth, ageAsAtDate);
  }, [personDateOfBirth, reg]);

  const regAgeRequirementIsMet = useMemo(() => {
    // See `personAge` above.
    if (isNil(reg)) {
      return true;
    }

    const ageRequirement = get(reg, "ageRequirement", false);

    if (!ageRequirement) {
      return true;
    }

    const ageAsAtDate = get(reg, "ageAsAtDate", null);
    const minimumAge = get(reg, "minimumAge", null);
    const maximumAge = get(reg, "maximumAge", null);

    return getAgeRequirementIsMetFromDob(personDateOfBirth, ageAsAtDate, minimumAge, maximumAge);
  }, [personDateOfBirth, reg]);

  // Automatically set the first registration as the active one once we have loaded the user's registrations (and
  // if they have at least one registration). This will only happen when PersonDetailRegistrations is used in the
  // context of the PersonDetailRegistrationsTab component, since only in that component do we pass a `setActiveRegId`
  // function as a prop. It will not happen in the context of the RegistrationUserSubmit component.
  useEffect(() => {
    if (isFunction(setActiveRegId) && isNil(RegId) && !isEmpty(regs)) {
      setActiveRegId(regs[0].id);
    }
  }, [RegId, regs, setActiveRegId]);

  const { regFormIds, conditionFieldIds } = useMemo(() => {
    const fieldIds = [];
    const formIds = [];

    steps.forEach(step => {
      const conditions = get(step, "meta.conditions", []);
      const stepType = get(step, "meta.type", undefined);

      if (stepType === STEP_TYPE.FORM) {
        const formId = get(step, "meta.form", undefined);

        if (formId) {
          formIds.push(formId);
        }
      }

      conditions.forEach(condition => {
        const { type, field } = condition;

        if (type === CONDITION_TYPES.fieldValue && field) {
          fieldIds.push(field);
        }
      });
    });

    return { regFormIds: formIds, conditionFieldIds: fieldIds };
  }, [steps]);

  const GroupId = useMemo(() => get(reg, "GroupId", undefined), [reg]);

  const getValuesForConditionCustomFieldsQuery = useQuery(GET_VALUES_FOR_CONDITION_CUSTOM_FIELDS, {
    variables: { customFieldIds: conditionFieldIds, formIds: regFormIds, GroupId, PersonId },
    skip: !GroupId || !PersonId || isEmpty(conditionFieldIds) || isEmpty(regFormIds),
  });

  const { refetch: getValuesForConditionCustomFieldsQueryRefetch } = getValuesForConditionCustomFieldsQuery;

  const regFormFieldValuesForConditionCustomFields = useMemo(() => {
    const formFieldValues = [];

    const formFields = get(getValuesForConditionCustomFieldsQuery.data, "FormField", []);

    each(formFields, formField => {
      const customFieldId = get(formField, "FieldId", null);
      const value = get(formField, "Values.0.value", undefined);

      // Note that we need to correctly handle valid values which are falsy, e.g. a `false` value for a Yes/No field, or
      // a `0` value for a Number field.
      if (!isUndefined(value)) {
        formFieldValues.push({ customFieldId, value });
      }
    });

    return formFieldValues;
  }, [getValuesForConditionCustomFieldsQuery.data]);

  const regStatuses = useMemo(() => mapDataToRegStatuses(getPersonRegStatusesQuery.data), [
    getPersonRegStatusesQuery.data,
  ]);

  const { personRoles, personGroups } = useMemo(
    () => getPersonRolesAndGroupsFromMemberData(getPersonMembersQuery.data),
    [getPersonMembersQuery.data],
  );

  const personRegStepValues = useMemo(() => getPersonRegStepValues(getPersonRegStepValuesQuery.data), [
    getPersonRegStepValuesQuery.data,
  ]);

  const preparedSteps = useMemo(() => {
    // Determine if the registration has been submitted, and add the boolean result to the preparedSteps function.
    const regSubmissionStatus = get(RegSubmission, "status");
    const regStatusIsSubmitted = [REG_SUBMISSION_STATUSES.approved, REG_SUBMISSION_STATUSES.submitted].includes(
      regSubmissionStatus,
    );
    const displayPostSubmissionContent =
      regStatusIsSubmitted && !getRichTextContentIsEmpty(get(reg, "postSubmissionContent", ""));

    return getPreparedSteps(
      steps,
      regStatuses,
      personRoles,
      personGroups,
      personRegStepValues,
      regFormFieldValuesForConditionCustomFields,
      personAge,
      userRole,
      RegSubmission,
      displayPostSubmissionContent,
    );
  }, [
    reg,
    steps,
    regStatuses,
    personRoles,
    personGroups,
    personRegStepValues,
    regFormFieldValuesForConditionCustomFields,
    personAge,
    userRole,
    RegSubmission,
  ]);

  const preparedStepsRef = useRef(preparedSteps);

  useEffect(() => {
    preparedStepsRef.current = preparedSteps;
  }, [preparedSteps]);

  const regContextData = useMemo(() => {
    const regSubmissionStatus = get(RegSubmission, "status");

    const regArchived = get(reg, "archived", false);

    const regIsClosed = reg && reg.openAt && reg.closeAt ? isRegistrationClosed(reg.openAt, reg.closeAt) : false;

    const regStatusIsReadOnly = [
      REG_SUBMISSION_STATUSES.approved,
      REG_SUBMISSION_STATUSES.submitted,
      REG_SUBMISSION_STATUSES.rejected,
      REG_SUBMISSION_STATUSES.deregistered,
    ].includes(regSubmissionStatus);

    const regIsReadOnly = regArchived || regStatusIsReadOnly || (regIsClosed && !canEditClosedRegistration);

    return {
      RegId,
      PersonId,
      RegSubmission,
      reg,
      regCharges,
      lockedFields,
      regIsReadOnly,
      regIsClosed,
      regAgeRequirementIsMet,
      steps: preparedSteps,
      regTenantId,
      updateRegSubmissionStatus,
      refetchRegSubmission,
      refetchRegCharges,
    };
  }, [
    RegId,
    PersonId,
    RegSubmission,
    reg,
    regCharges,
    lockedFields,
    regAgeRequirementIsMet,
    preparedSteps,
    regTenantId,
    canEditClosedRegistration,
    updateRegSubmissionStatus,
    refetchRegSubmission,
    refetchRegCharges,
  ]);

  const personData = useMemo(() => {
    return {
      dob: personDateOfBirth,
      gender: personGender,
    };
  }, [personDateOfBirth, personGender]);

  const activeStep = useMemo(() => {
    // Match return value of `findIndex` when step is not found.
    if (!activeStepId) {
      return undefined;
    }

    return find(preparedSteps, step => step.id === activeStepId);
  }, [preparedSteps, activeStepId]);

  const activeStepIndex = useMemo(() => {
    if (!activeStepId) {
      // Match return value of `findIndex` when step is not found.
      return -1;
    }

    return findIndex(preparedSteps, step => step.id === activeStepId);
  }, [preparedSteps, activeStepId]);

  const activeStepIndexRef = useRef(activeStepIndex);

  useEffect(() => {
    activeStepIndexRef.current = activeStepIndex;
  }, [activeStepIndex]);

  const handleSetActiveStepId = useCallback(
    (newActiveStepId, withoutCheck = false) => {
      const oldActiveStepId = get(activeStep, "id", null);
      const oldActiveStepType = get(activeStep, "meta.type", "");

      const moveToStep = callback => {
        setActiveStepId(newActiveStepId);

        return callback && callback();
      };

      if (newActiveStepId && newActiveStepId !== oldActiveStepId) {
        if (STEP_TYPES_WITH_FORM.includes(oldActiveStepType)) {
          const storageFormName = `_formik-persist-${activeStep.id}`;
          const state = JSON.parse(window.localStorage.getItem(storageFormName) || null);
          const removeFormContext = () => window.localStorage.removeItem(storageFormName);

          if (state && state.dirty && state.submitCount === 0 && !withoutCheck) {
            const callback = result => result && moveToStep(removeFormContext);

            getUserConfirmation(DEFAULT_CONFIRMATION_MSG, callback);
          } else {
            moveToStep(removeFormContext);
          }
        } else {
          moveToStep();
        }
      }
    },
    [activeStep],
  );

  const [lastStepTriggered, setLastStepTriggered] = useState(false);

  const resetLastStepTriggered = () => {
    setLastStepTriggered(false);
  };

  /**
   * Note: this function uses React refs for `preparedSteps` and `activeStepIndex` instead of using those values
   * directly because when this function gets called, we need to go to the next step based on the most recent list of
   * `preparedSteps`, which may have just changed as a result of a step being submitted and conditions for other steps
   * being re-evaluated. If we simply use `preparedSteps` and `activeStepIndex` directly, even with those variables
   * being added as dependencies for this `useCallback`, their values will be stale and we will not be operating on the
   * latest correct values for those variables. When this function operates on stale values, we get bugs where the user
   * is taken to a step which is no longer meant to be visible to them, or where the user skips past a step which was
   * previously not visible, but now is visible.
   *
   * The same problem and solution applies to `prevStep` below.
   */
  const nextStep = useCallback(
    withoutCheck => {
      const lastPreparedStepsIndex = preparedStepsRef.current.length - 1;
      setLastStepTriggered(false);

      // If the current active step is the last step that's currently available and visible, we have no next step
      // to go to.
      if (activeStepIndexRef.current === lastPreparedStepsIndex) {
        setLastStepTriggered(true);
        return;
      }

      const newActiveStepId = preparedStepsRef.current[activeStepIndexRef.current + 1].id;

      handleSetActiveStepId(newActiveStepId, withoutCheck);
    },
    [handleSetActiveStepId],
  );

  const prevStep = useCallback(() => {
    // If the current active step is the first step that's currently available and visible, we have no previous step
    // to go to.
    if (activeStepIndexRef.current === 0) {
      return;
    }

    const newActiveStepId = preparedStepsRef.current[activeStepIndexRef.current - 1].id;

    handleSetActiveStepId(newActiveStepId);
  }, [handleSetActiveStepId]);

  // Automatically set the first step as the active one once we have calculated the user's steps (and if there is at
  // least one step). Note that this method does not work in all cases, see https://fuse.atlassian.net/browse/FUS-458.
  // FUS-1405: This has been modified to check for any PostSubmission content and set that as the first selected page.
  useEffect(() => {
    if (isEmpty(preparedSteps)) {
      return;
    }
    if (!hasSetPostSubmissionPage) {
      const postSubmissionContentStep = preparedSteps.find(step => step.id === POST_SUBMISSION_STATUS_STEP_ID);
      if (!isEmpty(postSubmissionContentStep)) {
        setFlagPostSubmissionPage(true);
        handleSetActiveStepId(postSubmissionContentStep.id);
        return;
      }
    }

    if (activeStep) {
      return;
    }

    setActiveStepId(preparedSteps[0].id);
  }, [hasSetPostSubmissionPage, activeStep, preparedSteps, handleSetActiveStepId]);

  useEffect(() => {
    return () => {
      const localStorageKeys = Object.keys(localStorage);

      localStorageKeys.forEach(key => {
        if (key.indexOf("_formik-persist-") !== -1) {
          localStorage.removeItem(key);
        }
      });
    };
  }, [RegId]);

  const staleSteps = useMemo(() => {
    // Get the difference of preparedSteps against all Steps in this Reg for this Person. These steps should be "invisible" to the User.
    // Then, return all those StepIds.
    return differenceBy(steps, preparedSteps, "id").map(x => x.id);
  }, [steps, preparedSteps]);

  const [deleteStaleStepData] = useMutation(PURGE_STALE_STEP_DATA, {
    variables: { RegId, PersonId, StepIds: staleSteps },
  });

  /**
   * Function which is called after a step is submitted, and which is responsible for refetching any necessary data
   * that could have changed as a result of submitting the step. This allows the new data to be fetched so that
   * `preparedSteps` will be updated accordingly, and then when we later call `nextStep` we will be able to go to the
   * next step correctly based on the new set of prepared steps, which may have just changed as a result of submitting
   * the step.
   */
  const handleRefetchDataAfterSubmit = useCallback(
    async step => {
      const type = get(step, "meta.type");

      if (type === STEP_TYPE.FORM) {
        await getValuesForConditionCustomFieldsQueryRefetch();
      } else if (type === STEP_TYPE.MEMBERSHIP) {
        await getPersonMembersQueryRefetch();
      } else if (type === STEP_TYPE.PROFILE) {
        await getPersonDobGenderQueryRefetch();
      } else if (type === STEP_TYPE.SPORTS) {
        await getPersonRegStepValuesQueryRefetch();
      } else if (type === STEP_TYPE.PARTICIPATION || type === STEP_TYPE.MEMBERSHIP) {
        // We force RegStepValue reevaluations on Role steps, because of step view logic and stale data.
        await getPersonRegStepValuesQueryRefetch();
      }

      return Promise.resolve();
    },
    [
      getValuesForConditionCustomFieldsQueryRefetch,
      getPersonMembersQueryRefetch,
      getPersonDobGenderQueryRefetch,
      getPersonRegStepValuesQueryRefetch,
    ],
  );

  if (
    (getPersonRegStatusesQuery.loading && !getPersonRegStatusesQuery.data) ||
    (getPersonDobGenderQuery.loading && !getPersonDobGenderQuery.data) ||
    (getPersonRegListQuery.loading && !getPersonRegListQuery.data) ||
    (getPersonMembersQuery.loading && !getPersonMembersQuery.data) ||
    (getPersonRegStepValuesQuery.loading && !getPersonRegStepValuesQuery.data) ||
    (getValuesForConditionCustomFieldsQuery.loading && !getValuesForConditionCustomFieldsQuery.data)
  ) {
    return <SpinPageContent style={{ width: "100%" }} />;
  }

  if (
    getPersonRegStatusesQuery.error ||
    getPersonDobGenderQuery.error ||
    getPersonRegListQuery.error ||
    getPersonMembersQuery.error ||
    getPersonRegStepValuesQuery.error ||
    getValuesForConditionCustomFieldsQuery.error
  ) {
    return (
      <div style={{ width: "100%", padding: 24 }}>
        <AlertFailedLoading message="Registrations failed to load" />
      </div>
    );
  }

  const hasRegs = !isNil(regs) && !isEmpty(regs);

  return (
    <RegContext.Provider
      value={{
        displayAvatarUpload,
        regContextData,
        personData,
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "0 12px" }}>
        <RegistrationsAside
          PersonId={PersonId}
          RegId={RegId}
          regs={regs}
          steps={preparedSteps}
          activeStepId={activeStepId}
          setActiveStepId={handleSetActiveStepId}
          setActiveRegId={setActiveRegId}
          refetchRegList={getPersonRegListQuery.refetch}
        />

        {hasRegs && (
          <RegistrationsContent
            PersonId={PersonId}
            RegId={RegId}
            regTenantId={regTenantId}
            steps={preparedSteps}
            activeStep={activeStep}
            activeStepIndex={activeStepIndex}
            nextStep={nextStep}
            prevStep={prevStep}
            refetchDataAfterSubmit={handleRefetchDataAfterSubmit}
            deleteStaleStepData={deleteStaleStepData}
            refetchRegStatuses={getPersonRegStatusesQuery.refetch}
            refetchRegCharges={refetchRegCharges}
            lastStepTriggered={lastStepTriggered}
            resetLastStepTriggered={resetLastStepTriggered}
          />
        )}
      </div>
    </RegContext.Provider>
  );
}

PersonDetailRegistrations.propTypes = {
  PersonId: PropTypes.string.isRequired,
  RegId: PropTypes.string,
  setActiveRegId: PropTypes.func,
  displayAvatarUpload: PropTypes.bool,
};

PersonDetailRegistrations.defaultProps = {
  RegId: undefined,
  setActiveRegId: null,
  displayAvatarUpload: false,
};

export default PersonDetailRegistrations;
