import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Spin, Alert } from "antd";
import gql from "fraql";
import { get } from "lodash";
import RegistrationSubmissionInvoiceMenuItem from "./RegistrationSubmissionInvoiceMenuItem";

const GET_REGSUBMISSION_INVOICES = gql`
  query getRegSubmissionInvoices($regSubmissionId: String!) {
    getRegSubmissionInvoices(regSubmissionId: $regSubmissionId) {
      invoices
    }
  }
`;

/**
 * Renders an Alert box containing a prompt to display the Invoice Modal.
 * @param {string} - The RegSubmission ID
 */
function RegistrationSubmissionInvoiceAlert({ regSubmissionId }) {
  const { data, error, loading } = useQuery(GET_REGSUBMISSION_INVOICES, {
    variables: { regSubmissionId },
    skip: !regSubmissionId,
  });

  const invoices = useMemo(() => {
    return get(data, "getRegSubmissionInvoices.invoices", []);
  }, [data]);

  if (loading) {
    return (
      <div className="sidebar-alert">
        <h3>Loading Invoices...</h3> <Spin />
      </div>
    );
  }

  if (error) {
    console.log(error);
    Sentry.captureException(error);
    return (
      <>
        <br /> <Alert type="error" message="Invoice failed to load. Please refresh the page and try again." />
      </>
    );
  }

  if (invoices.length <= 0) {
    return (
      <>
        <br /> <Alert type="success" showIcon message="No Invoice. Registration was free." />
      </>
    );
  }

  return invoices.map(invoice => (
    <RegistrationSubmissionInvoiceMenuItem invoice={invoice} key={invoice.InvoiceNumber} />
  ));
}

RegistrationSubmissionInvoiceAlert.propTypes = {
  regSubmissionId: PropTypes.string,
};

RegistrationSubmissionInvoiceAlert.defaultProps = {
  regSubmissionId: null,
};

export default RegistrationSubmissionInvoiceAlert;
