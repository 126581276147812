import React from "react";
import { Typography } from "antd";
import get from "lodash/get";
import { useRegContext } from "../utils/useRegContext";
import SectionHeaderContainer from "./SectionHeaderContainer";
import TextEditorPreview from "./TextEditor/TextEditorPreview";

/**
 * Displays content for a Post-Submission Screen.
 * This assumes that content has already been checked if it is null or not.
 * @returns Displays content for a Post-Submission Screen.
 */
function RegSubmissionPostSubmissionContent() {
  const regContext = useRegContext();

  const { reg } = get(regContext, "regContextData", {});

  const descriptionContent = get(reg, "postSubmissionContent", "");

  return (
    <div className="registration-user-landing-page__flex-container">
      <div className="registration-user-landing-page__content">
        <SectionHeaderContainer>
          <Typography.Title level={3}>Registration Complete</Typography.Title>
        </SectionHeaderContainer>

        <div className="registration-user-landing-page__description">
          <TextEditorPreview defaultValue={descriptionContent} />
        </div>
      </div>
    </div>
  );
}

export default RegSubmissionPostSubmissionContent;
