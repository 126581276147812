import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
import isBlank from "../../utils/isBlank";

// TODO: Legacy Slate editor settings. Removed in FUS-1480. We could clean this all up if not necessary anymore.
// The value stored in the DB when the field has the default content, i.e. the content which appears automatically when
// a step is first created, and the user has not made any edits to the content form field. This default content is just
// a new line with no text.
const DEFAULT_CONTENT = `{"object":"value","document":{"object":"document","data":{},"nodes":[{"object":"block","type":"line","data":{},"nodes":[{"object":"text","text":"","marks":[]}]},{"object":"block","type":"paragraph","data":{},"nodes":[{"object":"text","text":"","marks":[]}]}]}}`;

// The value stored in the DB when the field has empty content, i.e. the content after the user manually clears out the
// default content from the content form field.
const EMPTY_CONTENT = `{"object":"value","document":{"object":"document","data":{},"nodes":[{"object":"block","type":"paragraph","data":{},"nodes":[{"object":"text","text":"","marks":[]}]}]}}`;

/**
 * @param {String} value RichText Content string
 * @returns True if the content is empty/null, or is the default Rich Text Empty Template. Returns FALSE if the content actually contains content.
 */
export function getRichTextContentIsEmpty(value) {
  return isBlank(value) || value === DEFAULT_CONTENT || value === EMPTY_CONTENT;
}

/**
 * Loads stringified HTML values and renders them as native HTML elements within browser.
 * @param {string} defaultValue - The stringified HTML content to render.
 * @returns JSX Component containing the parsed HTML content.
 */
const TextEditorPreview = props => {
  const { defaultValue, style } = props;
  const containerRef = useRef(null);

  useEffect(() => {
    // If the value is null or undefined, we prevent the DOM parser from loading.
    if (isNil(defaultValue)) {
      return;
    }
    // Create a new DOMParser
    const parser = new DOMParser();

    // Parse the HTML string
    const parsedDocument = parser.parseFromString(defaultValue, "text/html");

    // Access the parsed content and append it to the container
    const fragment = document.createDocumentFragment();
    Array.from(parsedDocument.body.childNodes).forEach(node => {
      fragment.appendChild(node.cloneNode(true));
    });

    // Clear existing content in the container
    containerRef.current.innerHTML = "";

    // Append the parsed content to the container
    containerRef.current.appendChild(fragment);
  }, [defaultValue]);

  // If there is no content to render, we return null.
  // If there is, we can then render out a div with the content.
  return isNil(defaultValue) ? null : (
    <div className="text-editor" style={style}>
      <div ref={containerRef} />
    </div>
  );
};

TextEditorPreview.propTypes = {
  defaultValue: PropTypes.string,
  style: PropTypes.any,
};

TextEditorPreview.defaultProps = {
  defaultValue: "",
  style: undefined,
};

export default TextEditorPreview;
