import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Form, Row, Checkbox } from "antd";
import { Col } from 'antd/es/grid';
import { connect } from "formik";
import { Select } from "formik-antd";
import gql from "fraql";
import { cloneDeep, each } from "lodash";
import omit from "lodash/omit";
import SpinPageContent from "../SpinPageContent";

const CheckboxGroup = Checkbox.Group;

const GET_REGS = gql`
  query GetRegistrations {
    Reg(where: {archived: {_neq: true}, Steps: { type: {_eq: "Fee" }, deleted: {_eq: false}}}, order_by: {name: asc}) {
      id
      name
      Steps(where: {type: {_eq: "Fee" }, deleted: {_eq: false}}) {
        id
        meta
        name
        type
      }
    }
  }
`;

const FormFieldFeeStepSelector = props => {
    const { formik, meta } = props;
    const { required, label } = meta;
    const { Option } = Select;
    const { values } = formik;

    const [selectedReg, setSelectedReg] = useState(values.regId ? values.regId : "");

    const { loading, data: regData } = useQuery(GET_REGS);

    const getFeeStepsForReg = () => {
      if (!regData || !selectedReg){
        return [];
      }

      const reg = regData.Reg.find(x => x.id === selectedReg);

      const result = [];
      
      if (reg && reg.Steps){
        each (reg.Steps, function(step){
          const temp = cloneDeep(step);
          temp.label = step.name.concat(' / ', step.meta.displayName);
          temp.value = step.id;
          result.push(temp);
        });
        return result;
      }

      return [];
    }

    const getSelectedFeeSteps = () => {
      if (!values || !values.appliesTo){
        return [];
      }

      return values.appliesTo;
    }

    const stepSelectionChanged = (e) => {

      formik.setFieldValue("appliesTo", e);
    }

    return (
    <Form.Item {...omit(props, ["formik"])} required={required} label={label}>
        <Row>
          <Col span={24}>
            <div style={{paddingBottom: "5px", paddingLeft: "10px"}}>Registration</div>
            { loading ? <SpinPageContent /> :
            <Select value={selectedReg}
              onChange={val => setSelectedReg(val)}
              disabled={loading}>
              {!selectedReg && <Option value="">-- Select Registration --</Option>}
              {regData && regData.Reg.map((reg) => (
                  <Option key={reg.id} value={reg.id}>{reg.name}</Option>
                ))
              }
            </Select>}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{paddingTop: "10px", paddingBottom: "5px", paddingLeft: "10px"}}>Fee Steps</div>
            <div style={{paddingLeft: "15px"}}>
              <CheckboxGroup
                options={getFeeStepsForReg()}
                onChange={stepSelectionChanged}
                value={getSelectedFeeSteps()}
              />
            </div>  
          </Col>
        </Row>
    </Form.Item>
    );

};

FormFieldFeeStepSelector.propTypes = {
    meta: PropTypes.object.isRequired,
    formik: PropTypes.object.isRequired,
  };

export default connect(FormFieldFeeStepSelector);;
