// Inspired by https://github.com/ant-design/ant-design/issues/6576#issuecomment-479445435.

import React from "react";
import PropTypes from "prop-types";
import { Menu, Icon } from "antd";
import { get } from "lodash";
import { NavLink, withRouter } from "react-router-dom";
import uuidv4 from "uuid/v4";
import isNil from "lodash/isNil";

// eslint-disable-next-line react/prop-types
const Item = ({ to, children, exact = false, badge = null, showZero = true, ...restProps }) => {
  if (!isNil(badge) && badge <= 0 && !showZero) {
    return null;
  }

  return (
    <Menu.Item {...restProps}>
      <NavLink className="nav-text" activeClassName="active" to={to} exact={exact}>
        {children}
        <div className="menu-item-badge">{badge}</div>
      </NavLink>
    </Menu.Item>
  );
};

// eslint-disable-next-line react/prop-types
const renderItemChildren = ({ icon, title }) => (
  <>
    {!!icon && <Icon type={icon} />}
    <span>{title}</span>
  </>
);

const renderMenuItem = item => {
  const { path, exact, badge, showZero, children, visible = true } = item;

  const id = get(children, "id", uuidv4());

  if (!visible) {
    return null;
  }

  return (
    <Item key={id} to={path} exact={exact} badge={badge} showZero={showZero}>
      {children || renderItemChildren(item)}
    </Item>
  );
};

function LayoutTabsNavigation({ items, location, className, style }) {
  const root = `/${location.pathname.split("/")[1]}`;

  return (
    <Menu
      theme="light"
      mode="horizontal"
      style={{ padding: 0, lineHeight: "36px", borderBottom: "none", background: "transparent", ...style }}
      defaultOpenKeys={[root]}
      selectedKeys={[root, location.pathname]}
      className={className}
    >
      {items.map(renderMenuItem)}
    </Menu>
  );
}

LayoutTabsNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
      title: PropTypes.string,
      children: PropTypes.any,
      exact: PropTypes.bool,
      badge: PropTypes.any,
      showZero: PropTypes.bool,
      icon: PropTypes.any,
    }),
  ).isRequired,
  location: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.any,
};

LayoutTabsNavigation.defaultProps = {
  className: "",
  style: {},
};

// eslint-disable-next-line no-func-assign
LayoutTabsNavigation = withRouter(LayoutTabsNavigation);

export default LayoutTabsNavigation;
