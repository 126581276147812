import { useMemo } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "fraql";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { useAuth0 } from "../components/Auth0";

export const GET_USER = gql`
  query GetUser($auth0Id: String!) {
    User(where: { auth0Id: { _eq: $auth0Id } }) {
      id
      auth0Id
      personId
    }
  }
`;

let appUser = null;

/**
 * TODO: This whole thing is based on legacy Auth0 issues. This component and whole Auth0.js module need to be completely rewritten due to several weird auth bugs.
 * Important note: this will not work correctly in a local environment if you are logged in as a newly created user.
 * Auth0 has a Rule which sends a request to our Hasura backend every time a new user is created, and this request
 * creates a new User record in our database corresponding with the newly created user. In a local environment (i.e.
 * running on your laptop/PC), Auth0 will be sending the request to the primary Hasura backend associated with that
 * Auth0 tenant (i.e. a hosted Hasura backend that is accessible via the internet), and therefore it won't be sending
 * the request to your local instance of Hasura. The result is that in your local database, there will be no User record
 * that matches the Auth0 ID from your JWT, so the query performed by this hook will not return any User record, and
 * this hook will simply always return `null`.
 */
function useCurrentUser() {
  const { user } = useAuth0();

  const auth0Id = useMemo(() => get(user, "sub", undefined), [user]);

  const [getUser, { loading, error, data, called }] = useLazyQuery(GET_USER);

  return useMemo(() => {
    if (appUser && appUser.auth0Id !== auth0Id) {
      getUser({ variables: { auth0Id } });
    }

    if (appUser) {
      return appUser;
    }

    if (!called && auth0Id) {
      getUser({ variables: { auth0Id } });
    }

    if ((loading && !data) || error) {
      return null;
    }

    if (data && !isEmpty(data.User)) {
      appUser = omit(data.User[0], ["__typename"]);
    }

    return appUser;
  }, [data, loading, error, called, getUser, auth0Id]);
}

export default useCurrentUser;
