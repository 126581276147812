import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";

/**
 * A TinyMCE v6 WYSIWYG Rich-Text Editor. Typically loaded by `FormFieldContent`, which provides Formik functions and wiring.
 * @param {string} name - A unique "name" to allow Formik to track and store the value of this editor.
 * @param {string} setFieldValue - The Formik function required to update form value.
 * @param {string} defaultValue - (Optional) A default value to initialise the Editor with.
 * @param {boolean} disabled - (Optional) Turn the Form into Read-Only mode if set to true.
 * @returns JSX Component wired up to the provided Formik context.
 */
const TextEditor = ({ name, setFieldValue, defaultValue, disabled }) => {
  const [value, setValue] = useState(defaultValue ?? "");

  useEffect(() => setFieldValue(name, value), [name, value, setFieldValue]);

  return (
    <div className="text-editor">
      <Editor
        name={name}
        value={value}
        onEditorChange={newValue => setValue(newValue)}
        disabled={disabled}
        init={{
          menubar: true,
          plugins: "lists code table link",
          toolbar: "h1 h2 bold italic underline link blockquote numlist bullist table code",
        }}
        tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.8.2/tinymce.min.js"
      />
    </div>
  );
};

TextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TextEditor.defaultProps = {
  disabled: false,
};

export default TextEditor;
