import TextEditorPreview from "../TextEditor/TextEditorPreview";
import FormFieldAlert from "./FormFieldAlert";
import FormFieldBlacklist from "./FormFieldBlacklist";
import FormFieldBool from "./FormFieldBool";
import FormFieldChoices from "./FormFieldChoices";
import FormFieldCompetition from "./FormFieldCompetition";
import FormFieldCompetitionScore from "./FormFieldCompetitionScore";
import FormFieldCompetitionScoreType from "./FormFieldCompetitionScoreType";
import FormFieldCompetitionTeams from "./FormFieldCompetitionTeams";
import FormFieldCompetitorsFilter from "./FormFieldCompetitorsFilter";
import FormFieldContent from "./FormFieldContent";
import FormFieldCountry from "./FormFieldCountry";
import FormFieldCurrency from "./FormFieldCurrency";
import FormFieldDate from "./FormFieldDate";
import FormFieldDatetime from "./FormFieldDatetime";
import FormFieldDiscountAmount from "./FormFieldDiscountAmount";
import FormFieldDivider from "./FormFieldDivider";
import FormFieldElapsedTime from "./FormFieldElapsedTime";
import FormFieldEmail from "./FormFieldEmail";
import FormFieldFeeStepSelector from "./FormFieldFeeStepSelector";
import FormFieldField from "./FormFieldField";
import FormFieldFile from "./FormFieldFile/FormFieldFile";
import FormFieldForm from "./FormFieldForm";
import FormFieldGender from "./FormFieldGender";
import FormFieldGroup from "./FormFieldGroup";
import FormFieldGroupEventSelect from "./FormFieldGroupEventSelect";
import FormFieldGroupType from "./FormFieldGroupType";
import FormFieldHeading from "./FormFieldHeading";
import FormFieldHidden from "./FormFieldHidden";
import FormFieldImage from "./FormFieldImage";
import FormFieldLanguage from "./FormFieldLanguage";
import FormFieldLocation from "./FormFieldLocation";
import FormFieldNumber from "./FormFieldNumber";
import FormFieldPassword from "./FormFieldPassword";
import FormFieldPhone from "./FormFieldPhone";
import FormFieldRadio from "./FormFieldRadio";
import FormFieldRadioButton from "./FormFieldRadioButton";
import FormFieldRegGroup from "./FormFieldRegGroup";
import FormFieldRegistration from "./FormFieldRegistration";
import FormFieldRegistrationCompetition from "./FormFieldRegistrationCompetition";
import FormFieldResultStatus from "./FormFieldResultStatus";
import FormFieldRole from "./FormFieldRole";
import FormFieldSelect from "./FormFieldSelect";
import FormFieldSport from "./FormFieldSport";
import FormFieldSportFormat from "./FormFieldSportFormat";
import FormFieldSportMeetCompetition from "./FormFieldSportMeetCompetition";
import FormFieldTeamConditions from "./FormFieldTeamConditions/FormFieldTeamConditions";
import FormFieldTeamMembersMinMax from "./FormFieldTeamMembersMinMax";
import FormFieldTeamQualificationScoreDetermination from "./FormFieldTeamQualificationScoreDetermination";
import FormFieldTenant from "./FormFieldTenant";
import FormFieldText from "./FormFieldText";
import FormFieldTextArea from "./FormFieldTextArea";
import FormFieldTime from "./FormFieldTime";
import FormFieldTimezone from "./FormFieldTimezone";
import FormFieldTitle from "./FormFieldTitle";
import FormFieldTravelGroup from "./FormFieldTravelGroup";
import FormFieldWhitelist from "./FormFieldWhitelist";

/**
 * Component mapping from declaration in form to Component.
 */
// eslint-disable-next-line import/prefer-default-export
export const FORM_FIELD_COMPONENT_MAP = {
  alert: FormFieldAlert,
  blacklist: FormFieldBlacklist,
  bool: FormFieldBool,
  choices: FormFieldChoices,
  competition: FormFieldCompetition,
  competitionScore: FormFieldCompetitionScore,
  competitionScoreType: FormFieldCompetitionScoreType,
  competitionTeams: FormFieldCompetitionTeams,
  competitorsFilter: FormFieldCompetitorsFilter,
  content: FormFieldContent,
  readonlyContent: TextEditorPreview,
  country: FormFieldCountry,
  currency: FormFieldCurrency,
  date: FormFieldDate,
  datetime: FormFieldDatetime,
  discountAmount: FormFieldDiscountAmount,
  divider: FormFieldDivider,
  elapsedTime: FormFieldElapsedTime,
  email: FormFieldEmail,
  feeStepSelector: FormFieldFeeStepSelector,
  field: FormFieldField,
  file: FormFieldFile,
  form: FormFieldForm,
  sportFormat: FormFieldSportFormat,
  gender: FormFieldGender,
  group: FormFieldGroup,
  groupEvent: FormFieldGroupEventSelect,
  groupType: FormFieldGroupType,
  heading: FormFieldHeading,
  hidden: FormFieldHidden,
  image: FormFieldImage,
  language: FormFieldLanguage,
  location: FormFieldLocation,
  number: FormFieldNumber,
  phone: FormFieldPhone,
  radio: FormFieldRadio,
  radioButton: FormFieldRadioButton,
  registration: FormFieldRegistration,
  registrationGroup: FormFieldRegGroup,
  registrationCompetition: FormFieldRegistrationCompetition,
  resultStatus: FormFieldResultStatus,
  role: FormFieldRole,
  select: FormFieldSelect,
  sport: FormFieldSport,
  sportMeetCompetition: FormFieldSportMeetCompetition,
  teamConditions: FormFieldTeamConditions,
  teamMembersMinMax: FormFieldTeamMembersMinMax,
  teamQualificationScoreDetermination: FormFieldTeamQualificationScoreDetermination,
  tenant: FormFieldTenant,
  text: FormFieldText,
  password: FormFieldPassword,
  textarea: FormFieldTextArea,
  time: FormFieldTime,
  timezone: FormFieldTimezone,
  title: FormFieldTitle,
  travelGroup: FormFieldTravelGroup,
  whitelist: FormFieldWhitelist,
};
