import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Spin } from "antd";
import gql from "fraql";
import get from "lodash/get";
import omit from "lodash/omit";
import sortBy from "lodash/sortBy";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

const GET_COUNTRY_OPTIONS = gql`
  query CountryList_By_Tenant($tenantId: String!) {
    Country(where: { tenantId: { _eq: $tenantId } }) {
      id
      code
      name
    }
  }
`;

function FormFieldCountry(props) {
  const { meta } = props;
  const tenantId = get(meta, "formTenantId", null);

  const { loading, data: countryData } = useQuery(GET_COUNTRY_OPTIONS, {
    variables: { tenantId },
    skip: !tenantId,
  });

  let countryList = useMemo(() => {
    if (loading) {
      return [];
    }

    const countryQueryList = get(countryData, "Country", []);
    if (countryQueryList.length === 0) {
      const localList = JSON.parse(localStorage.getItem("countryList") || "[]");
      return sortBy(
        localList.map(country => {
          return { value: country.id, label: country.name };
        }),
        option => option.label,
      );
    }
    return countryQueryList;
  }, [countryData, loading]);

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Select a country";
  }

  if (loading && !countryData) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (!loading && countryData) {
    countryList = sortBy(
      countryList.map(Country => ({ value: Country.id, label: Country.name })),
      option => option.label,
    );
  }
  return <FormFieldSelect {...props} meta={{ ...meta, options: countryList, placeholder }} />;
}

FormFieldCountry.propTypes = {
  meta: PropTypes.object.isRequired,
};

export default FormFieldCountry;
