import * as Sentry from "@sentry/browser";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, execute, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import gql from "fraql";
import { SubscriptionClient } from "subscriptions-transport-ws";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import ENV from "../constants/envConstants";
import introspectionQueryResultData from "../schemas/fragmentTypes.json";

/**
 * Utility for creating an ApolloClient which makes requests to Hasura.
 */
class ApolloClientManager {
  constructor() {
    this.httpLink = null;
    this.wsLink = null;
    this.link = null;
    this.errorLink = null;
    this.client = null;
  }

  createApolloClient(token, role) {
    const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
    const cache = new InMemoryCache({ fragmentMatcher });

    let headers = {};

    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (role) {
      headers = {
        ...headers,
        "X-Hasura-Role": role,
      };
    }

    this.httpLink = createUploadLink({
      uri: ENV.API_GRAPHQL_HTTP_URL,
      headers,
    });

    this.wsLink = new WebSocketLink(
      new SubscriptionClient(ENV.API_GRAPHQL_WEBSOCKET_URL, {
        reconnect: true,
        connectionParams: {
          headers,
        },
      }),
    );

    this.link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);

        return kind === "OperationDefinition" && operation === "subscription";
      },
      this.wsLink,
      this.httpLink,
    );

    this.errorLink = onError(({ operation, networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, extensions }) => {
          const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${extensions.locations}, Path: ${extensions.path}. QueryName: ${operation.operationName}.`;

          console.error(errorMessage);

          Sentry.captureException(new Error(errorMessage));

          if (message === "Could not verify JWT: JWTExpired") {
            window.location.reload();
          }

          return true;
        });
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    });

    const defaultOptions = {
      watchQuery: { fetchPolicy: "cache-and-network" },
    };

    this.client = new ApolloClient({
      link: ApolloLink.from([this.errorLink, this.link]),
      cache,
      defaultOptions,
    });

    return this.client;
  }

  subscribe({ gqlOperation, handleSuccess, handleError, variables }) {
    const operation = {
      query: gql`
        ${gqlOperation}
      `,
      variables,
    };

    const onSubscribeError = (error, unsubscribe) => {
      Sentry.captureException(error);

      if (isFunction(handleError)) {
        handleError({ error, unsubscribe });
      }
    };

    const { unsubscribe } = execute(this.wsLink, operation).subscribe({
      next: result => {
        const error = get(result, "errors.0", null);

        if (error) {
          onSubscribeError(error, unsubscribe);
        } else if (isFunction(handleSuccess)) {
          const { data } = result;

          handleSuccess({ data, unsubscribe });
        }
      },
      error: error => {
        onSubscribeError(error, unsubscribe);
      },
    });

    return unsubscribe;
  }
}

export default new ApolloClientManager();
