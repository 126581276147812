import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import gql from "fraql";
import { isNil, get } from "lodash";
import { useAuth0 } from "./Auth0";

export const TenantPickerContext = React.createContext();

export const useTenantPicker = () => useContext(TenantPickerContext);

const GET_DOMAIN_TENANT = gql`
  query Get_Tenant_Via_Domain($domain: String = "") {
    getTenantViaDomain(domain: $domain) {
      id
      name
      key
    }
  }
`;

const GET_COUNTRY_LIST = gql`
  query personList_GetTenantCountryList($tenantId: String!) {
    Country(where: { tenantId: { _eq: $tenantId } }) {
      id
      code
      name
    }
  }
`;

export function TenantPickerProvider({ children }) {
  const [tenant, setTenant] = useState(null);
  const [tenantFilter, setTenantFilter] = useState(null);
  const { isAuthenticated } = useAuth0();

  // Getting Tenant details based on hostname/domain of website.
  const { hostname } = window.location;
  const { data, loading } = useQuery(GET_DOMAIN_TENANT, {
    variables: { domain: hostname },
  });

  const domainTenant = get(data, "getTenantViaDomain", null);
  const tenantId = useMemo(() => get(domainTenant, "id", null), [domainTenant]);

  const { data: countryListData } = useQuery(GET_COUNTRY_LIST, {
    variables: { tenantId },
    skip: !isAuthenticated,
  });

  // This countryList is only used to populate Filters like on the People page.
  // This is due to the how the Filters and whatnot are outside of the React ecosystem.
  // Stuffing the country list into localstorage, however, is perfectly fine.
  const countryList = useMemo(() => {
    const list = get(countryListData, "Country", []);
    list.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return list;
  }, [countryListData]);

  useEffect(() => {
    localStorage.setItem("countryList", JSON.stringify(countryList));
    return () => {
      localStorage.removeItem("countryList");
    };
  }, [countryList]);

  // Set Tenant based on domain of the site.
  // If no reliable tenant is found, set Tenant Id and name to "unknown".
  useEffect(() => {
    if (!isNil(domainTenant)) {
      setTenant({
        id: domainTenant.id,
        name: domainTenant.name,
        key: domainTenant.key,
      });
    }
    if (isNil(domainTenant) && !loading) {
      setTenant({
        id: "unknown",
        name: "unknown",
        key: "unknown",
      });
    }
  }, [loading, domainTenant]);

  return (
    <TenantPickerContext.Provider
      value={{
        tenantId: tenant ? tenant.id : null,
        tenantName: tenant ? tenant.name : null,
        tenantKey: tenant ? tenant.key : null,
        tenantFilter,
        setTenantFilter,
      }}
    >
      {children}
    </TenantPickerContext.Provider>
  );
}

TenantPickerProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};
