import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { DeleteOutlined, EyeOutlined, LockOutlined, MailOutlined, UserOutlined, MergeCellsOutlined } from "@ant-design/icons";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { Button, Dropdown, Menu, Modal } from "antd";
import gql from "fraql";
import { useHistory, useLocation } from "react-router-dom";
import first from "lodash/first";
import get from "lodash/get";
import { PEOPLE, PROFILE, PROFILE_CHANGE_EMAIL, PROFILE_CHANGE_PASSWORD } from "../../constants/routeConstants";
import { serverErrorStatus } from "../../utils/graphqlErrors";
import useCurrentUser from "../../utils/useCurrentUser";
import { PERMISSION, useGetUserHasPermission } from "../../utils/useGetUserHasPermission";
import { GET_PERSON_USER } from "../../schemas/personGql";
import ColumnValueText from "../ColumnValueText";

const PERSON_DELETE = gql`
  mutation PersonProfileActionsMenu_DeletePerson($PersonId: ID!) {
    deletePerson(PersonId: $PersonId) {
      affected_rows
    }
  }
`;

export const VIEWING_MODES = {
  PEOPLE_MENU: "PEOPLE_MENU",
  PERSONAL_PROFILE: "PERSONAL_PROFILE",
};

/**
 * Used by Personal Profile tools to determine which context the component is being rendered in.
 * @param {*} routerLocation - The React Router v5 "location" object.
 * @returns String declaring the Viewing Mode.
 */
export function getViewingMode(routerLocation) {
  if (routerLocation.pathname.includes(PEOPLE)) {
    return "PEOPLE_MENU";
  }
  if (routerLocation.pathname.includes(PROFILE)) {
    return "PERSONAL_PROFILE";
  }
  return "UNKNOWN";
}

/**
 * Action Dropdown Menu for Profile Management: Change Email, Change Password, Delete Person, etc.
 * @param {string} personId - Required. Loads the Person's details.
 * @param {Object} style - (Optional). Changes containing div CSS style.
 * @returns JSX Component that renders a Button with a DropDown Menu.
 */
function PersonProfileActionsMenu({ personId, style }) {
  const history = useHistory();
  const location = useLocation();
  const apolloClient = useApolloClient();
  const currentUser = useCurrentUser();

  const [deletePerson] = useMutation(PERSON_DELETE);

  const { loading: personUsersLoading, error: personUsersError, data: personUsersData } = useQuery(GET_PERSON_USER, {
    variables: { PersonId: personId },
    skip: !personId,
  });

  // Determine in what context this Menu is loaded/viewed in.
  const viewingMode = useMemo(() => {
    return getViewingMode(location);
  }, [location]);

  const currentUserPersonId = useMemo(() => get(currentUser, "personId"), [currentUser]);
  const userIsPersonId = currentUserPersonId === personId;

  const personUser = useMemo(() => first(get(personUsersData, "Person_by_pk.Users", [])), [personUsersData]);

  const hasDeletePersonPermission = useGetUserHasPermission(PERMISSION.DELETE_PERSON);
  const hasViewPersonUserPermission = useGetUserHasPermission(PERMISSION.VIEW_PERSON_USER);
  const hasChangeUserPasswordPermission = useGetUserHasPermission(PERMISSION.CHANGE_USER_PASSWORD) || userIsPersonId;
  const hasChangeUserEmailAddress = useGetUserHasPermission(PERMISSION.CHANGE_USER_EMAIL_ADDRESS) || userIsPersonId;

  function handleClickViewUser() {
    Modal.info({
      title: "User account details",
      content: (
        <ul>
          <li>
            <strong>Auth0 ID:</strong> <ColumnValueText>{personUser.auth0Id}</ColumnValueText>
          </li>
          <li>
            <strong>Email:</strong> <ColumnValueText>{personUser.email}</ColumnValueText>
          </li>
        </ul>
      ),
      style: { minWidth: 500 },
    });
  }

  function handleClickViewProfile() {
    if (viewingMode === VIEWING_MODES.PEOPLE_MENU) {
      history.push(`${PEOPLE}/${personId}`);
    } else if (viewingMode === VIEWING_MODES.PERSONAL_PROFILE) {
      history.push(PROFILE);
    }
  }

  function handleClickChangePassword() {
    if (viewingMode === VIEWING_MODES.PEOPLE_MENU) {
      history.push(`${PEOPLE}/${personId}/changeuserpassword`);
    } else if (viewingMode === VIEWING_MODES.PERSONAL_PROFILE) {
      history.push(PROFILE_CHANGE_PASSWORD);
    }
  }

  function handleClickChangeEmailAddress() {
    if (viewingMode === VIEWING_MODES.PEOPLE_MENU) {
      history.push(`${PEOPLE}/${personId}/changeuseremailaddress`);
    } else if (viewingMode === VIEWING_MODES.PERSONAL_PROFILE) {
      history.push(PROFILE_CHANGE_EMAIL);
    }
  }

  function handleClickMergePersons() {
    if (viewingMode === VIEWING_MODES.PEOPLE_MENU) {
      history.push(`${PEOPLE}/${personId}/merge`);
    } 
  }

  async function handleClickDeletePerson() {
    const content = "Are you sure you want to delete this person? This action cannot be undone.";

    const modal = Modal.confirm({
      title: "Delete person",
      content,
      okText: "Delete",
      async onOk() {
        modal.update({
          okButtonProps: {
            loading: true,
          },
          cancelButtonProps: {
            disabled: true,
          },
        });

        try {
          await deletePerson({
            variables: { PersonId: personId },
          });

          history.replace(PEOPLE);

          Modal.confirm({
            title: "Person deleted successfully",
            content: "Your request to delete this person was successful.",
            cancelButtonProps: {
              hidden: true,
            },
            async onOk() {
              apolloClient.resetStore();
            },
          });
        } catch (error) {
          const errorStatus = serverErrorStatus(error);

          const description = get(errorStatus, "description", "There was an error deleting this person.");

          Modal.error({
            title: "Person not deleted",
            content: description,
          });

          Sentry.captureException(error);
        }
      },
      onCancel() {},
    });
  }

  function renderUserAccountDetailsMenuItem() {
    if (personUsersLoading && !personUsersData) {
      return (
        <Menu.Item disabled>
          <EyeOutlined /> Loading...
        </Menu.Item>
      );
    }

    if (personUsersError) {
      return (
        <Menu.Item disabled>
          <EyeOutlined /> Error loading user account details
        </Menu.Item>
      );
    }

    if (!personUser) {
      return null;
    }

    return (
      <Menu.Item onClick={handleClickViewUser}>
        <EyeOutlined /> View User Account Details
      </Menu.Item>
    );
  }

  // Define the Menu for the Actions Menu.
  //
  const menu = (
    <Menu>
      <Menu.Item onClick={handleClickViewProfile}>
        <UserOutlined /> Profile Details
      </Menu.Item>

      {hasDeletePersonPermission && (
        <Menu.Item onClick={handleClickDeletePerson}>
          <DeleteOutlined /> Delete Person
        </Menu.Item>
      )}

      {hasViewPersonUserPermission && renderUserAccountDetailsMenuItem()}

      {hasChangeUserPasswordPermission && (
        <Menu.Item onClick={handleClickChangePassword}>
          <LockOutlined />
          Change Password
        </Menu.Item>
      )}

      {hasChangeUserEmailAddress && (
        <Menu.Item icon="@" onClick={handleClickChangeEmailAddress}>
          <MailOutlined />
          Change Email Address
        </Menu.Item>
      )}
      <Menu.Item icon="@" onClick={handleClickMergePersons}>
          <MergeCellsOutlined />
          Merge People
        </Menu.Item>
    </Menu>
  );

  return (
    <div style={style}>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <Button icon="down" size="small">
          Actions
        </Button>
      </Dropdown>
    </div>
  );
}

PersonProfileActionsMenu.propTypes = {
  personId: PropTypes.string.isRequired,
  style: PropTypes.any,
};

PersonProfileActionsMenu.defaultProps = {
  style: undefined,
};

export default PersonProfileActionsMenu;
