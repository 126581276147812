import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Typography, Select, Col, Row } from "antd";
import gql from "fraql";
import { get, isEmpty, uniq, isNil } from "lodash";
import moment from "moment-timezone";
import { renderOptions } from "../../../utils/selectUtils";
import SectionHeaderContainer from "../../SectionHeaderContainer";
import SpinPageContent from "../../SpinPageContent";
import { useTenantPicker } from "../../TenantPickerContext";
import MyPublicRegistrationItem from "./MyPublicRegistrationItem";
import MyRegistrationItem from "./MyRegistrationItem";
import { REGISTRATION_LIST_MODE, LIST_REG_SUBMISSION_STATUSES } from "./MyRegistrationsConstants";
import "./MyRegistrationsList.scss";

const GET_REG_SUBMISSIONS = gql`
  query Reg_GetRegistrationSubmissionList($PersonId: String!, $regSubmissionStatuses: [String!]!) {
    getRegistrationSubmissionList(PersonId: $PersonId, regSubmissionStatuses: $regSubmissionStatuses) {
      result
    }
  }
`;

const GET_PUBLIC_REGISTRATIONS = gql`
  query MyRegistrationList_GetPublicEvents($tenantId: String!) {
    Reg(where: { Group: { Tenant: { id: { _eq: $tenantId } } }, isPublic: { _eq: true } }) {
      id
      name
      isPublic
      closeAt
      openAt
      timezoneName
    }
  }
`;

function MyRegistrationsList({ headerTitle, listMode, PersonId }) {
  const [yearFilter, setYearFilter] = useState("all");
  const [availableRegYearFilter, setAvailableYearFilter] = useState("all");
  const { tenantId } = useTenantPicker();

  // Get all publically available Events (for this Tenancy)
  const { loading: publicRegLoading, error: publicRegError, data: publicRegData } = useQuery(GET_PUBLIC_REGISTRATIONS, {
    variables: { tenantId },
    skip: isNil(tenantId),
  });

  // Get current User Registration Submissions
  const { loading: regSubmissionsLoading, error: regSubmissionsError, data: rawRegSubmissionsData } = useQuery(
    GET_REG_SUBMISSIONS,
    {
      variables: { PersonId, regSubmissionStatuses: LIST_REG_SUBMISSION_STATUSES[listMode] },
      skip: isNil(PersonId),
    },
  );

  const regSubmissionsData = useMemo(() => {
    if (!rawRegSubmissionsData || rawRegSubmissionsData === undefined) {
      return null;
    }
    return { RegSubmission: rawRegSubmissionsData.getRegistrationSubmissionList.result };
  }, [rawRegSubmissionsData]);

  const { yearsOption, regSubmissionsList } = useMemo(() => {
    const result = { yearsOption: [{ label: "All", value: "all" }], regSubmissionsList: [] };

    if (!regSubmissionsData) {
      return result;
    }

    result.regSubmissionsList = regSubmissionsData.RegSubmission.map(regSubmission => ({
      ...regSubmission,
      closeAtYear: `${moment.tz(regSubmission.Reg.closeAt, regSubmission.Reg.timezoneName).year()}`,
    }));

    result.yearsOption = result.yearsOption.concat(
      uniq(result.regSubmissionsList.map(({ closeAtYear }) => closeAtYear)).map(year => ({
        label: `${year}`,
        value: `${year}`,
      })),
    );

    return result;
  }, [regSubmissionsData]);

  const handleYearSelect = useCallback(
    value => {
      setYearFilter(value);
    },
    [setYearFilter],
  );

  const availableRegistrationList = useMemo(() => {
    if (regSubmissionsLoading || publicRegLoading) {
      return [];
    }
    const publicRegistrations = get(publicRegData, "Reg", []);
    const regSubmissionIds = regSubmissionsList?.map(rs => get(rs, "Reg.id"));
    const delta = publicRegistrations.filter(r => !regSubmissionIds.includes(r.id));
    return delta.map(reg => ({
      ...reg,
      closeAtYear: `${moment.tz(get(reg, "closeAt"), get(reg, "timezoneName")).year()}`,
    }));
  }, [regSubmissionsList, publicRegData, regSubmissionsLoading, publicRegLoading]);

  const availableRegYearOptions = useMemo(() => {
    return [
      { label: "All", value: "all" },
      ...uniq(availableRegistrationList.map(({ closeAtYear }) => closeAtYear)).map(year => ({
        label: `${year}`,
        value: `${year}`,
      })),
    ];
  }, [availableRegistrationList]);

  const handleAvailableYearSelect = useCallback(
    value => {
      setAvailableYearFilter(value);
    },
    [setAvailableYearFilter],
  );

  const renderMyRegistrationsContent = () => {
    if (regSubmissionsLoading || publicRegLoading) {
      return <SpinPageContent style={{ height: "100%" }} />;
    }

    if (regSubmissionsError || !regSubmissionsData) {
      return (
        <Alert
          message="Registrations failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      );
    }

    if (isEmpty(regSubmissionsList)) {
      return (
        <Alert message="No registrations" description="There are no registrations to show." type="warning" showIcon />
      );
    }

    return regSubmissionsList
      .filter(regSubmission => yearFilter === "all" || regSubmission.closeAtYear === yearFilter)
      .map(regSubmission => (
        <MyRegistrationItem
          key={regSubmission.id}
          name={regSubmission.name}
          regSubmission={regSubmission}
          listMode={listMode}
        />
      ));
  };

  const renderPublicRegistrationsContent = () => {
    if (publicRegLoading || regSubmissionsLoading) {
      return <SpinPageContent style={{ height: "100%" }} />;
    }

    if (publicRegError) {
      return (
        <Alert
          message="Registrations failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      );
    }

    if (isEmpty(availableRegistrationList)) {
      return (
        <Alert
          message="No registrations"
          description="There are no other publically available registrations."
          type="warning"
          showIcon
        />
      );
    }

    return availableRegistrationList
      .filter(registration => availableRegYearFilter === "all" || registration.closeAtYear === availableRegYearFilter)
      .map(reg => <MyPublicRegistrationItem key={reg.id} name={reg.name} Reg={reg} listMode="NEW" />);
  };

  return (
    <div className="my-registrations-list">
      <SectionHeaderContainer>
        <Typography.Title level={3}>{headerTitle}</Typography.Title>
      </SectionHeaderContainer>
      <div className="my-registrations-list__content">
        <Row>
          <Col xl={{ span: 5 }}>
            <div className="my-registrations-list__left-sidebar">
              <div className="my-registrations-list__left-sidebar-label">Registration close date</div>

              <Select value={yearFilter} onSelect={handleYearSelect} style={{ minWidth: 160 }}>
                {renderOptions(yearsOption)}
              </Select>
            </div>
          </Col>
          <Col xl={{ span: 12 }}>
            <div className="my-registrations-list__right-content">{renderMyRegistrationsContent()}</div>
          </Col>
        </Row>
        <SectionHeaderContainer>
          <Typography.Title level={3}>Available Registrations</Typography.Title>
        </SectionHeaderContainer>
        <p>Below are other available Events that you may be interested in</p>
        <div className="my-registrations-list__content">
          <Row>
            <Col xl={{ span: 5 }}>
              <div className="my-registrations-list__left-sidebar">
                <div className="my-registrations-list__left-sidebar-label">Registration close date</div>

                <Select value={availableRegYearFilter} onSelect={handleAvailableYearSelect} style={{ minWidth: 160 }}>
                  {renderOptions(availableRegYearOptions)}
                </Select>
              </div>
            </Col>
            <Col xl={{ span: 12 }}>
              <div className="my-registrations-list__right-content">{renderPublicRegistrationsContent()}</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

MyRegistrationsList.propTypes = {
  PersonId: PropTypes.string,
  headerTitle: PropTypes.string.isRequired,
  listMode: PropTypes.oneOf(Object.values(REGISTRATION_LIST_MODE)).isRequired,
};

MyRegistrationsList.defaultProps = {
  PersonId: null,
};

export default MyRegistrationsList;
