import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Spin } from "antd";
import { connect } from "formik";
import { TreeSelect } from "formik-antd";
import gql from "fraql";
import find from "lodash/find";
import get from "lodash/get";
import omit from "lodash/omit";
import { NO_ACCESS_TITLE } from "../../constants/labelConstants";
import isBlank from "../../utils/isBlank";
import useGroupTreeData from "../../utils/useGroupTreeData";
import NoAccessStatusText from "../NoAccessStatusText";
import StatusText, { STATUS_TEXT_STATUS_OPTIONS } from "../StatusText";
import FormFieldFormItem from "./FormFieldFormItem";
import FormFieldSelect from "./FormFieldSelect";

export const refGroups = React.createRef();

const GET_GROUPS = gql`
  query FormFieldGroup_GetGroups {
    Group(order_by: { name: asc }) {
      id
      name
      parentId
      path
      tenantId
    }
  }
`;

export const DISPLAY_MODES = {
  tree: "tree",
  treeSelect: "treeSelect",
  select: "select",
};

function renderNoAccessParentGroupTitle() {
  return <StatusText status={STATUS_TEXT_STATUS_OPTIONS.DEFAULT}>{NO_ACCESS_TITLE}</StatusText>;
}

function renderNoAccessChildGroupTitle() {
  return <NoAccessStatusText />;
}

function FormFieldGroupSelect(props) {
  const { meta, loading, groups, selectedValue } = props;

  const options = useMemo(() => {
    const allOptions = groups.map(group => ({ value: group.id, label: group.name }));
    if (!isBlank(selectedValue) && !find(allOptions, { value: selectedValue })) {
      allOptions.unshift({
        value: selectedValue,
        label: <NoAccessStatusText />,
        title: NO_ACCESS_TITLE,
      });
    }
    return allOptions;
  }, [groups, selectedValue]);

  return <FormFieldSelect {...omit(props, ["selectedValue"])} loading={loading} meta={{ ...meta, options }} />;
}

FormFieldGroupSelect.propTypes = {
  selectedValue: PropTypes.string,
  meta: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
};

FormFieldGroupSelect.defaultProps = {
  selectedValue: null,
};

function FormFieldGroupTreeSelect(props) {
  const { name, meta, disabled, groups, formik } = props;

  const multiple = get(meta, "multiple", false);
  const allowClear = get(meta, "allowClear", true);
  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);
  const enableGetPopupContainer = get(meta, "enableGetPopupContainer", true);
  const selectedValue = get(formik, `values.${name}`, null);

  let placeholder = get(meta, "placeholder", null);

  if (!placeholder) {
    placeholder = "Select a group";
  }

  const treeData = useGroupTreeData(groups, {
    selectedValues: selectedValue,
    noAccessParentGroupTitleRenderer: renderNoAccessParentGroupTitle,
    noAccessChildGroupTitleRenderer: renderNoAccessChildGroupTitle,
  });

  // When TreeSelect is used inside a Drawer component, there is a bug where after expanding enough items in the tree so
  // that you need to be able to scroll vertically to see them all, the dropdown containing the tree gets its position
  // updated in a way that makes part of it go off screen and become unusable. The root issue is the dropdown, which is
  // positioned absolutely and has its `top` value dynamically calculated by Ant Design via JavaScript, ends up having a
  // `top` value of `0px` set, which is incorrect and causes the dropdown to go partially off screen. It looks like this
  // bug can be fixed by wrapping the TreeSelect input in a div and then using `getPopupContainer` to treat that
  // wrapping div as the container for the dropdown, which is what we are doing now. Note that because we need to use an
  // `id` for this, and the only 'unique' information we have for the field is its `name`, this approach is likely to
  // not work or be buggy if we ever had two forms being displayed at the same time and both of them were using a
  // TreeSelect with inputs which share the same name (as then the wrapping divs for both would have an identical `id`).
  // This could be resolved by giving the fields different names.
  const formFieldId = `tree-select__wrapper--${name}`;

  // In some cases, the use of `getPopupContainer` actually causes UI issues for the dropdown/popup. For example, if
  // TreeSelect is used within a table component like in FormFieldCompetitionTeams, our `getPopupContainer` logic here
  // results in the dropdown only being visible within the bounds of the table, which means a part of the dropdown is
  // not visible. To handle cases like this, we offer the `enableGetPopupContainer` prop as a way to opt-out of this
  // logic when needed.
  const getPopupContainer = enableGetPopupContainer ? () => document.getElementById(formFieldId) : undefined;

  return (
    <div id={formFieldId}>
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel}>
        <TreeSelect
          name={name}
          treeData={treeData}
          disabled={disabled}
          multiple={multiple}
          placeholder={placeholder}
          allowClear={allowClear}
          getPopupContainer={getPopupContainer}
        />
      </FormFieldFormItem>
    </div>
  );
}

FormFieldGroupTreeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  formik: PropTypes.object.isRequired,
};

function FormFieldGroup(props) {
  const { name, meta, query, queryOptions, formik } = props;
  const selectedValue = get(formik, `values.${name}`, null);
  const displayMode = get(meta, "displayMode", DISPLAY_MODES.select);
  const displayDefaultLabel = get(meta, "displayDefaultLabel", true);

  const { loading: formFieldGroupLoading, error: formFieldGroupError, data: formFieldGroupData } = useQuery(
    query,
    queryOptions,
  );

  const groups = useMemo(() => get(formFieldGroupData, "Group", []), [formFieldGroupData]);

  useEffect(() => {
    // TODO: Check if there's a better solution than this.
    // Note: this ref is used in GroupBaseForm.
    refGroups.current = groups;
  }, [groups]);

  if (formFieldGroupLoading && !formFieldGroupData) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Spin size="small" className="form-field-spin" />
      </FormFieldFormItem>
    );
  }

  if (formFieldGroupError) {
    return (
      <FormFieldFormItem {...omit(props, ["formik"])} displayDefaultLabel={displayDefaultLabel} displayForInput={false}>
        <Alert message="Groups failed to load" type="error" className="form-field-alert" />
      </FormFieldFormItem>
    );
  }

  switch (displayMode) {
    case DISPLAY_MODES.select: {
      return (
        <FormFieldGroupSelect
          {...omit(props, ["formik"])}
          loading={formFieldGroupLoading}
          groups={groups}
          selectedValue={selectedValue}
        />
      );
    }
    case DISPLAY_MODES.treeSelect: {
      return <FormFieldGroupTreeSelect {...props} groups={groups} />;
    }
    case DISPLAY_MODES.tree: {
      // TODO: Add back the `DISPLAY_MODES.tree` which renders the full tree.
      return <FormFieldGroupTreeSelect {...props} groups={groups} />;
    }
    default: {
      console.error("Unsupported display mode", displayMode);
      return null;
    }
  }
}

FormFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  query: PropTypes.any,
  queryOptions: PropTypes.object,
};

FormFieldGroup.defaultProps = {
  disabled: false,
  query: GET_GROUPS,
  queryOptions: {},
};

export default connect(FormFieldGroup);
