import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Select, Row } from "antd";
import { Col } from 'antd/es/grid';
import { connect } from "formik";
import omit from "lodash/omit";

const FormFieldDiscountAmount = props => {
    const { formik, meta } = props;
    const { required, label } = meta;
    const { Option } = Select;

    const { values } = formik;
    const { percentOff, fixedOff } = values;

    const [currentMode, setCurrentMode] = useState(percentOff ? "percent" : "fixed");

    const modeChanged = (e) => {
      if (e === "percent"){
        formik.setFieldValue("percentOff", formik.values.fixedOff);
        formik.setFieldValue("discountAmount", formik.values.fixedOff);
        formik.setFieldValue("fixedOff", null);
        setCurrentMode("percent");
      }
      else{
        formik.setFieldValue("fixedOff", formik.values.percentOff);
        formik.setFieldValue("discountAmount", formik.values.percentOff);
        formik.setFieldValue("percentOff", null);
        setCurrentMode("fixed");
      }
    }

    const valueChanged = (e) => {
      if (currentMode === "percent"){
        formik.setFieldValue("percentOff", e.target.value);
        formik.setFieldValue("discountAmount", e.target.value);
        formik.setFieldValue("fixedOff", null);
      }
      else{
        formik.setFieldValue("fixedOff", e.target.value);
        formik.setFieldValue("discountAmount", e.target.value);
        formik.setFieldValue("percentOff", null);
      }
    }

    return (
    <Form.Item {...omit(props, ["formik"])} required={required} label={label}>
        <Row>
          <Col span={12}>
            <Input 
              {...omit(props, ["formik"])}
              style={{marginRight: "10px"}}
              type="number" 
              value={percentOff || fixedOff} 
              onChange={valueChanged}  
            />
          </Col>
          <Col span={12}>
            <Select 
              value={currentMode}
              onChange={modeChanged}
              style={{marginLeft: "10px"}}>
              <Option value="percent">Percent</Option>  
              <Option value="fixed">Fixed Amount</Option>
            </Select>
          </Col>
        </Row>
    </Form.Item>
    );

};

FormFieldDiscountAmount.propTypes = {
    meta: PropTypes.object.isRequired,
    formik: PropTypes.object.isRequired,
  };

export default connect(FormFieldDiscountAmount);;
