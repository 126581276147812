import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import { Alert, Select, Typography } from "antd";
import gql from "fraql";
import find from "lodash/find";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { filterOption } from "../../../utils/selectUtils";
import RegistrationStatus from "../../RegistrationStatus";
import SectionHeaderContainer from "../../SectionHeaderContainer";
import SpinPageContent from "../../SpinPageContent";
import RegistrationArchivedStatusAlert from "../Registrations/RegistrationArchivedStatusAlert";
import PersonRegistrationCompetitions from "./PersonRegistrationCompetitions/PersonRegistrationCompetitions";
import "./PersonDetailCompetitions.scss";

const GET_PERSON_REG_SUBMISSIONS = gql`
  query PersonDetailCompetitionsTab_GetPersonRegSubmissions($personId: String!) {
    RegSubmission(where: { PersonId: { _eq: $personId } }, order_by: { Reg: { name: asc } }) {
      id

      Reg {
        id
        name
        archived
        openAt
        closeAt
      }
    }
  }
`;

export function PersonDetailCompetitionsBody({ personId, readOnly }){

  const [activeRegSubmissionId, setActiveRegSubmissionId] = useState();

  const { loading: regSubmissionsLoading, error: regSubmissionsError, data: regSubmissionsData } = useQuery(
    GET_PERSON_REG_SUBMISSIONS,
    {
      variables: { personId },
    },
  );

  const regSubmissions = useMemo(() => {
    return get(regSubmissionsData, "RegSubmission", []);
  }, [regSubmissionsData]);

  const activeRegSubmission = useMemo(() => find(regSubmissions, { id: activeRegSubmissionId }), [
    activeRegSubmissionId,
    regSubmissions,
  ]);

  const activeReg = useMemo(() => get(activeRegSubmission, "Reg", null), [activeRegSubmission]);
  const activeRegArchived = useMemo(() => get(activeReg, "archived", false), [activeReg]);

  const hasRegSubmissions = !!regSubmissions.length;
  const hasActiveRegSubmission = !!activeRegSubmissionId;

  function handleChangeRegSubmission(selectedRegSubmissionId) {
    setActiveRegSubmissionId(selectedRegSubmissionId);
  }

  if (regSubmissionsLoading && !regSubmissionsData) {
    return <SpinPageContent />;
  }

  if (regSubmissionsError) {
    return (
      <div style={{ padding: 24 }}>
        <Alert
          message="Registrations failed to load"
          description="Sorry, there was an issue loading the data for this page."
          type="error"
          showIcon
        />
      </div>
    );
  }

  if (!hasRegSubmissions) {
    return (
      <div style={{ padding: 24 }}>
        <SectionHeaderContainer>
          <Typography.Title level={3}>Registration</Typography.Title>
        </SectionHeaderContainer>

        <Alert
          message="No Registrations"
          description="There are currently no registrations for this person. Add one on the Registrations tab."
          type="info"
          showIcon
        />
      </div>
    );
  }

  return (
    <div className="person-detail-competitions__container">
      <div className="person-detail-competitions__flex-container">
        <div className="person-detail-competitions__left-sidebar">
          <SectionHeaderContainer>
            <Typography.Title level={3}>Registration</Typography.Title>
          </SectionHeaderContainer>

          <Select
            style={{ width: "100%" }}
            onSelect={handleChangeRegSubmission}
            placeholder="Select a registration"
            value={activeRegSubmissionId}
            dropdownMatchSelectWidth={false}
            filterOption={filterOption}
            showSearch
          >
            {regSubmissions
              .filter(({ Reg: reg }) => !isNil(reg))
              .map(({ id, Reg: reg }) => {
                return (
                  <Select.Option key={id} value={id} title={reg.name}>
                    <div style={{ display: "flex" }}>
                      <RegistrationStatus size="small" reg={reg} />
                      {reg.name}
                    </div>
                  </Select.Option>
                );
              })}
          </Select>
        </div>

        <div className="person-detail-competitions__content">
          <PersonRegistrationCompetitions personId={personId} regSubmissionId={activeRegSubmissionId} readOnly={readOnly} />
        </div>

        <div className="person-detail-competitions__right-sidebar">
          {hasActiveRegSubmission && activeRegArchived && (
            <RegistrationArchivedStatusAlert style={{ marginBottom: 24 }} />
          )}
        </div>
      </div>
    </div>
  );

}

PersonDetailCompetitionsBody.propTypes = {
  personId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

PersonDetailCompetitionsBody.defaultProps = {
  readOnly: false,
};

function PersonDetailCompetitionsTab({ match }) {
  const { id: personId } = match.params;

  return(
    <PersonDetailCompetitionsBody 
      personId={personId}
    />
  );
}

PersonDetailCompetitionsTab.propTypes = {
  match: PropTypes.object.isRequired,
};

export default PersonDetailCompetitionsTab;
